var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"jrlmqzg drag",attrs:{"id":"jrlmqzg"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(" "+_vm._s(_vm.projectData.project)+"_煤气总管压力模型 ")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("煤气总管压力控制参数")]),_c('div',{staticClass:"main-top"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"fl row1-col1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl row1-col2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_PFJ.PMQ_SP,
                    'A01-TSP',
                     'PMQ_SP',
                    'G_PFJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.PMQ_SP)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"fl col1"},[_c('div',[_c('div',{staticClass:"fl row1-col1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl row1-col2",staticStyle:{"color":"#8aeaff"},on:{"click":function($event){return _vm.toCompon(2, 'PIA_105_B', 'G_SOUREC', 'PIA_105_J1','煤气总管压力')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_SOUREC.PIA_105_B,'PIA_105_B', 'G_SOUREC', 'PIA_105_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.PIA_105_B))])]),_c('div',{staticClass:"col1-2"},[_c('div',{staticClass:"fl row1-col1"},[_vm._v("PMQTC")]),_c('div',{staticClass:"fl row1-col2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_PFJ.PMQTC,
                    'PMQTC',
                     'PMQTC',
                    'G_PFJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.PMQTC)+" ")])])]),_c('div',{staticClass:"fl col2"},[_c('div',{staticClass:"col1-2 jg fl"},[_c('div',{staticClass:"fl row1-col1"},[_vm._v("A02-SUMPV")]),_c('div',{staticClass:"fl row1-col3",on:{"click":function($event){return _vm.toCompon(2, 'PV', 'G_MCSOTHER__p__QK7', 'QK7_PV_J1','煤气总管煤气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK7.PV,'PV', 'G_MCSOTHER__p__QK7', 'QK7_PV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK7.PV))])]),_c('div',{staticClass:"fl buttonc"},[_c('div',{staticClass:"bottonx jg1",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK14 &&
                !_vm.infoList.G_MCSXK__p__XK14.XK14
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK14 ? _vm.toDetail(1,'XK14','G_MCSXK__p__XK14','','煤气总管压力控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx jg2",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK7 &&
                !_vm.infoList.G_MCSOTHER__p__QK7.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK7 ? _vm.toDetail(2,'QK7','G_MCSOTHER__p__QK7','','煤气总管煤气阀门前馈' ): ''}}},[_vm._v("K ")])])]),_c('div',{staticClass:"fl col3"},[_c('div',{staticClass:"bottonx jg3",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN24 &&
                _vm.infoList.G_MCSMAN__p__MAN24.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN24
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN24',
                    'MAN24_RM_J1',
                    '煤气总管压力手操器'
                  )
                : ''}}},[_vm._v("A")])])])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }