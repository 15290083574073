<template>
  <div class="JrparmsGroup drag" v-draw id="JrparmsGroup">
        <div class="title-hang" fl> 
     {{ projectData.project }}_参数11_{{ datatype }}
    </div>
     <div class="param11-header-icon"  fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    <!-- <div class="param11-header">
      <span class="param11-header-title"
        >{{ projectData.project }}_参数11_{{ datatype }}</span
      >
      <div class="param11-header-icon" @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div> -->
    <div class="JrparmsGroup-data">
      <div class="JrparmsGroup-data-tit">{{ tit }}</div>
      <div class="JrparmsGroup-data-top">
        <div class="top-text1">
          停炉时间设置S
          <span
            @click="
              toIpt(
                infoList.G_PVCL['T_TSBZ' + String(datatype)],
                '停炉时间设置S',
                'T_TSBZ' + datatype,
                'G_PVCL'
              )
            "
          >
            {{ infoList.G_PVCL["T_TSBZ" + String(datatype)] }}
          </span>
        </div>
        <div class="top-text1">
          启炉时间设置S
          <span
            @click="
              toIpt(
                infoList.G_PVCL['Q_TSBZ' + String(datatype)],
                '启炉时间设置S',
                'Q_TSBZ' + datatype,
                'G_PVCL'
              )
            "
          >
            {{ infoList.G_PVCL["Q_TSBZ" + String(datatype)] }}
          </span>
        </div>
        <div class="left-box1 flex align-cen">
          <div>A01-TSP</div>
          <div
            class="ma-auto"
            style="color: #00ffb4"
            @click="
              toIpt(
                infoList.G_TEMP['T' + datatype + '1SP'],
                '温度设定值',
                'T' + datatype + '1SP',
                'G_TEMP'
              )
            "
          >
            {{ infoList.G_TEMP["T" + datatype + "1SP"] }}
          </div>
        </div>
        <div class="left-box1 left-box3 flex align-cen">
          <div>A01-FMQ</div>
          <div
            class="ma-auto"
            @click="
              toCompon(
                2,
                'AV',
                'G_MCSXK__p__XK' +
                  (datatype == 1
                    ? '10'
                    : datatype == 2
                    ? '7'
                    : datatype == 3
                    ? '4'
                    : '1'),
                'XK' +
                  +(datatype == 1
                    ? '10'
                    : datatype == 2
                    ? '7'
                    : datatype == 3
                    ? '4'
                    : '1') +
                  '_AV_J1'
              )
            "
            @dblclick="
              Cclick(
                infoList[
                  'G_MCSXK__p__XK' +
                    (datatype == 1
                      ? '10'
                      : datatype == 2
                      ? '7'
                      : datatype == 3
                      ? '4'
                      : '1')
                ].AV,
                'AV',
                'G_MCSXK__p__XK' +
                  (datatype == 1
                    ? '10'
                    : datatype == 2
                    ? '7'
                    : datatype == 3
                    ? '4'
                    : '1'),
                'XK' +
                  +(datatype == 1
                    ? '10'
                    : datatype == 2
                    ? '7'
                    : datatype == 3
                    ? '4'
                    : '1') +
                  '_AV_J1'
              )
            "
          >
            {{
              infoList[
                "G_MCSXK__p__XK" +
                  (datatype == 1
                    ? "10"
                    : datatype == 2
                    ? "7"
                    : datatype == 3
                    ? "4"
                    : "1")
              ].AV
            }}
          </div>
        </div>
        <div
          class="child-dataX child-databtn"
          :style="{
            background:
              infoList[
                'G_MCSXK__p__XK' +
                  (datatype == 1
                    ? '10'
                    : datatype == 2
                    ? '7'
                    : datatype == 3
                    ? '4'
                    : '1')
              ] &&
              infoList[
                'G_MCSXK__p__XK' +
                  (datatype == 1
                    ? '10'
                    : datatype == 2
                    ? '7'
                    : datatype == 3
                    ? '4'
                    : '1')
              ].RM == 1
                ? '#2AFC30'
                : 'red',
          }"
          @click="
            infoList[
              'G_MCSXK__p__XK' +
                (datatype == 1
                  ? '10'
                  : datatype == 2
                  ? '7'
                  : datatype == 3
                  ? '4'
                  : '1')
            ]
              ? toDetail(
                  1,
                  'XK' +
                    (datatype == 1
                      ? '10'
                      : datatype == 2
                      ? '7'
                      : datatype == 3
                      ? '4'
                      : '1'),
                  'G_MCSXK__p__XK' +
                    (datatype == 1
                      ? '10'
                      : datatype == 2
                      ? '7'
                      : datatype == 3
                      ? '4'
                      : '1'),
                  '',
                  (datatype == 1
                    ? '一加'
                    : datatype == 2
                    ? '二加'
                    : datatype == 3
                    ? '三加'
                    : '均') + '热段炉膛温度控制XK'
                )
              : ''
          "
        >
          X
        </div>
        <div class="left-box1 left-box4 flex align-cen">
          <div>上限防抖</div>
          <div
            class="ma-auto"
            style="color: #00ffb4"
            @click="
              toIpt(
                infoList.G_TEMP['MQ' + datatype + '1_FDH'],
                '修改',
                'MQ' + datatype + '1_FDH',
                'G_TEMP'
              )
            "
          >
            {{ infoList.G_TEMP["MQ" + datatype + "1_FDH"] }}
          </div>
        </div>
        <div class="btnright">
          <div
            class="child-dataX1 child-databtn"
            :style="{background: infoList[
                  'G_MCSXK__p__XK' +
                    (datatype == 1
                      ? '21'
                      : datatype == 2
                      ? '19'
                      : datatype == 3
                      ? '17'
                      : '15')
                ] &&
                infoList[
                  'G_MCSXK__p__XK' +
                    (datatype == 1
                      ? '21'
                      : datatype == 2
                      ? '19'
                      : datatype == 3
                      ? '17'
                      : '15')
                ].RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList[
                'G_MCSXK__p__XK' +
                  (datatype == 1
                    ? '21'
                    : datatype == 2
                    ? '19'
                    : datatype == 3
                    ? '17'
                    : '15')
              ]
                ? toDetail(
                    1,
                    'XK' +
                      (datatype == 1
                        ? '21'
                        : datatype == 2
                        ? '19'
                        : datatype == 3
                        ? '17'
                        : '15'),
                    'G_MCSXK__p__XK' +
                      (datatype == 1
                        ? '21'
                        : datatype == 2
                        ? '19'
                        : datatype == 3
                        ? '17'
                        : '15'),
                    '',
                    (datatype == 1
                      ? '一加'
                      : datatype == 2
                      ? '二加'
                      : datatype == 3
                      ? '三加'
                      : '均') + '热煤气流量XK'
                  )
                : ''
            "
          >
            X
          </div>
        </div>
          <div class="child-dataA child-databtn"
          :style="{ background: 
          infoList['G_MCSMAN__p__MAN' + (datatype == 1 ? '21' : datatype == 2 ? '10' : datatype == 3 ? '6' : '2')].RM == 1 
          ? '#2AFC30' : 'red',}"
          @click="infoList['G_MCSMAN__p__MAN' + (datatype == 1 ? '21' : datatype == 2 ? '10' : datatype == 3 ? '6' : '2')]
           ? 
           toCompon(0,'AV','G_MCSMAN__p__MAN' + (datatype == 1 ? '21' : datatype == 2 ? '10' : datatype == 3 ? '6' : '2'),
           'MAN' + (datatype == 1 ? '21' : datatype == 2 ? '10' : datatype == 3 ? '6' : '2') +'_AV_J1',
            (datatype == 1 ? '一' : datatype == 2 ? '二' : datatype == 3 ? '三' : '均')  +'热段煤气阀门手操器MAN' + (datatype == 1 ? '21' : datatype == 2 ? '10' : datatype == 3 ? '6' : '2')) 
            : ''" 
           >A</div>
        <div class="left-box1 left-box2 flex align-cen">
          <div>A01-TSPSJ</div>
          <div
            class="ma-auto"
            @click="
              toCompon(
                2,
                'T' + datatype + '1SPSJ',
                'G_TEMP',
                'T' + datatype + '1SPSJ_J1'
				,'实际温度控制点'
              )
            "
            @dblclick="
              Cclick(
                infoList.G_TEMP['T' + datatype + '1SPSJ'],
                'T' + datatype + '1SPSJ',
                'G_TEMP',
                'T' + datatype + '1SPSJ_J1'
              )
            "
          >
            {{ infoList.G_TEMP["T" + datatype + "1SPSJ"] }}
          </div>
        </div>
        <div class="box flex">
          <div class="left-box1 flex align-cen">
            <div>A02-YRBK</div>
            <div
              class="ma-auto"
              style="color: #00ffb4"
              @click="
                datatype == 1
                  ? toIpt(
                      infoList.G_TEMP.KCGT_YJ,
                      '一加热段修改',
                      'KCGT_YJ',
                      'G_TEMP'
                    )
                  : datatype == 2
                  ? toIpt(
                      infoList.G_TEMP.KCGT_EJ,
                      '二加热段修改',
                      'KCGT_EJ',
                      'G_TEMP'
                    )
                  : datatype == 3
                  ? toIpt(
                      infoList.G_TEMP.KCGT_SJ,
                      '三加热段修改',
                      'KCGT_SJ',
                      'G_TEMP'
                    )
                  : toIpt(
                      infoList.G_TEMP.KCGT_JR,
                      '均热段修改',
                      'KCGT_JR',
                      'G_TEMP'
                    )
              "
            >
              {{
                datatype == 1
                  ? infoList.G_TEMP.KCGT_YJ
                  : datatype == 2
                  ? infoList.G_TEMP.KCGT_EJ
                  : datatype == 3
                  ? infoList.G_TEMP.KCGT_SJ
                  : infoList.G_TEMP.KCGT_JR
              }}
            </div>
          </div>
          <div
            class="child-dataR child-databtn"
            :style="{
              background:
                infoList.G_MCSOTHER__p__RSF2 && !infoList.G_MCSOTHER__p__RSF2.TS
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="infoList.G_MCSOTHER__p__RSF2 ? toDetail(3,'RSF2','G_MCSOTHER__p__RSF2','','出钢温度软伺服' ): ''"
          >
            R
          </div>
          <div
            class="boxbtn"
            @click="
              toIpt(
                infoList.G_MCSOTHER.RSF2SEL,
                '按钮',
                'RSF2SEL',
                'G_MCSOTHER'
              )
            "
          >
            {{ infoList.G_MCSOTHER.RSF2SEL ? "投用" : "切除" }}
          </div>
          <div
            class="boxbtn boxbtn1"
            @click="
              toIpt(
                infoList.G_TEMP['T' + datatype + '1SEL1'],
                '按钮',
                'T' + datatype + '1SEL1',
                'G_TEMP'
              )
            "
          >
            {{ infoList.G_TEMP["T" + datatype + "1SEL1"] ? "投用" : "切除" }}
          </div>
        </div>
        <div class="left1-box-cen">
          <div class="box-cen1 flex  justify-cen">
            <div class="flex align-cen">
              A01-TCL1
              <span class="ma-auto"
              @click="checkStu(datatype, 1)"
              :style="{
                color:infoList.G_TEMP['T' + datatype + '1SEL'] == 1 ? '#01ffba' :'#ccc'
              }"
              >
              {{infoList.G_SOUREC['TICA_10' + (datatype == 1 ? '1':datatype == 1 ? '7' : datatype == 2 ? '5' :  datatype == 3 ? '3' : '2') + 'A_B']}}</span>
            </div>
              <div class="flex align-cen">
              A01-TPV
              <span class="ma-auto"
               @click="toCompon(2, 'T' + datatype + '1PV', 'G_TEMP', 'T' + datatype + '1PV' +'_J1','均热段上部温度均值')"
               @dblclick="Cclick(infoList.G_SOUREC['T' + datatype + '1PV'],'T' + datatype + '1PV', 'G_TEMP', 'T' + datatype + '1PV' +'_J1')"
              >
              {{infoList.G_TEMP['T' + datatype + '1PV']}}</span>
            </div>
               <div class="flex align-cen">
              A01-T11TC
              <span class="ma-auto"
               style="color: #00ffb4"
               @click="toIpt(infoList.G_TEMP['T' + datatype + '1TC'],'修改','T' + datatype + '1TC','G_TEMP')"
              >
              {{infoList.G_TEMP['T' + datatype + '1TC']}}</span>
            </div>
              <div class="flex align-cen">
              A01-LBSJ
              <span class="ma-auto"
               style="color: #00ffb4"
               @click="toIpt(infoList['G_MCSXK__p__TYYC' + datatype].LBSJ,'修改','LBSJ','G_MCSXK__p__TYYC' + datatype)"
              >
              {{infoList['G_MCSXK__p__TYYC' + datatype].LBSJ}}</span>
            </div>
          </div>
          <div class="box-cen1 flex justify-cen">
              <div class="flex align-cen">
              A01-TCL1
              <span class="ma-auto"
              @click="checkStu(datatype, 2)"
              :style="{
                color:infoList.G_TEMP['T' + datatype + '1SEL'] == 2 ? '#01ffba' :'#ccc'
              }"
              >
              {{infoList.G_SOUREC['TICA_10' + (datatype == 1 ? '1':datatype == 1 ? '7' : datatype == 2 ? '5' :  datatype == 3 ? '3' : '2') + 'B_B']}}</span>
            </div>
               <div class="flex align-cen">
              A01-HZCL
              <span class="ma-auto"
               style="color: #00ffb4;"
               @click="toIpt(infoList.G_TEMP['T' + datatype + '1_C'],'修改','T' + datatype + '1_C','G_TEMP')"
              >
              {{infoList.G_TEMP['T' + datatype + '1_C']}}</span>
            </div>
             <div class="flex align-cen">
              A01-CYZQ
              <span class="ma-auto"
               style="color: #00ffb4;"
               @click="toIpt(infoList['G_MCSXK__p__TYYC' + datatype].CYZQ ,'修改','CYZQ','G_MCSXK__p__TYYC' + datatype)"
              >  
              {{infoList['G_MCSXK__p__TYYC' + datatype].CYZQ}}</span>
            </div>
             <div class="flex align-cen">
              A01-YCSJ
              <span class="ma-auto"
               style="color: #00ffb4;"
               @click="toIpt(infoList['G_MCSXK__p__TYYC' + datatype].YCSJ ,'修改','YCSJ','G_MCSXK__p__TYYC' + datatype)"
              >  
              {{infoList['G_MCSXK__p__TYYC' + datatype].YCSJ}}</span>
            </div>
          </div>
        </div>
        
        <div class="left1-box-cen1">
                     <div
            class="boxbtn2"
            @click="
              toIpt(
               infoList[commonLet2].BCSYHQY,                
                '按钮',
                'BCSYHQY',
                commonLet2
              )
            "
          >
            {{ infoList[commonLet2].BCSYHQY ? "投用" : "切除" }}
          </div>
          <div class="spbt flex align-cen">
            <span>入炉钢温前馈补偿</span>
              <div class="child-dataK child-databtn "
                :style="{
                background:
                infoList[commonLet3] &&
                !infoList[commonLet3].TS
                ? '#2AFC30'
                : 'red',
                }"
                @click="infoList[commonLet3] ? toDetail(2,'QK' + (datatype == 1 ? '17': datatype == 2 ? '18': datatype == 3 ? '19' : '20'),commonLet3,'',(datatype == 1 ? '一加': datatype == 2 ? '二加': datatype == 3 ? '三加' : '均') + '热入炉钢温前馈' ): ''"
              >K</div>
          </div>
          <div class="box-cen1-top flex">
            <div class="lf">
              <div class="flex align-cen">
                AO3-TOP
                <span class="ma-auto"
                style="color: #00ffb4;"
                @click="toIpt(infoList[commonLet].TOP,
                '修改','TOP',
                commonLet)"
                >
                 {{infoList[commonLet].TOP}}
                  <!-- {{datatype | typeFifter}} -->
                </span>
              </div>
               <div class="flex align-cen">
              A03-SOP
              <span class="ma-auto"
               style="color: #00ffb4;"
                @click="toIpt(infoList[commonLet].SOP,'修改','SOP',commonLet)"
                >
                 {{infoList[commonLet].SOP}}
              
              </span>
            </div>
                 <div class="flex align-cen">
              A03-EOP
              <span class="ma-auto"
               style="color: #00ffb4;"
                @click="toIpt(infoList[commonLet].EOP, '修改','EOP',commonLet)"
                >
                {{infoList[commonLet].EOP}}
              </span>
            </div>
                 <div class="flex align-cen">
              A03-MQLJ
              <span class="ma-auto"
               @click="toCompon(2, 'YHMQLJ_B' + datatype + '1', 'G_BCS', 'YHMQLJ_B' + datatype + '1' +'_J1','优化煤气累积')"
               @dblclick="Cclick(infoList.G_BCS['YHMQLJ_B' + datatype + '1'],'YHMQLJ_B' + datatype + '1', 'G_BCS', 'YHMQLJ_B' + datatype + '1' +'_J1')"
                >
              {{infoList.G_BCS['YHMQLJ_B' + datatype + '1']}}
              </span>
            </div>
              <div class="flex align-cen">
              A03-SYT(m)
              <span class="ma-auto"
               @click="toCompon(2, 'YHJGSYSJ', commonLet1, 'TIMELAP2_B' + datatype + '1' +'_J1','均热煤气优化剩余时间')"
               @dblclick="Cclick(infoList[commonLet1].YHJGSYSJ,'YHJGSYSJ', commonLet1, 'TIMELAP2_B' + datatype + '1' +'_J1')"
                >
             {{infoList[commonLet1].YHJGSYSJ}}
              </span>
            </div>
            </div>
            <div class="lf">
              <div class="flex align-cen">
                AO3-OH
                <span class="ma-auto"
                 @click="toCompon(2, 'YHZL_H', commonLet, 'BCS_MH_B' + datatype  +'1_J1','煤气优化上限')"
                @dblclick="Cclick(infoList[commonLet].YHZL_H,'YHZL_H', commonLet, 'BCS_MH_B' + datatype  +'1_J1')"
                >
                {{infoList[commonLet].YHZL_H}}
                </span>
              </div>
               <div class="flex align-cen">
                AO3-OL
                <span class="ma-auto"
                 @click="toCompon(2, 'YHZL_L', commonLet, 'BCS_ML_B' + datatype  +'1_J1','煤气优化下限')"
                @dblclick="Cclick(infoList[commonLet].YHZL_L,'YHZL_L', commonLet, 'BCS_ML_B' + datatype  +'1_J1')"
                >
                {{infoList[commonLet].YHZL_L}}
                </span>
              </div>
                  <div class="flex align-cen">
              A03-JG(m)
              <span class="ma-auto"
               style="color: #00ffb4;"
                @click="toIpt(infoList[commonLet].YHJG, '修改','YHJG',commonLet)"
                >
                {{infoList[commonLet].YHJG}}
              </span>
            </div>
             <div class="flex align-cen">
                A03-OT
                <span class="ma-auto"
                 @click="toCompon(2, 'YHZL', commonLet1, 'AV' + (datatype == 1 ? '8': datatype == 2 ? '6': datatype == 3 ? '4' : '2')  +'_J1')"
                @dblclick="Cclick(infoList[commonLet1].YHZL,'YHZL', commonLet1, 'AV' + (datatype == 1 ? '8': datatype == 2 ? '6': datatype == 3 ? '4' : '2')  +'_J1')"
                >
                {{infoList[commonLet1].YHZL}}
                </span>
              </div>
              <div></div>
            </div>
          </div>
          <div class="box-cen1-bot flex align-cen">
            <div class="lf"
                :style="[{
              background:
                infoList[commonLet].RESET
                  ? 'red'
                  : '#2AFC30',
            },
            {
              color:
               infoList[commonLet].RESET
                  ? '#fff'
                  : '',
            }
            ]"
             @click="toIpt(infoList[commonLet].RESET, '按钮','RESET',commonLet)"
            >{{infoList[commonLet].RESET ? '强制优化' :'自动优化'}}</div>
            <div class="ma-auto">
              <div class="dpr">
                <div class="npr"
                :style="{
                  width: infoList[commonLet1].PV + '%'
                }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="left1-box-cen2 flex">
          <div class="flex">
            A04-MQSPK
            <el-tooltip class="item" effect="dark" :content="infoList.G_TEMP['MQ' + datatype + '1SP_K']" placement="top">
            <span class="ma-auto"
             style="color: #00ffb4;"
               @click="toIpt(infoList.G_TEMP['MQ' + datatype + '1SP_K'] ,'修改','MQ' + datatype + '1SP_K','G_TEMP')"
            >
              {{infoList.G_TEMP['MQ' + datatype + '1SP_K']}}
            </span>
            </el-tooltip>
          </div>
          <div class="flex">
            A04-MQJBSP
            <el-tooltip class="item" effect="dark" :content="infoList.G_TEMP['MQ' + datatype + '1JBSP']" placement="top">
              <span class="ma-auto"
             style="color: #00ffb4;"
               @click="toIpt(infoList.G_TEMP['MQ' + datatype + '1JBSP'] ,'修改','MQ' + datatype + '1JBSP','G_TEMP')"
            >
              {{infoList.G_TEMP['MQ' + datatype + '1JBSP']}}
            </span>
            </el-tooltip>
          </div>
        </div>
          <div class="left1-box-cen3">
            <div class="flex align-cen">
              A05-AV
              <span class="ma-auto"
              @click="toCompon(2, 'KX_1' + commonLet4 + '_B', 'G_SOUREC', 'KX_1' + commonLet4  +'_J1')"
              @dblclick="Cclick(infoList.G_SOUREC['KX_1' + commonLet4 + '_B'],'KX_1' + commonLet4 + '_B', 'G_SOUREC', 'KX_1' + commonLet4  +'_J1')"
              >
                {{infoList.G_SOUREC['KX_1' + commonLet4 + '_B']}}
              </span>
            </div>
            <div class="flex align-cen">
            A05-AOH
            <span class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList['G_MCSMAN__p__MAN' + commonLet14].OutT, '修改','OutT',
            'G_MCSMAN__p__MAN' + commonLet14
            )"
            >
              {{infoList['G_MCSMAN__p__MAN' + commonLet14].OutT}}
            </span>
          </div>
           <div class="flex align-cen">
            A05-AOL
            <span class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList['G_MCSMAN__p__MAN' + commonLet14].OutB, '修改','OutB',
            'G_MCSMAN__p__MAN' + commonLet14
            )
            "
            >
              {{infoList['G_MCSMAN__p__MAN' + commonLet14].OutB}}
            </span>
          </div>
          </div>
          <div class="left1-box-cen4">
            <div class="tr">
              <div>二级炉温补偿使能</div>
                 <div
            class="boxbtn"
            @click="
              toIpt(
                infoList.G_BJL[commonLet5 + 'WBCEN_EJ'],
                '按钮',
                commonLet5 + 'WBCEN_EJ',
                'G_BJL'
              )
            "
          >
            {{ infoList.G_BJL[commonLet5 + 'WBCEN_EJ'] ? "投用" : "切除" }}
          </div>
            </div>
             <div class="tr">
              <div>二级煤气补偿使能</div>
                 <div
            class="boxbtn"
            @click="
              toIpt(
                infoList.G_BJL[commonLet6 + 'QBCEN_EJ'],
                '按钮',
                commonLet6 + 'QBCEN_EJ',
                'G_BJL'
              )
            "
          >
            {{ infoList.G_BJL[commonLet6 + 'QBCEN_EJ'] ? "投用" : "切除" }}
          </div>
            </div>
          </div>
          <div class="left1-box-cen5">
              <div class="tr">
                <div class="flex align-cen">
                  A05-FMQ
                  <span class="spdata"
                @click="toCompon(2, 'FTMQ_B' + datatype + '1', 'G_BCS', 'FTMQ_B' + datatype +'1_J1','参与优化煤气量')"
                @dblclick="Cclick(infoList.G_BCS['FTMQ_B' + datatype + '1'],'FTMQ_B' + datatype + '1', 'G_BCS', 'FTMQ_B' + datatype +'1_J1')"
                  >
                    {{infoList.G_BCS['FTMQ_B' + datatype + '1']}}
                  </span>
                  <div class="ma-auto"
                  style="cursor: pointer;"
                  @click="toIpt(infoList.G_BCS['FFQH_B' + datatype + '1'], '按钮','FFQH_B' + datatype + '1','G_BCS')"
                  >
                    {{infoList.G_BCS['FFQH_B' + datatype + '1'] ? '软测量' : '实际测量'}}
                  </div>
                </div>
                 <div class="flex align-cen">
                  A05-MQTC
                  <span class="spdata"
                   style="color: #00ffb4;"
                      @click="toIpt(infoList.G_TEMP['FM' + datatype + '1TC'], '修改','FM' + datatype + '1TC','G_TEMP')"
                  >
                    {{infoList.G_TEMP['FM' + datatype + '1TC']}}
                  </span>
                  <div class="ma-auto"
                   style="color: #00ffb4;cursor: pointer;"
                   @click="toIpt(infoList.G_PVCL['MQS_B' + datatype + '1'], '修改','MQS_B' + datatype + '1','G_PVCL')"
                  >
                    {{infoList.G_PVCL['MQS_B' + datatype + '1']}}
                  </div>
                </div>
              </div>
              <div class="tr tr1">
                <div class="flex align-cen">
                   <span></span>
                   <div class="ma-auto"
                   style="cursor: pointer;"
                    @click="toIpt(infoList.G_TEMP['HX' + datatype + 'SEL'], '按钮','HX' + datatype + 'SEL','G_TEMP')"
                   >
                     {{infoList.G_TEMP['HX' + datatype + 'SEL'] ? '换向处理' : '换向不处理'}}
                   </div>
                </div>
                 <div class="flex align-cen">
                   换向处理延迟时间
                   <div class="ma-auto"
                   style="color: #00ffb4;cursor: pointer;"
                   @click="toIpt(infoList.G_PVCL['T_DELAY_HX' + datatype], '修改','T_DELAY_HX' + datatype,'G_PVCL')"
                   >
                     {{infoList.G_PVCL['T_DELAY_HX' + datatype]}}
                   </div>
                 </div>
              </div>
          </div>
          <div class="left1-box-cen6  flex align-cen">
            <span>煤气总管压力补偿</span>
           
              <div class="child-dataK child-databtn "
                :style="{
                background:
                infoList['G_MCSOTHER__p__QK' + commonLet7] &&
                !infoList['G_MCSOTHER__p__QK' + commonLet7].TS
                ? '#2AFC30'
                : 'red',
                }"
                @click="infoList['G_MCSOTHER__p__QK' + commonLet7] ? toDetail(2,'QK' + commonLet7,'G_MCSOTHER__p__QK' + commonLet7,'',(datatype == 1 ? '一加': datatype == 2 ? '二加': datatype == 3 ? '三加' : '均') + '热段煤气压力前馈' ): ''"
              >K</div>
          </div>
          <div class="left1-box-cen7">
            <div class="flex align-cen">
              煤气停烧阀位
              <span class="ma-auto"
              style="color: #00ffb4;"
              @click="toIpt(infoList.G_TEMP[commonLet8 + 'SMF'], '修改',commonLet8 + 'SMF','G_TEMP')"
              >
                {{infoList.G_TEMP[commonLet8 + 'SMF']}}
              </span>
            </div>
            <div class="flex align-cen">
              空气停烧阀位
              <span class="ma-auto"
              style="color: #00ffb4;"
              @click="toIpt(infoList.G_TEMP[commonLet8 + 'SKF'], '修改',commonLet8 + 'SKF','G_TEMP')"
              >
                {{infoList.G_TEMP[commonLet8 + 'SKF']}}
              </span>
            </div>
          </div>
      </div>
      <div class="JrparmsGroup-data-tit JrparmsGroup-data-tit1">{{ tit1 }}</div>
          
      <div class="JrparmsGroup-data-bot">

        <div class="bot-box1">
           <div class="box-cen1-top flex">
            <div class="lf">
              <div class="flex align-cen">
                AO7-TOP
                <span class="ma-auto"
                style="color: #00ffb4;"
                @click="toIpt(infoList[commonLet9].TOP,'修改','TOP',commonLet9)"
                >
                  {{infoList[commonLet9].TOP}}
                </span>
              </div>
               <div class="flex align-cen">
              A07-SOP
              <span class="ma-auto"
               style="color: #00ffb4;"
                @click="toIpt(infoList[commonLet9].SOP,'修改','SOP',commonLet9)"
                >
                 {{infoList[commonLet9].SOP}}
              
              </span>
            </div>
                 <div class="flex align-cen">
              A07-EOP
              <span class="ma-auto"
               style="color: #00ffb4;"
                @click="toIpt(infoList[commonLet9].EOP, '修改','EOP',commonLet9)"
                >
                {{infoList[commonLet9].EOP}}
              </span>
            </div>
              <div class="flex align-cen">
              A07-SYT(m)
              <span class="ma-auto"
               @click="toCompon(2, 'YHJGSYSJ', commonLet10, 'TIMELAP_B' + datatype  +'1_J1','优化剩余时间')"
               @dblclick="Cclick(infoList[commonLet10].YHJGSYSJ,'YHJGSYSJ', commonLet10, 'TIMELAP_B' + datatype + '1_J1')"
                >
             {{infoList[commonLet10].YHJGSYSJ}}
              </span>
            </div>
            </div>
            <div class="lf">
              <div class="flex align-cen">
                AO7-OH
                <span class="ma-auto"
                 @click="toCompon(2, 'YHZL_H', commonLet9, 'BCS_KH_B' + datatype  +'1_J1','空燃比优化上限')"
                @dblclick="Cclick(infoList[commonLet9].YHZL_H,'YHZL_H', commonLet9, 'BCS_KH_B' + datatype  +'1_J1')"
                >
                {{infoList[commonLet9].YHZL_H}}
                </span>
              </div>
               <div class="flex align-cen">
                AO7-OL
                <span class="ma-auto"
                 @click="toCompon(2, 'YHZL_L', commonLet9, 'BCS_KL_B' + datatype  +'1_J1','空燃比优化下限')"
                @dblclick="Cclick(infoList[commonLet9].YHZL_H,'YHZL_L', commonLet9, 'BCS_KL_B' + datatype  +'1_J1')"
                >
                {{infoList[commonLet9].YHZL_L}}
                </span>
              </div>
                  <div class="flex align-cen">
              A07-JG(m)
              <span class="ma-auto"
               style="color: #00ffb4;"
                @click="toIpt(infoList[commonLet9].YHJG, '修改','YHJG',commonLet9)"
                >
                {{infoList[commonLet9].YHJG}}
              </span>
            </div>
             <div class="flex align-cen">
                A07-OT
                <span class="ma-auto"
                 @click="toCompon(2, 'YHZL', commonLet10, 'AV' + (datatype == 1 ? '7': datatype == 2 ? '5': datatype == 3 ? '3' : '1')  +'_J1')"
                @dblclick="Cclick(infoList[commonLet10].YHZL,'YHZL', commonLet1, 'AV' + (datatype == 1 ? '7': datatype == 2 ? '5': datatype == 3 ? '3' : '1')  +'_J1')"
                >
                {{infoList[commonLet10].YHZL}}
                </span>
              </div>
              <div></div>
            </div>
          </div>
          <div class="box-cen1-bot flex align-cen">
            <div class="lf"
                :style="[{
              background:
                infoList[commonLet9].RESET
                  ? 'red'
                  : '#2AFC30',
            },
            {
              color:
               infoList[commonLet9].RESET
                  ? '#fff'
                  : '',
            }
            ]"
             @click="toIpt(infoList[commonLet9].RESET, '按钮','RESET',commonLet9)"
            >{{infoList[commonLet9].RESET ? '强制优化' :'自动优化'}}</div>
            <div class="ma-auto">
              <div class="dpr">
                <div class="npr"
                :style="{
                  width: infoList[commonLet10].PV + '%'
                }"
                ></div>
              </div>
            </div>
          </div>
        </div>
             <div
            class="boxbtnxz"
             @click="
              toIpt(
                infoList['G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__'+datatype+'__rr__'].BCSYHQY,
                '按钮',
                'BCSYHQY',
                'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__'+datatype+'__rr__'
              )
            "
          >
            {{ infoList['G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__'+datatype+'__rr__'].BCSYHQY ? "投用" : "切除" }}
              </div>
        <div class="bot-box2">
          <div class="flex align-cen">
            A07-DETMQH
            <div class="ma-auto"
            style="color: #00ffb4;cursor: pointer;"
           @click="toIpt(infoList.G_BCS['DETMQH' + datatype +'1'], '修改','DETMQH' + datatype +'1','G_BCS')"
            >
              {{infoList.G_BCS['DETMQH' + datatype +'1']}}
            </div>
            <div class="ma-auto"></div>
          </div>
          <div class="flex align-cen">
            A07-DETT
            <div class="ma-auto"
            style="color: #00ffb4;cursor: pointer;"
           @click="toIpt(infoList.G_BCS['DETT' + datatype +'1'], '修改','DETT' + datatype +'1','G_BCS')"
            >
              {{infoList.G_BCS['DETT' + datatype +'1']}}
            </div>
            <div class="ma-auto">S</div>
          </div>
           <div class="flex align-cen">
            A07-WAITT
            <div class="ma-auto"
            style="color: #00ffb4;cursor: pointer;"
           @click="toIpt(infoList.G_BCS['WAITT' + datatype +'1'], '修改','WAITT' + datatype +'1','G_BCS')"
            >
              {{infoList.G_BCS['WAITT' + datatype +'1']}}
            </div>
            <div class="ma-auto">S</div>
          </div>
        </div>
        <div class="bot-box3 flex align-cen">
          A09-FTMQ
          <div class="ma-auto"
          @click="toCompon(2, 'FTMQ_B' + datatype +'1', 'G_BCS', 'FTMQ_B' + datatype  +'1_J1','参与优化煤气量')"
          @dblclick="Cclick(infoList.G_BCS['FTMQ_B' + datatype +'1'],'FTMQ_B' + datatype +'1', 'G_BCS', 'FTMQ_B' + datatype  +'1_J1')"
          >
            {{infoList.G_BCS['FTMQ_B' + datatype +'1']}}
          </div>
        </div>
     
        <div class="bot-box4">
          <div class="flex align-cen">
            A08-FMBH
            <div class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList.G_BCS['JBFMBH_B' + datatype +'1'], '修改','JBFMBH_B' + datatype +'1','G_BCS')"
            >
              {{infoList.G_BCS['JBFMBH_B' + datatype +'1']}}
            </div>
          </div>
          <div class="flex align-cen">
            A08-FMBL
            <div class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList.G_BCS['JBFMBL_B' + datatype +'1'], '修改','JBFMBL_B' + datatype +'1','G_BCS')"
            >
              {{infoList.G_BCS['JBFMBL_B' + datatype +'1']}}
            </div>
          </div>
          <div class="flex align-cen">
            A08-YHKRB
            <div class="ma-auto"
            @click="toCompon(2, 'YHFMB_B' + datatype +'1', 'G_BCS', 'YHFMB_B' + datatype  +'1_J1','优化风煤比')"
          @dblclick="Cclick(infoList.G_BCS['YHFMB_B' + datatype +'1'],'YHFMB_B' + datatype +'1', 'G_BCS', 'YHFMB_B' + datatype  +'1_J1')"
            >
              {{infoList.G_BCS['YHFMB_B' + datatype +'1']}}
            </div>
          </div>
          <div class="flex align-cen">
           A08-JBFMB
            <div class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList.G_BCS['JBFMB_B' + datatype +'1'], '修改','JBFMB_B' + datatype +'1','G_BCS')"
            >
              {{infoList.G_BCS['JBFMB_B' + datatype +'1']}}
            </div>
          </div>
           <div class="flex align-cen">
            A08-SJFMB
            <div class="ma-auto"
            @click="toCompon(2, 'SJFMB_B' + datatype +'1', 'G_BCS', 'SJFMB_B' + datatype  +'1_J1','实际风煤比')"
          @dblclick="Cclick(infoList.G_BCS['SJFMB_B' + datatype +'1'],'SJFMB_B' + datatype +'1', 'G_BCS', 'SJFMB_B' + datatype  +'1_J1')"
            >
              {{infoList.G_BCS['SJFMB_B' + datatype +'1']}}
            </div>
          </div>
        </div>
        <div class="bot-box5">
            <div class="flex align-cen">
            A10-FKQ
            <div class="ma-auto"
            @click="toCompon(2, 'FTKQ_B' + datatype +'1', 'G_BCS', 'FTKQ_B' + datatype  +'1_J1','参与优化空气量')"
          @dblclick="Cclick(infoList.G_BCS['FTKQ_B' + datatype +'1'],'FTKQ_B' + datatype +'1', 'G_BCS', 'FTKQ_B' + datatype  +'1_J1')"
            >
              {{infoList.G_BCS['FTKQ_B' + datatype +'1']}}
            </div>
          </div>
          <div class="flex align-cen">
            A10-KQTC
            <div class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList.G_TEMP['FK' + datatype +'1TC'], '修改','FK' + datatype +'1TC','G_TEMP')"
            >
              {{infoList.G_TEMP['FK' + datatype +'1TC']}}
            </div>
          </div>
            <div class="flex align-cen">
            {{infoList.G_BCS['FFQH2_B' + datatype + '1'] ? '软测量' : '实际测量'}}
            <div class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList.G_PVCL['KQS_B' + datatype +'1'], '修改','KQS_B' + datatype +'1','G_PVCL')"
            >
              {{infoList.G_PVCL['KQS_B' + datatype +'1']}}
            </div>
          </div>
        </div>
         <div class="bot-box3 bot-box6 flex align-cen">
         A10-KQSP
          <div class="ma-auto"
          @click="toCompon(2, 'KQ' + datatype +'1SP', 'G_TEMP', 'KQ' + datatype +'1SP'  +'_J1')"
          @dblclick="Cclick(infoList.G_TEMP['KQ' + datatype +'1SP'],'KQ' + datatype +'1SP', 'G_TEMP', 'KQ' + datatype +'1SP'  +'1_J1')"
          >
            {{infoList.G_TEMP['KQ' + datatype +'1SP']}}
          </div>
        </div>
         <div class="bot-box5 bot-box7">
            <div class="flex align-cen">
            A10-AV
            <div class="ma-auto"
            @click="toCompon(2, 'KX_1' + commonLet11 +'_B', 'G_SOUREC', 'KX_1' + commonLet11  +'_J1')"
          @dblclick="Cclick(infoList.G_SOUREC['KX_1' + commonLet11 +'_B'],'KX_1' + commonLet11  +'_B', 'G_SOUREC', 'KX_1' + commonLet11  +'_J1')"
            >
              {{infoList.G_SOUREC['KX_1' + commonLet11 +'_B']}}
            </div>
          </div>
          <div class="flex align-cen">
           A10-AOH
            <div class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList['G_MCSMAN__p__MAN' + commonLet12].OutT, '修改','OutT','G_MCSMAN__p__MAN' + commonLet12)"
            >
              {{infoList['G_MCSMAN__p__MAN' + commonLet12].OutT}}
            </div>
          </div>
            <div class="flex align-cen">
           A10-AOL
            <div class="ma-auto"
             style="color: #00ffb4;"
            @click="toIpt(infoList['G_MCSMAN__p__MAN' + commonLet12].OutB, '修改','OutB','G_MCSMAN__p__MAN' + commonLet12)"
            >
               {{infoList['G_MCSMAN__p__MAN' + commonLet12].OutB}}
            </div>
          </div>
        </div>
        <div class="left-box1 left-box5 flex align-cen">
          <div>上限防抖</div>
         
          <div
            class="ma-auto"
            style="color: #00ffb4"
            @click="
              toIpt(
                infoList.G_TEMP['KQ' + datatype + '1_FDH'],
                '修改',
                'KQ' + datatype + '1_FDH',
                'G_TEMP'
              )
            "
          >
            {{ infoList.G_TEMP["KQ" + datatype + "1_FDH"] }}
          </div>
        </div>
           <div class="child-dataX2 child-databtn"
          :style="{
            background:
                infoList['G_MCSXK__p__XK' + commonLet13] &&
                infoList['G_MCSXK__p__XK' + commonLet13].RM == 1
                ? '#2AFC30'
                : 'red',
            }"
          @click="infoList['G_MCSXK__p__XK' + commonLet13] ?  toDetail(1,'XK' + commonLet13,'G_MCSXK__p__XK' + commonLet13,'',(datatype == 1 ? '一加': datatype == 2 ? '二加': datatype == 3 ? '三加' : '均') + '热空气流量XK' ): ''" 
          >X</div>
           <div class="child-dataA1 child-databtn"
          :style="{ background: infoList['G_MCSMAN__p__MAN' + commonLet12] && infoList['G_MCSMAN__p__MAN' + commonLet12].RM == 1 ? '#2AFC30' : 'red',}"
          @click="infoList['G_MCSMAN__p__MAN' + commonLet12] ? toCompon(0,'AV','G_MCSMAN__p__MAN' + commonLet12,'MAN' + commonLet12 +'_AV_J1',(datatype == 1 ? '一': datatype == 2 ? '二': datatype == 3 ? '三' : '均') + '热段空气阀门手操器MAN' + commonLet12) : ''" 
      >A</div>
      
      </div>
    </div>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
     <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
	  :chName="chName" 
      :infoList="infoList"
    ></Historical>
    <Firstcontrol
      :infoList="infoList"
      :isIndex="isIndex"
      :historyname="Firstcontrolname"
      :titname="Firstcontroltitname"
      :node="Firstcontrolnode"
      v-if="isFshow"
      @sendStatus="isFshow = false"
    ></Firstcontrol>
    <Rsf
      v-if="isRshow"
      @sendStatus="isRshow = false"
      :titname="Rsftitname"
      :infoList="infoList"
      :historyname="Rsfname"
      :node="Rsfnode"
    ></Rsf>
    <ParameterYh
      v-if="isComShow"
      :infoList="infoList"
      @sendStatus="isshowfase"
    ></ParameterYh>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isMshow = false"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import inputVal from "./inputVal"; //输入框组件
import Historical from "@/components/Historical.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Manual from "@/components/Manual.vue"; //手操器组件
import { downAction } from "@/api/index/index";

export default {
  name: "JrparmsGroup",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
    datatype: [String, Number], //接口返回的点名
    canshu: [String, Number], //接口返回的点名
  },
  components: { inputVal, Historical, Firstcontrol, Rsf, ParameterYh, Manual },
  data: () => {
    return {
		chName: '',
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
      tit: "",
      tit1:'',
      commonLet:'',
      commonLet1:'',
      commonLet2:'',
      commonLet3:'',
      commonLet4:'',
      commonLet5:'',
      commonLet6:'',
      commonLet7:'',
      commonLet8:'',
      commonLet9:'',
      commonLet10:'',
      commonLet11:'',
      commonLet12:'',
      commonLet13:'',
      commonLet14:''
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    datatype: {
      handler(n, o) {
        this.datatype = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
    // beforeCreate: function () {
    //     that = this;
    //   },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
    if(this.datatype == 1){
      this.tit = "一加热段煤气控制模型",this.tit1 = "一加热段空气控制模型"
    }else if(this.datatype == 2){
    this.tit = "二加热段煤气控制模型" ,this.tit1 = "二加热段空气控制模型"
    }else if(this.datatype == 3){
    this.tit = "三加热段煤气控制模型",this.tit1 = "三加热段空气控制模型"      
    }else{
      this.tit = "均热段煤气控制模型",this.tit1 = "均热段空气控制模型"
    }
    this.commonLet = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 2 ? '8': this.datatype == 3 ? '6': this.datatype == 2 ? '4' : '2') + '__rr____p__ZDBL'
    this.commonLet1 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 4 ? '8': this.datatype == 3 ? '6': this.datatype == 2 ? '4' : '2') + '__rr____p__DXBL'
    this.commonLet2 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 4 ? '8': this.datatype == 3 ? '6': this.datatype == 2 ? '4' : '2') + '__rr__'
    this.commonLet3 = 'G_MCSOTHER__p__QK' + (this.datatype == 4 ? '17': this.datatype == 3 ? '18': this.datatype == 2 ? '19' : '20')
    this.commonLet4 = (this.datatype == 4 ? '21': this.datatype == 3 ? '10': this.datatype == 2 ? '06' : '02')
    this.commonLet5 = (this.datatype == 4 ? 'YJRL': this.datatype == 3 ? 'EJRL': this.datatype == 2 ? 'SJRL' : 'JUNL')
    this.commonLet6 = (this.datatype == 4 ? 'YJRM': this.datatype == 3 ? 'EJRM': this.datatype == 2 ? 'SJRM' : 'JUNM')
    this.commonLet7 = (this.datatype == 4 ? '21': this.datatype == 3 ? '22': this.datatype == 2 ? '23' : '24')
    this.commonLet8 = (this.datatype == 4 ? 'YJT': this.datatype == 3 ? 'EJT': this.datatype == 2 ? 'SJT' : 'JRT')
    this.commonLet9 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 4 ? '7': this.datatype == 3 ? '5': this.datatype == 2 ? '3' : '1') + '__rr____p__ZDBL'
    this.commonLet10 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 4 ? '7': this.datatype == 3 ? '5': this.datatype == 2 ? '3' : '1') + '__rr____p__DXBL'
    this.commonLet11 = (this.datatype == 4 ? '20': this.datatype == 3 ? '09': this.datatype == 2 ? '05' : '01')
    this.commonLet12 = (this.datatype == 4 ? '20': this.datatype == 3 ? '9': this.datatype == 2 ? '5' : '1')
    this.commonLet13 = (this.datatype == 4 ? '22': this.datatype == 3 ? '20': this.datatype == 2 ? '18' : '16')
    this.commonLet14 = (this.datatype == 4 ? '21': this.datatype == 3 ? '10': this.datatype == 2 ? '6' : '2')
    this.commonLet = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 1 ? '8': this.datatype == 2 ? '6': this.datatype == 3 ? '4' : '2') + '__rr____p__ZDBL'
    this.commonLet1 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 1 ? '8': this.datatype == 2 ? '6': this.datatype == 3 ? '4' : '2') + '__rr____p__DXBL'
    this.commonLet2 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 1 ? '8': this.datatype == 2 ? '6': this.datatype == 3 ? '4' : '2') + '__rr__'
    this.commonLet3 = 'G_MCSOTHER__p__QK' + (this.datatype == 1 ? '17': this.datatype == 2 ? '18': this.datatype == 3 ? '19' : '20')
    this.commonLet4 = (this.datatype == 1 ? '21': this.datatype == 2 ? '10': this.datatype == 3 ? '06' : '02')
    this.commonLet5 = (this.datatype == 1 ? 'YJRL': this.datatype == 2 ? 'EJRL': this.datatype == 3 ? 'SJRL' : 'JUNL')
    this.commonLet6 = (this.datatype == 1 ? 'YJRM': this.datatype == 2 ? 'EJRM': this.datatype == 3 ? 'SJRM' : 'JUNM')
    this.commonLet7 = (this.datatype == 1 ? '21': this.datatype == 2 ? '22': this.datatype == 3 ? '23' : '24')
    this.commonLet8 = (this.datatype == 1 ? 'YJT': this.datatype == 2 ? 'EJT': this.datatype == 3 ? 'SJT' : 'JRT')
    this.commonLet9 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 1 ? '7': this.datatype == 2 ? '5': this.datatype == 3 ? '3' : '1') + '__rr____p__ZDBL'
    this.commonLet10 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 1 ? '7': this.datatype == 2 ? '5': this.datatype == 3 ? '3' : '1') + '__rr____p__DXBL'
    this.commonLet11 = (this.datatype == 1 ? '20': this.datatype == 2 ? '09': this.datatype == 3 ? '05' : '01')
    this.commonLet12 = (this.datatype == 1 ? '20': this.datatype == 2 ? '9': this.datatype == 3 ? '5' : '1')
    this.commonLet13 = (this.datatype == 1 ? '22': this.datatype == 2 ? '20': this.datatype == 3 ? '18' : '16')
    this.commonLet14 = (this.datatype == 1 ? '21': this.datatype == 2 ? '10': this.datatype == 3 ? '6' : '2')
    this.commonLet = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 2 ? '8': this.datatype == 3 ? '6': this.datatype == 2 ? '4' : '2') + '__rr____p__ZDBL'
    this.commonLet1 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 4 ? '8': this.datatype == 3 ? '6': this.datatype == 2 ? '4' : '2') + '__rr____p__DXBL'
    this.commonLet2 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 4 ? '8': this.datatype == 3 ? '6': this.datatype == 2 ? '4' : '2') + '__rr__'
    this.commonLet3 = 'G_MCSOTHER__p__QK' + (this.datatype == 4 ? '17': this.datatype == 3 ? '18': this.datatype == 2 ? '19' : '20')
    this.commonLet4 = (this.datatype == 4 ? '21': this.datatype == 3 ? '10': this.datatype == 2 ? '06' : '02')
    this.commonLet5 = (this.datatype == 4 ? 'YJRL': this.datatype == 3 ? 'EJRL': this.datatype == 2 ? 'SJRL' : 'JUNL')
    this.commonLet6 = (this.datatype == 4 ? 'YJRM': this.datatype == 3 ? 'EJRM': this.datatype == 2 ? 'SJRM' : 'JUNM')
    this.commonLet7 = (this.datatype == 4 ? '21': this.datatype == 3 ? '22': this.datatype == 2 ? '23' : '24')
    this.commonLet8 = (this.datatype == 4 ? 'YJT': this.datatype == 3 ? 'EJT': this.datatype == 2 ? 'SJT' : 'JRT')
    this.commonLet9 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 4 ? '7': this.datatype == 3 ? '5': this.datatype == 2 ? '3' : '1') + '__rr____p__ZDBL'
    this.commonLet10 = 'G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__' + (this.datatype == 4 ? '7': this.datatype == 3 ? '5': this.datatype == 2 ? '3' : '1') + '__rr____p__DXBL'
    this.commonLet11 = (this.datatype == 4 ? '20': this.datatype == 3 ? '09': this.datatype == 2 ? '05' : '01')
    this.commonLet12 = (this.datatype == 4 ? '20': this.datatype == 3 ? '9': this.datatype == 2 ? '5' : '1')
    this.commonLet13 = (this.datatype == 4 ? '22': this.datatype == 3 ? '20': this.datatype == 2 ? '18' : '16')
    this.commonLet14 = (this.datatype == 4 ? '21': this.datatype == 3 ? '10': this.datatype == 2 ? '6' : '2')
  },
  methods: {
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontol";
            break;
          case 2:
            pathname = "jrkfirstcontol";
            break;
             case 3:
            pathname = "jrRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
         checkStu(key,boo) {
      this.$confirm('确定要修改温度的选择吗？', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let mark = 'T' + key + '1SEL'
          let val
          if(boo == 1){
             val = this.infoList.G_TEMP['T' + key + '1SEL'] == 1 ? '254' : '1'
          }else{
            val = this.infoList.G_TEMP['T' + key + '1SEL'] == 2 ? '253' : '2'
          } 
          downAction({
            device: this.projectData.project,
            mark: mark ,
            node: 'G_TEMP',
            value: val,
            cp_type: this.projectData.type,
          })
            .then((res) => {
              if (res.info.res == 1) {
               
                this.infoList.G_TEMP['T' + key + '1SEL'] = res.item.value
              } else {
                this.$message.error("出错了");
              }
            })
            .catch((err) => {
              this.$message.error("下置失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "JrparmsGroup", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (
            	(this.isHshow = true),
            	(this.chName = name4)
            );
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
    filters: {
    
    // 过滤器
    // typeFifter: (val) => {
    //   switch(val){
    //     case 4:
    //     return that.infoList.G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__8__rr____p__ZDBL.TOP
    //     case 3:
    //     return that.infoList.G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__6__rr____p__ZDBL.TOP
    //     case 2:
    //     return that.infoList.G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll_4__rr____p__ZDBL.TOP
    //     case 1:
    //     return that.infoList.G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL.TOP
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.flex{
  font-family: 'PingFang-SC-Regular';
  font-size: .8vw;
}
.JrparmsGroup {
  width: 83vw;
  height: 91vh;
  background: url("~@/assets/images/jrl-param.png") no-repeat 0 0;
  background-size: 100% 100%;
  cursor: move;
  border-radius: 5px;
   .fl{
         float: left;
     }
     .fr{
         float: right;
     }
       .title-hang {
    width: 100%;
    height: 8vh;
    text-align: center;
    	font-family: MicrosoftYaHei;
	font-size: 3vh;
	font-weight: normal;
	font-stretch: normal;
	line-height: 8vh;
	letter-spacing: 0vh;
	color: #0ef7ff;
  }
     .param11-header-icon {
      width: 2vw;
      height: 2vh;
      right: 1vw;
      margin-top: -5vh;
      cursor: pointer;
      margin-left:80vw;
    }
  .param11-header {
    height: 7vh;
    //  background: #5DCEF3;
    line-height: 7vh;
    text-align: center;
    color: #041a28;
    font-size: 1vw;
    position: relative;
    border-radius: 5px 5px 0 0;
    .param11-header-title {
      width: 179px;
      height: 28px;
      font-family: MicrosoftYaHei;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;

      letter-spacing: 0px;
      color: #0ef7ff;
    }
    .param11-header-icon {
      width: 2vw;
      height: 2vh;
      position: absolute;
      right: 1vw;
      margin-top: -5vh;
      cursor: pointer;
    }
  }
  .JrparmsGroup-data {
    .child-databtn {
      color: #fff;
      width: 1vw;
      height: 2vh;
      line-height: 2vh;
      text-align: center;
      box-sizing: border-box;
      border: 1px solid #fdf061;
      font-size: 0.8vw;
      cursor: pointer;
    }
    .JrparmsGroup-data-tit {
      margin-top: 5vh;
      width: 100%;
      height: 26px;
      font-family: PingFang-SC-Regular;
      line-height: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-size: 1.2vw;
      text-align: center;
      letter-spacing: 6px;
      color: #d5fffe;
    }
    .JrparmsGroup-data-tit1{
      margin-top: 0;
      position: absolute;
      top: 54vh;
    }
    .JrparmsGroup-data-top {
      width: 74vw;
      height: 38vh;
      margin: 0 auto;
      background: url("~@/assets/images/jrl4.png") no-repeat 9.3vw 7vh;
      background-size: 59vw 31vh;
      position: relative;
      .top-text1 {
        height: 3vh;
        line-height: 3vh;
        color: #8aeaff;
        font-size: 1vw;
        span {
          padding-left: 1vw;
          color: #00ffb4;
          font-size: 1vw;
          cursor: pointer;
        }
      }
      .left-box1 {
        width: 7.8vw;
        height: 3vh;
        border: solid 1px #236f8d;
        border-radius: 2px;
        color: #8aeaff;
        font-size: .8vw;
        padding: 0 0.5vw;
        margin-top: 1vh;
        .ma-auto {
          color: rgb(0, 180, 255);
          font-size: 0.8vw;
          cursor: pointer;
        }
      }
      .left-box2 {
        position: absolute;
        top: 3vh;
        left: 14vw;
        width: 9vw;
      }
      .left-box3 {
        position: absolute;
        top: 3vh;
        left: 29vw;
        width: 9vw;
      }
      .left-box4 {
        position: absolute;
        top: 3vh;
        left: 49vw;
        width: 7vw;
        border: none;
        font-size: 0.8vw;
      }
      .child-dataX {
        position: absolute;
        top: 8vh;
        left: 29.5vw;
      }
      .child-dataA{
        position: absolute;
        top: 8vh;
        right: 5vw;
      }
      .btnright {
        position: absolute;
        top: 8vh;
        left: 60vw;
      }
      .box {
        .child-dataR {
          margin-left: 3.5vw;
          margin-top: 2vh;
        }
        .boxbtn {
          width: 3vw;
          height: 2vh;
          text-align: center;
          line-height: 2vh;
          background: #22fff7;
          border-radius: 2px;
          color: #0a4c62;
          font-size: 0.8vw;
          margin-top: 2vh;
          margin-left: 1vw;
          cursor: pointer;
        }
        .boxbtn1 {
          margin-left: 5vw;
        }
      }
      .left1-box-cen{
        width: 38vw;
        height: 5vh;
        background-color: #001b2860;
	      border: solid 1px #236f8d;
        margin-top: 1vh;
        justify-content: center;
        .box-cen1 {
          width: 100%;
          height: 2.5vh;
          div{
            width: 8.5vw;
            padding: 0 .5vw;
            color: #8aeaff;
            font-size: .8vw;
            text-align: right;
            span{
              color: #01ffba;
              cursor: pointer;
            }
          }
        }
      }
      .left1-box-cen1{
        width: 18vw;
        height: 15vh;
        background-color: #001b2860;
	      border: solid 1px #236f8d;
        position: relative;
           .boxbtn2{
          width: 3vw;
          height: 2vh;
          text-align: center;
          line-height: 2vh;
          background: #22fff7;
          border-radius: 2px;
          color: #0a4c62;
          font-size: 0.8vw;
          cursor: pointer;
          position: absolute;
          top: 3vh;
          left: 19vw;
      }
      .spbt{
        position: absolute;
        left:25vw;
        width: 12vw;
        top: .5vh;
        span{color: #2fc3e3;font-size: 1vw;padding-right: 2vw;}
      }
        .box-cen1-top{
          width: 100%;
          
          height: 11vh;
          .lf{
            width: 8vw;
            padding: .5vh .5vw;
            color: #8aeaff;
            height: inherit;
            font-size: .8vw;
            div{
              height: 2.2vh;
              line-height: 2.2vh;
           
            span{
               cursor: pointer;
               color: rgb(0, 180, 255);
            }
            }
          }
        }
        .box-cen1-bot{
          width: 100%;
          height: 3vh;
          .lf{
            width: 5vw;
            height: 2vh;
            background-color: #04ff57;
	          border-radius: 2px;
            color: #0a4c62;
            font-size: .8vw;
            line-height: 2vh;
            text-align: center;
            margin-left: 1vw;
            margin-top: 1vh;
            cursor: pointer;
          }
          .ma-auto{
            margin-right: .5vw;
            margin-top: 1vh;
            color: rgb(0, 180, 255);
            .dpr{
            width: 8vw;
            height: 1vh;
            background-color: #207294;
            .npr{
              background-color: #10fec0;
              height: inherit;
              
            }
          }
          }
          
        }
      }
      .left1-box-cen2{
         width: 18vw;
        height: 3vh;
        background-color: #001b2860;
	      border: solid 1px #236f8d;
        margin-top: 0.5vh;
        position: relative;
        .flex{
          width: 8vw;
          padding: 0 .5vw;
          height: inherit;
          line-height: 3vh;
          color: #8aeaff;
          font-size: .8vw;
          span{
            width: 2vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space:nowrap;
             cursor: pointer;
          }
        }
      }
      .left1-box-cen3{
        width: 8vw;
        height: 6vh;
         background-color: #001b2860;
	      border: solid 1px #236f8d;
        position: absolute;
        right: 0;
        top:11vh;
        padding: 1vh .5vw;
        color: #8aeaff;
            font-size: .8vw;
            line-height: 2vh;
            text-align: center;
       span{
         color: #01ffba;
         cursor: pointer;
       }
      }
      .left1-box-cen4{
        width: 9vw;
        position: absolute;
        right: 0;
        top:25vh;
        text-align: center;
        .tr{
         color: #c7f2f3;
         font-size: 1vw;
            .boxbtn {
          width: 3vw;
          height: 2vh;
          text-align: center;
          line-height: 2vh;
          background: #22fff7;
          border-radius: 2px;
          color: #0a4c62;
          font-size: 0.8vw;
          
          cursor: pointer;
          margin: 0 auto;
           margin-top: 1vh;
        }
        }
      }
      .left1-box-cen5{
      width: 13vw;
      height: 11vh;
      padding: .5vh .5vw;
       background-color: #001b2860;
	      border: solid 1px #236f8d;
        position: absolute;
         right: 15vw;
        top:11vh;
        .tr{
          height: 6vh;
          width: 100%;
          div{
           color: #8aeaff;
           span{
               cursor: pointer;
               color: rgb(0, 180, 255);
               margin-left: 1vw;
            }
          }
        }
        .tr1{
          border-top: 1px solid #236f8d;
          box-sizing: border-box;
          height: 6vh;
          div{
            height: 2.5vh;
          }
        }
      }
      .left1-box-cen6{
        width: 12vw;
        position: absolute;
          right: 15vw;
        top:23vh;
        span{
          color: #2fc3e3;
          font-size: 1vw;
          padding-right: 2vw;
        }
      }
      .left1-box-cen7{
        height: 6vh;
        width: 9vw;
         background-color: #001b2860;
	      border: solid 1px #236f8d;
        position: absolute;
          right: 15vw;
        bottom:1vh;
        padding: .5vh .5vw;
        color: #8aeaff;
        span{
               cursor: pointer;
            
            }
      }
    }
    .JrparmsGroup-data-bot{
      width: 74vw;
      height: 31vh;
      margin: -20vh 0 0 5vw;
      position: relative;
      background: url("~@/assets/images/jrl3.png") no-repeat 6.5vw 6vh;
      background-size: 61vw 20vh;
      margin-top: 5vh;
      .bot-box1{
        height: 17vh;
        width: 18vw;
        background-color: #001b2860;
	      border: solid 1px #236f8d;
        margin-top: 2vh;
         .box-cen1-top{
          width: 100%;
          
          height: 12.5vh;
          .lf{
            width: 8vw;
            padding: .5vh .5vw;
            color: #8aeaff;
            height: inherit;
            div{
              height: 3vh;
              line-height: 3vh;
           
            span{
               cursor: pointer;
               color: rgb(0, 180, 255);
            }
            }
          }
        }
        .box-cen1-bot{
          width: 100%;
          height: 3vh;
          .lf{
            width: 5vw;
            height: 2vh;
            background-color: #04ff57;
	          border-radius: 2px;
            color: #0a4c62;
            font-size: .8vw;
            line-height: 2vh;
            text-align: center;
            margin-left: 1vw;
            margin-top: 1vh;
            cursor: pointer;
          }
          .ma-auto{
            margin-right: .5vw;
            margin-top: 1vh;
            color: rgb(0, 180, 255);
            .dpr{
            width: 8vw;
            height: 1vh;
            background-color: #207294;
            .npr{
              background-color: #10fec0;
              height: inherit;
              
            }
          }
          }
          
        }
      }
      .bot-box2{
         height: 8vh;
        width: 10vw;
        background-color: #001b2860;
	      border: solid 1px #236f8d;
        margin-top: 6vh;
        padding: .5vh .5vw;
        color: #8aeaff;
        div{
          height: 2.6vh;
        }
      }
      .bot-box3 {
        width: 10vw;
        height: 3vh;
        background-color: #001b2860;
	      border: solid 1px #236f8d;
        padding: .5vh .5vw;
         color: #8aeaff;
         position: absolute;
         left: 12vw;
         top: 22vh;
         .ma-auto{
           color: rgb(0, 180, 255);
           cursor: pointer;
         }
      }
      .bot-box4{
          height: 12vh;
        width: 10vw;
        background-color: #001b2860;
	      border: solid 1px #236f8d;
        margin-top: 4vh;
        padding: .5vh .5vw;
        color: #8aeaff;
         position: absolute;
        left: 29vw;
        top: 4vh;
        .flex{
          height: 2.5vh;
          line-height: 2.5vh;
        }
        .ma-auto{
           color: rgb(0, 180, 255);
           cursor: pointer;
         }
      }
      .bot-box5{
          height: 8vh;
        width: 10vw;
        background-color: #001b2860;
	      border: solid 1px #236f8d;
         padding: .5vh .5vw;
          color: #8aeaff;
          position: absolute;
        left: 49vw;
        top: 9.5vh;
        .flex{
          height: 2.5vh;
          line-height: 2.5vh;
        }
        .ma-auto{
           color: rgb(0, 180, 255);
           cursor: pointer;
         }
      }
      .bot-box6{
         left: 42vw;
        top: 19vh;
      }
      .bot-box7{
          left: 63vw;
        top: 13vh;
      }
           .left-box5{
        width: 10vw;
        position: absolute;
        bottom: 1vh;
        left: 49vw;
        width: 7vw;
        border: none;
        font-size: 0.8vw;
        height: 3vh;
        border-radius: 2px;
        color: #8aeaff;
        .ma-auto {
          color: rgb(0, 180, 255);
          font-size: 0.8vw;
          cursor: pointer;
        }
      }
      .child-dataX2{
         position: absolute;
        bottom: 5.5vh;
        left: 60vw;
      }
      .child-dataA1{
        position: absolute;
        bottom: 5.5vh;
        left: 67vw;
      }
    }
  }
  .boxbtnxz{
    width:3vw;
    height:2vh;
    line-height:2vh;
    font-size:0.8vw;
    color:#000;
    text-align: center;
       background-color: #22fff7;
	      border: solid 1px #236f8d;
        margin:-15vh 0 0 25vw;
      float:left;
      // z-index: 100;
  }
}
</style>
 