<template>
	<div class="jrlmqzg drag" id="jrlmqzg" v-draw>
		<!-- 标题部分 -->
		<div>
			<div class="title-hang" fl>
				{{ projectData.project }}_煤气总管压力模型
			</div>
			<div class="param11-header-icon" fr @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="titlelable">煤气总管压力控制参数</div>
		<div class="main-top">
			<div class="row1">
				<div class="fl row1-col1">A01-TSP</div>
				<div class="fl row1-col2" @click="
                  toIpt(
                    infoList.G_PFJ.PMQ_SP,
                    'A01-TSP',
                     'PMQ_SP',
                    'G_PFJ',
                   
                  )
                ">
					{{ infoList.G_PFJ.PMQ_SP}}
				</div>
			</div>
			<div class="row2">
				<div class="fl col1">
					<div>
						<div class="fl row1-col1">A01-TPV</div>
						<div class="fl row1-col2" style="color:#8aeaff"
							@click="toCompon(2, 'PIA_105_B', 'G_SOUREC', 'PIA_105_J1','煤气总管压力')"
							@dblclick="Cclick(infoList.G_SOUREC.PIA_105_B,'PIA_105_B', 'G_SOUREC', 'PIA_105_J1')">
							{{infoList.G_SOUREC.PIA_105_B}}</div>
					</div>
					<div class="col1-2">
						<div class="fl row1-col1">PMQTC</div>
						<div class="fl row1-col2" @click="
                  toIpt(
                    infoList.G_PFJ.PMQTC,
                    'PMQTC',
                     'PMQTC',
                    'G_PFJ',
                   
                  )
                ">
							{{ infoList.G_PFJ.PMQTC }}
						</div>
					</div>
				</div>
				<div class="fl col2">
					<div class="col1-2 jg fl">
						<div class="fl row1-col1">A02-SUMPV</div>
						<div class="fl row1-col3"
							@click="toCompon(2, 'PV', 'G_MCSOTHER__p__QK7', 'QK7_PV_J1','煤气总管煤气阀门前馈')"
							@dblclick="Cclick(infoList.G_MCSOTHER__p__QK7.PV,'PV', 'G_MCSOTHER__p__QK7', 'QK7_PV_J1')">
							{{infoList.G_MCSOTHER__p__QK7.PV}}</div>
					</div>
					<div class="fl buttonc">
						<div class="bottonx jg1" :style="{
              background:
                infoList.G_MCSXK__p__XK14 &&
                !infoList.G_MCSXK__p__XK14.XK14
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK14 ? toDetail(1,'XK14','G_MCSXK__p__XK14','','煤气总管压力控制XK' ): ''">
							X
						</div>
						<div class="bottonx jg2" :style="{
              background:
                infoList.G_MCSOTHER__p__QK7 &&
                !infoList.G_MCSOTHER__p__QK7.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK7 ? toDetail(2,'QK7','G_MCSOTHER__p__QK7','','煤气总管煤气阀门前馈' ): ''">K
						</div>
					</div>
				</div>
				<div class="fl col3">
					<div class="bottonx jg3" :style="{
              background:
                infoList.G_MCSMAN__p__MAN24 &&
                infoList.G_MCSMAN__p__MAN24.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN24
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN24',
                    'MAN24_RM_J1',
                    '煤气总管压力手操器'
                  )
                : ''
            ">A</div>

				</div>

			</div>
		</div>


		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isClose" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList"></Manual>


		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>
	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "JRL_mqzg",
		components: {
			Manual,
			Historical,
			inputVal,
		},
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		data: () => {
			return {
				chName: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				isMshow: false,
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
				spotArr: [],
				authInfo: [],
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
		},
		computed: {},
		mounted() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'jrkfirstcontol'
							break
						case 3:
							pathname = 'jrRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			closeCompon() {
				this.$emit("sendStatus", "JRL_mqzg", false);
			},
			isClose() {
				this.isMshow = false;
			},
			isClose1() {
				this.isHshow = false;
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (
								(this.isHshow = true),
								(this.chName = name4)
							);
					}
				}, 300);
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.jrlmqzg {
		width: 53vw;
		height: 78vh;
		//  background: #e9f7ff;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		//  cursor:move;
		border-radius: 5px;

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.main-top {
			margin-left: 3.5vw;
			font-size: 0.9vw;
			;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 53vw;
			font-family: MicrosoftYaHei;
			font-size: 0.9vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			padding-top: 0.5vh;
			// margin-top:vh;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}


		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 1.5vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.lable1 {
			font-family: PingFang-SC-Regular;
			font-size: 1.1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-left: 2.5vw;
			margin-top: 1vh;
		}

		.titlelable {
			width: 53vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 1.5vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0.3vw;
			color: #d5fffe;
			text-align: center;
			margin-top: 4vh;
		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 50vw;
		}

		.button-top {
			width: 5vw;
			height: 3vh;
			background-color: #008db1;
			text-align: center;
			line-height: 3vh;
			margin-right: 3vw;
		}

		.col2 {
			margin-top: 2vh;
			width: 30vw;
			height: 21vh;
			background-image: url("~@/assets/images/jrl-mqzg.png");
			background-size: 100% 100%;
			margin-left: 0.5vw;
		}

		.row1 {
			margin-left: 14vw;
			width: 10vw;
			height: 4vh;
			background-color: #184054;
			border-radius: 0vh;
			border: solid 0vh #236f8d;
			margin-top: 4vh;
			font-family: PingFang-SC-Regular;

			font-weight: normal;
			font-stretch: normal;
			line-height: 1vw;
			letter-spacing: 0vw;

		}

		.row1-col1 {
			color: #8aeaff;
			line-height: 4vh;
			width: 7vw;
			text-align: 5vw;
			;
			// font-size: vh;;
			// margin-left: 1vw;;
		}

		.row1-col2 {
			color: #00ffb4;
			line-height: 4vh;
			cursor: pointer;
			//  font-size: 3vh;;
		}

		.row1-col3 {
			color: #8aeaff;
			line-height: 4vh;
			cursor: pointer;
			//  font-size: 3vh;;
		}

		.col1-2 {
			width: 11vw;
			height: 4vh;
			background-color: #184054;
			border-radius: 0vw;
			border: solid 0vw #236f8d;
			margin-top: 5vh;




		}

		.col1 {
			width: 11vw;
			height: 4vh;
			margin-top: 5vh;
			margin-left: -2vw;

		}

		.jg {
			margin-top: 19vh;
			margin-left: 1.5vw;
		}

		.buttonc {
			margin-left: 4vw;
		}

		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 0.9vw;
			// margin-right: 0.1vw;
			color: #fff;
			cursor: pointer;
		}

		.jg1 {
			margin-top: 4.5vh;
		}

		.jg2 {
			margin-top: 12vh;
		}

		.jg3 {
			margin-top: 6.5vh;
		}


	}
</style>
