var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"Jrindex",staticStyle:{"height":"100%"},attrs:{"element-loading-text":"系统正在初始化","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(255, 255, 255, 0.6)"}},[_c('div',{ref:"bigbox",staticClass:"jrindex-conitaner",style:(_vm.scaleHeight)},[(_vm.maskbok)?_c('div',{staticClass:"maskbok"}):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"加热炉燃烧优化控制系统")]),_c('div',{staticClass:"Jrindex-tit"},[_c('div',{staticClass:"Jrindex-tit-top flex"},[_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("煤气总流量")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'FICA_117_B',
                'G_SOUREC',
                'FICA_117_J1',
                '煤气总管流量'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.G_SOUREC.FICA_117_B,
                'FICA_117_B',
                'G_SOUREC',
                'FICA_117_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_117_B)+" m³/h ")])]),_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("入炉钢坯数量(PLC)")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(2, 'CGNO_B', 'G_SOUREC', 'GPRLNUM_J1', '入炉板坯数量')},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.G_SOUREC.CGNO_B,
                'CGNO_B',
                'G_SOUREC',
                'GPRLNUM_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.CGNO_B)+" 根 ")])]),_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("入炉钢坯数量(BCS)")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(2, 'KHGGLJB', 'G_KHTJ', 'KHGGLJB_J1', '过钢量班累积')},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.G_KHTJ.KHGGLJB,
                'KHGGLJB',
                'G_KHTJ',
                'KHGGLJB_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_KHTJ.KHGGLJB)+" 根 ")])]),_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("入炉钢温")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(2, 'RGT_B', 'G_SOUREC', 'RGT_J1', '入钢温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_SOUREC.RGT_B, 'RGT_B', 'G_SOUREC', 'RGT_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.RGT_B)+" ℃ ")])]),_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("开轧温度(PLC)")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(2, 'CGT_B', 'G_SOUREC', 'CGT_J1', '开轧温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_SOUREC.CGT_B, 'CGT_B', 'G_SOUREC', 'CGT_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.CGT_B)+" ℃ ")])]),_c('div',{staticClass:"text-row flex align-cen"},[_c('div',[_vm._v("开轧温度(BCS)")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(2, 'CGT_B', 'G_SOUREC', 'CGT_J1', '开轧温度')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_SOUREC.CGT_B, 'CGT_B', 'G_SOUREC', 'CGT_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.CGT_B)+" ℃ ")]),_c('div',{staticClass:"child-dataR child-databtn ma-auto",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__RSF2 &&
                !_vm.infoList.G_MCSOTHER__p__RSF2.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF2
                ? _vm.toDetail(
                    3,
                    'RSF2',
                    'G_MCSOTHER__p__RSF2',
                    '',
                    '出钢温度软伺服'
                  )
                : ''}}},[_vm._v(" R ")])])]),_c('div',{staticClass:"Jrindex-tit-bot"},[_c('span',{on:{"click":function($event){return _vm.toCompon(6)}}},[_vm._v("钢损模型")]),_c('span',{on:{"click":function($event){return _vm.toCompon(
              2,
              'GSM',
              'G_KHTJ__p__GSMX1',
              'GSMX_GSM_J1',
              '钢损模型氧化烧损量'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.G_KHTJ__p__GSMX1.GSM,
              'GSM',
              'G_KHTJ__p__GSMX1',
              'GSMX_GSM_J1'
            )}}},[_vm._v(_vm._s(_vm.infoList.G_KHTJ__p__GSMX1.GSM)+"%")])])]),_c('div',{staticClass:"el-container-box",style:(_vm.conHeight)},[_c('div',{staticClass:"el-container-box-cen"},[_c('div',{staticClass:"el-container-box-cen-coulm1"},[_c('div',{staticClass:"coulm1-top flex align-cen"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                background:
                  _vm.infoList.G_MCSMAN__p__MAN13 &&
                  _vm.infoList.G_MCSMAN__p__MAN13.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN13
                  ? _vm.toCompon(
                      0,
                      'AV',
                      'G_MCSMAN__p__MAN13',
                      'MAN13_AV_J1',
                      '空烟气总管阀BCS手操器MAN13'
                    )
                  : ''}}},[_vm._v(" A ")]),_c('img',{staticClass:"jrlimg",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../assets/images/rts_jrlimg.png"),"alt":""},on:{"click":function($event){return _vm.toCompon(3)}}}),_c('div',{staticClass:"line"}),_c('img',{staticClass:"jflimg1",attrs:{"src":require("../../assets/images/rts_jrlimg1.png"),"alt":""}}),_c('div',{staticClass:"jrl-row flex"},[_c('div',{staticClass:"line1"}),_c('div',{staticClass:"fam1"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_113_B',
                      'G_SOUREC',
                      'KF_113_J1',
                      '空烟气总管阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.G_SOUREC.KF_113_B,
                      'KF_113_B',
                      'G_SOUREC',
                      'KF_113_J1'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_113_B)+"% ")])]),_c('div',{staticClass:"jrl_auto flex"},[_c('div',{staticClass:"list-auto1 flex"},[_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.YJRKPTSP,
                          '修改',
                          'YJRKPTSP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.YJRKPTSP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T42PV1',
                          'G_TEMP',
                          'T42PV1_J1',
                          '一加热空烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T42PV1,
                          'T42PV1',
                          'G_TEMP',
                          'T42PV1_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T42PV1)+"℃ ")])]),_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.YJRMPTSP,
                          '修改',
                          'YJRMPTSP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.YJRMPTSP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T42PV2',
                          'G_TEMP',
                          'T42PV2_J1',
                          '一加热段煤烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T42PV2,
                          'T42PV2',
                          'G_TEMP',
                          'T42PV2_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T42PV2)+"℃ ")])]),_c('div',{staticClass:"text2 flex align-cen"},[_c('div',{staticClass:"text"},[_vm._v("烟气含氧量")]),_c('div',{staticStyle:{"color":"rgb(0, 180, 255)","padding-left":"2vw"},on:{"click":function($event){return _vm.toIpt(_vm.infoList.G_PFJ.O2SP, '修改', 'O2SP', 'G_PFJ')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.O2SP)+"% ")]),_c('div',{staticStyle:{"color":"#d9feff","padding-left":"1vw"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'CO2_B',
                          'G_SOUREC',
                          'CO2_J1',
                          '烟气含氧量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.CO2_B,
                          'CO2_B',
                          'G_SOUREC',
                          'CO2_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.CO2_B)+"% ")]),_c('div',{staticClass:"child-dataR child-databtn",style:({
                        background:
                          _vm.infoList.G_MCSOTHER__p__RSF5 &&
                          !_vm.infoList.G_MCSOTHER__p__RSF5.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF5
                          ? _vm.toDetail(
                              3,
                              'RSF5',
                              'G_MCSOTHER__p__RSF5',
                              '',
                              '备用'
                            )
                          : ''}}},[_vm._v(" R ")])])]),_c('div',{staticClass:"list-auto1 flex"},[_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.EJRKPTSP,
                          '修改',
                          'EJRKPTSP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.EJRKPTSP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T32PV1',
                          'G_TEMP',
                          'T32PV1_J1',
                          '二加热段空烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T32PV1,
                          'T32PV1',
                          'G_TEMP',
                          'T32PV1_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T32PV1)+"℃ ")])]),_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.EJRMPTSP,
                          '修改',
                          'EJRMPTSP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.EJRMPTSP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T32PV2',
                          'G_TEMP',
                          'T32PV2_J1',
                          '二加热段煤烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T32PV2,
                          'T32PV2',
                          'G_TEMP',
                          'T32PV2_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T32PV2)+"℃ ")])])]),_c('div',{staticClass:"list-auto1 flex"},[_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.SJRKPTSP,
                          '修改',
                          'SJRKPTSP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.SJRKPTSP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T22PV1',
                          'G_TEMP',
                          'T22PV1_J1',
                          '三加热段空烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T22PV1,
                          'T22PV1',
                          'G_TEMP',
                          'T22PV1_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T22PV1)+"℃ ")])]),_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.SJRMPTSP,
                          '修改',
                          'SJRMPTSP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.SJRMPTSP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T22PV2',
                          'G_TEMP',
                          'T22PV2_J1',
                          '三加热段煤烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T22PV2,
                          'T22PV2',
                          'G_TEMP',
                          'T22PV2_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T22PV2)+"℃ ")])]),_c('div',{staticClass:"text2 th-text2 flex align-cen"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)","position":"absolute","left":"-6vw"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_PFJ.FYSP_L,
                          '修改',
                          'FYSP_L',
                          'G_PFJ'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.FYSP_L)+"Pa ")]),_c('div',{staticClass:"th-child-dataR child-databtn",style:({
                        background:
                          _vm.infoList.G_MCSOTHER__p__RSF3 &&
                          !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3
                          ? _vm.toDetail(
                              3,
                              'RSF3',
                              'G_MCSOTHER__p__RSF3',
                              '',
                              '负压空废煤废温度补偿'
                            )
                          : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"th-text",on:{"click":function($event){return _vm.toCompon(10)}}},[_vm._v("空烟管道")]),_c('div',{staticClass:"my-text th-text",on:{"click":function($event){return _vm.toCompon(10)}}},[_vm._v(" 煤烟管道 ")])])])])]),_c('div',{staticClass:"thfamright flex"},[_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.G_TEMP.JRDKPTSP,
                      '修改',
                      'JRDKPTSP',
                      'G_TEMP'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.JRDKPTSP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'T12PV1',
                      'G_TEMP',
                      'T12PV1_J1',
                      '均热段空烟温度处理值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.G_TEMP.T12PV1,
                      'T12PV1',
                      'G_TEMP',
                      'T12PV1_J1'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T12PV1)+"℃ ")])]),_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.G_TEMP.JRDMPTSP,
                      '修改',
                      'JRDMPTSP',
                      'G_TEMP'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.JRDMPTSP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'T12PV2',
                      'G_TEMP',
                      'T12PV2_J1',
                      '均热段煤烟温度处理值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.G_TEMP.T12PV2,
                      'T12PV2',
                      'G_TEMP',
                      'T12PV2_J1'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T12PV2)+"℃ ")])])])]),_c('div',{staticClass:"coulm1-cen flex align-cen"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                background:
                  _vm.infoList.G_MCSXK__p__XK13 &&
                  _vm.infoList.G_MCSXK__p__XK13.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK13
                  ? _vm.toDetail(
                      1,
                      'XK13',
                      'G_MCSXK__p__XK13',
                      '',
                      '炉膛压力控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataR child-databtn",style:({
                background:
                  _vm.infoList.G_MCSOTHER__p__RSF1 &&
                  !_vm.infoList.G_MCSOTHER__p__RSF1.TS
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF1
                  ? _vm.toDetail(
                      3,
                      'RSF1',
                      'G_MCSOTHER__p__RSF1',
                      '',
                      '空烟煤协调软伺服'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                background:
                  _vm.infoList.G_MCSOTHER__p__QK1 &&
                  !_vm.infoList.G_MCSOTHER__p__QK1.TS
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK1
                  ? _vm.toDetail(
                      2,
                      'QK1',
                      'G_MCSOTHER__p__QK1',
                      '',
                      '空气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                background:
                  _vm.infoList.G_MCSOTHER__p__QK3 &&
                  !_vm.infoList.G_MCSOTHER__p__QK3.TS
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK3
                  ? _vm.toDetail(
                      2,
                      'QK3',
                      'G_MCSOTHER__p__QK3',
                      '',
                      '煤气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"coulm1-bot flex align-cen"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                background:
                  _vm.infoList.G_MCSMAN__p__MAN14 &&
                  _vm.infoList.G_MCSMAN__p__MAN14.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN14
                  ? _vm.toCompon(
                      0,
                      'AV',
                      'G_MCSMAN__p__MAN14',
                      'MAN14_AV_J1',
                      '煤烟气总管阀BCS手操器MAN14'
                    )
                  : ''}}},[_vm._v(" A ")]),_c('img',{staticClass:"jrlimg",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../assets/images/rts_jrlimg.png"),"alt":""},on:{"click":function($event){return _vm.toCompon(3)}}}),_c('div',{staticClass:"bot-wiidth flex"},[_c('div',{staticClass:"coulm1-bot-line"}),_c('img',{staticClass:"coulm1-bot-img1",attrs:{"src":require("../../assets/images/rts_jrlimg1.png"),"alt":""}}),_c('div',{staticClass:"coulm1-bot-line1"}),_c('div',{staticClass:"coulm1-bot-line2"}),_c('div',{staticClass:"pore",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'KF_114_B',
                    'G_SOUREC',
                    'KF_114_J1',
                    '煤烟气总管阀反馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.G_SOUREC.KF_114_B,
                    'KF_114_B',
                    'G_SOUREC',
                    'KF_114_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_114_B)+"% ")]),_c('div',{staticClass:"coulm1-bot-right flex"},[_vm._m(0),_c('div',{staticClass:"famright flex"},[_c('div',{staticClass:"famright-list flex"},[_c('div',{staticClass:"famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK15 &&
                              !_vm.infoList.G_MCSOTHER__p__QK15.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK15
                              ? _vm.toDetail(
                                  2,
                                  'QK15',
                                  'G_MCSOTHER__p__QK15',
                                  '',
                                  '一加热空烟空气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK12 &&
                              _vm.infoList.G_MCSXK__p__XK12.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK12
                              ? _vm.toDetail(
                                  1,
                                  'XK12',
                                  'G_MCSXK__p__XK12',
                                  '',
                                  '一加热段空烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN22 &&
                              _vm.infoList.G_MCSMAN__p__MAN22.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN22
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN22',
                                  'MAN22_AV_J1',
                                  '一加热段空烟气阀门手操器MAN22'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_122_B',
                              'G_SOUREC',
                              'KF_122_J1',
                              '一热段空烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_122_B,
                              'KF_122_B',
                              'G_SOUREC',
                              'KF_122_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_122_B)+"% ")])])]),_c('div',{staticClass:"famright-list-right famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK16 &&
                              !_vm.infoList.G_MCSOTHER__p__QK16.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK16
                              ? _vm.toDetail(
                                  2,
                                  'QK16',
                                  'G_MCSOTHER__p__QK16',
                                  '',
                                  '一加热煤烟煤气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK11 &&
                              _vm.infoList.G_MCSXK__p__XK11.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK11
                              ? _vm.toDetail(
                                  1,
                                  'XK11',
                                  'G_MCSXK__p__XK11',
                                  '',
                                  '一加热段煤烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN23 &&
                              _vm.infoList.G_MCSMAN__p__MAN23.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN23
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN23',
                                  'MAN23_AV_J1',
                                  '一加热段煤烟气阀门手操器MAN23'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_123_B',
                              'G_SOUREC',
                              'KF_123_J1',
                              '一热段煤烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_123_B,
                              'KF_123_B',
                              'G_SOUREC',
                              'KF_123_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_123_B)+"% ")])])])]),_c('div',{staticClass:"famright-list flex"},[_c('div',{staticClass:"famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK13 &&
                              !_vm.infoList.G_MCSOTHER__p__QK13.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK13
                              ? _vm.toDetail(
                                  2,
                                  'QK13',
                                  'G_MCSOTHER__p__QK13',
                                  '',
                                  '二加热空烟空气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK9 &&
                              _vm.infoList.G_MCSXK__p__XK9.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK9
                              ? _vm.toDetail(
                                  1,
                                  'XK9',
                                  'G_MCSXK__p__XK9',
                                  '',
                                  '二加热段空烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN11 &&
                              _vm.infoList.G_MCSMAN__p__MAN11.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN11
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN11',
                                  'MAN11_AV_J1',
                                  '二加热段空烟气阀门手操器MAN11'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_111_B',
                              'G_SOUREC',
                              'KF_111_J1',
                              '二热段空烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_111_B,
                              'KF_111_B',
                              'G_SOUREC',
                              'KF_111_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_111_B)+"% ")])])]),_c('div',{staticClass:"famright-list-right famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK14 &&
                              !_vm.infoList.G_MCSOTHER__p__QK14.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK14
                              ? _vm.toDetail(
                                  2,
                                  'QK14',
                                  'G_MCSOTHER__p__QK14',
                                  '',
                                  '二加热煤烟煤气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK8 &&
                              _vm.infoList.G_MCSXK__p__XK8.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK8
                              ? _vm.toDetail(
                                  1,
                                  'XK8',
                                  'G_MCSXK__p__XK8',
                                  '',
                                  '二加热段煤烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN12 &&
                              _vm.infoList.G_MCSMAN__p__MAN12.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN12
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN12',
                                  'MAN12_AV_J1',
                                  '二加热段煤烟气阀门手操器MAN12'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_112_B',
                              'G_SOUREC',
                              'KF_112_J1',
                              '二热段煤烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_112_B,
                              'KF_112_B',
                              'G_SOUREC',
                              'KF_112_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_112_B)+"% ")])])])]),_c('div',{staticClass:"famright-list flex"},[_c('div',{staticClass:"famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK11 &&
                              !_vm.infoList.G_MCSOTHER__p__QK11.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK11
                              ? _vm.toDetail(
                                  2,
                                  'QK11',
                                  'G_MCSOTHER__p__QK11',
                                  '',
                                  '三加热空烟空气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK6 &&
                              _vm.infoList.G_MCSXK__p__XK6.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK6
                              ? _vm.toDetail(
                                  1,
                                  'XK6',
                                  'G_MCSXK__p__XK6',
                                  '',
                                  '三加热段空烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN7 &&
                              _vm.infoList.G_MCSMAN__p__MAN7.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN7
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN7',
                                  'MAN7_AV_J1',
                                  '三加热段空烟气阀门手操器MAN7'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2 jflimgpo2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{staticClass:"jflimgpop",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_107_B',
                              'G_SOUREC',
                              'KF_107_J1',
                              '三热段空烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_107_B,
                              'KF_107_B',
                              'G_SOUREC',
                              'KF_107_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_107_B)+"% ")])])]),_c('div',{staticClass:"famright-list-right famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK12 &&
                              !_vm.infoList.G_MCSOTHER__p__QK12.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK12
                              ? _vm.toDetail(
                                  2,
                                  'QK12',
                                  'G_MCSOTHER__p__QK12',
                                  '',
                                  '三加热煤烟煤气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK5 &&
                              _vm.infoList.G_MCSXK__p__XK5.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK5
                              ? _vm.toDetail(
                                  1,
                                  'XK5',
                                  'G_MCSXK__p__XK5',
                                  '',
                                  '三加热段煤烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN8 &&
                              _vm.infoList.G_MCSMAN__p__MAN8.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN8
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN8',
                                  'MAN8_AV_J1',
                                  '三加热段煤烟气阀门手操器MAN8'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_108_B',
                              'G_SOUREC',
                              'KF_108_J1',
                              '三热段煤烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_108_B,
                              'KF_108_B',
                              'G_SOUREC',
                              'KF_108_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_108_B)+"% ")])])])]),_c('div',{staticClass:"thfamright1"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                          background:
                            _vm.infoList.G_MCSOTHER__p__QK9 &&
                            !_vm.infoList.G_MCSOTHER__p__QK9.TS
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK9
                            ? _vm.toDetail(
                                2,
                                'QK9',
                                'G_MCSOTHER__p__QK9',
                                '',
                                '均热段空烟空气阀门前馈'
                              )
                            : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                          background:
                            _vm.infoList.G_MCSXK__p__XK3 &&
                            _vm.infoList.G_MCSXK__p__XK3.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK3
                            ? _vm.toDetail(
                                1,
                                'XK3',
                                'G_MCSXK__p__XK3',
                                '',
                                '均热段空烟温度控制XK'
                              )
                            : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                          background:
                            _vm.infoList.G_MCSMAN__p__MAN3 &&
                            _vm.infoList.G_MCSMAN__p__MAN3.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN3
                            ? _vm.toCompon(
                                0,
                                'AV',
                                'G_MCSMAN__p__MAN3',
                                'MAN3_AV_J1',
                                '均热段空烟气阀门手操器MAN3'
                              )
                            : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_103_B',
                            'G_SOUREC',
                            'KF_103_J1',
                            '均热段空烟气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.KF_103_B,
                            'KF_103_B',
                            'G_SOUREC',
                            'KF_103_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_103_B)+"% ")])]),_c('div',{staticClass:"thfamright2"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK10 &&
                              !_vm.infoList.G_MCSOTHER__p__QK10.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK10
                              ? _vm.toDetail(
                                  2,
                                  'QK10',
                                  'G_MCSOTHER__p__QK10',
                                  '',
                                  '均热段煤烟煤气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK2 &&
                              _vm.infoList.G_MCSXK__p__XK2.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK2
                              ? _vm.toDetail(
                                  1,
                                  'XK2',
                                  'G_MCSXK__p__XK2',
                                  '',
                                  '均热段煤烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN4 &&
                              _vm.infoList.G_MCSMAN__p__MAN4.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN4
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN4',
                                  'MAN4_AV_J1',
                                  '均热段煤烟气阀门手操器MAN4'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_104_B',
                              'G_SOUREC',
                              'KF_104_J1',
                              '均热段煤烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_104_B,
                              'KF_104_B',
                              'G_SOUREC',
                              'KF_104_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_104_B)+"% ")])])])])])])])])]),_c('div',{staticClass:"el-container-box-cen-coulm2"},[_c('div',{staticClass:"coulm2-box flex align-cen"},[_c('div',{staticClass:"coulm2-box-left"},[_c('p',[_vm._v("生产模式切换")]),_c('div',{class:_vm.infoList.G_SOUREC.MENLU == 2 ? 'active' : '',on:{"click":function($event){return _vm.chaneStu(2, 'MENLU', 'G_SOUREC')}}},[_vm._v(" 闷炉 ")]),_c('div',{class:_vm.infoList.G_SOUREC.MENLU == 3 ? 'active' : '',on:{"click":function($event){return _vm.chaneStu(3, 'MENLU', 'G_SOUREC')}}},[_vm._v(" 长时间闷炉 ")]),_c('div',{class:_vm.infoList.G_SOUREC.MENLU == 4 ? 'active' : '',on:{"click":function($event){return _vm.chaneStu(4, 'MENLU', 'G_SOUREC')}}},[_vm._v(" 正常烧炉 ")])]),_c('div',{staticClass:"coulm2-box-right flex"},[_c('div',{staticClass:"right-list"},[_c('img',{staticClass:"jrlimg3",attrs:{"src":require("../../assets/images/jrlimg3.png"),"alt":""}}),_c('img',{staticClass:"jrlimg4",attrs:{"src":require("../../assets/images/jrlimg4.png"),"alt":""}}),_c('div',{staticClass:"coulm2-text1 flex"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_108A_B',
                        'G_SOUREC',
                        'TICA_108A_J1',
                        '一加热段空烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_108A_B,
                        'TICA_108A_B',
                        'G_SOUREC',
                        'TICA_108A_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_108A_B)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_108B_B',
                        'G_SOUREC',
                        'TICA_108B_J1',
                        '一加热段煤烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_108B_B,
                        'TICA_108B_B',
                        'G_SOUREC',
                        'TICA_108B_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_108B_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"coulm2-text2-row1"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_107A_B',
                          'G_SOUREC',
                          'TICA_107A_J1',
                          '一加热段左侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.TICA_107A_B,
                          'TICA_107A_B',
                          'G_SOUREC',
                          'TICA_107A_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_107A_B)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_107B_B',
                          'G_SOUREC',
                          'TICA_107B_J1',
                          '一加热段右侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.TICA_107B_B,
                          'TICA_107B_B',
                          'G_SOUREC',
                          'TICA_107B_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_107B_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2-row2",on:{"click":function($event){return _vm.toCompon(9, 1)}}},[_c('div',[_vm._v("一加热段")])]),_c('div',{staticClass:"coulm2-text2-row3"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.T41SP,
                          '一加热段设定值',
                          'T41SP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T41SP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'T41SPSJ', 'G_TEMP', 'T41SPSJ_J1')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T41SPSJ,
                          'T41SPSJ',
                          'G_TEMP',
                          'T41SPSJ_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T41SPSJ)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T41PV',
                          'G_TEMP',
                          'T41PV_J1',
                          '一加热段上部温度均值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T41PV,
                          'T41PV',
                          'G_TEMP',
                          'T41PV_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T41PV)+"℃ ")])])]),_c('div',{staticClass:"coulm2-text1 flex"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_108C_B',
                        'G_SOUREC',
                        'TICA_108C_J1',
                        '一加热段空烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_108C_B,
                        'TICA_108C_B',
                        'G_SOUREC',
                        'TICA_108C_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_108C_B)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_108D_B',
                        'G_SOUREC',
                        'TICA_108D_J1',
                        '一加热段煤烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_108D_B,
                        'TICA_108D_B',
                        'G_SOUREC',
                        'TICA_108D_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_108D_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YJRHXT_B',
                      'G_SOUREC',
                      'YJRHXT_J1',
                      '一加热换向时间'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.G_SOUREC.YJRHXT_B,
                      'YJRHXT_B',
                      'G_SOUREC',
                      'YJRHXT_J1'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.YJRHXT_B)+"S ")]),_c('div',{staticClass:"coulm2-line1"}),_c('div',{staticClass:"coulm2-line2"})]),_c('div',{staticClass:"right-list"},[_c('img',{staticClass:"jrlimg3",attrs:{"src":require("../../assets/images/jrlimg3.png"),"alt":""}}),_c('img',{staticClass:"jrlimg4",attrs:{"src":require("../../assets/images/jrlimg4.png"),"alt":""}}),_c('div',{staticClass:"coulm2-text1 flex"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_106A_B',
                        'G_SOUREC',
                        'TICA_106A_J1',
                        '二加热段空烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_106A_B,
                        'TICA_106A_B',
                        'G_SOUREC',
                        'TICA_106A_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_106A_B)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_106B_B',
                        'G_SOUREC',
                        'TICA_106B_J1',
                        '二加热段煤烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_106B_B,
                        'TICA_106B_B',
                        'G_SOUREC',
                        'TICA_106B_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_106B_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"coulm2-text2-row1"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_105A_B',
                          'G_SOUREC',
                          'TICA_105A_J1',
                          '二加热段左侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.TICA_105A_B,
                          'TICA_105A_B',
                          'G_SOUREC',
                          'TICA_105A_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_105A_B)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_105B_B',
                          'G_SOUREC',
                          'TICA_105B_J1',
                          '二加热段右侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.TICA_105B_B,
                          'TICA_105B_B',
                          'G_SOUREC',
                          'TICA_105B_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_105B_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2-row2",on:{"click":function($event){return _vm.toCompon(9, 2)}}},[_c('div',[_vm._v("二加热段")])]),_c('div',{staticClass:"coulm2-text2-row3"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.T31SP,
                          '二加热段设定值',
                          'T31SP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T31SP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'T31SPSJ', 'G_TEMP', 'T31SPSJ_J1')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T31SPSJ,
                          'T31SPSJ',
                          'G_TEMP',
                          'T31SPSJ_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T31SPSJ)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T31PV',
                          'G_TEMP',
                          'T31PV_J1',
                          '二加热段上部温度均值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T31PV,
                          'T31PV',
                          'G_TEMP',
                          'T31PV_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T31PV)+"℃ ")])]),_c('div',{staticClass:"coulm2-line1"}),_c('div',{staticClass:"coulm2-line2"})]),_c('div',{staticClass:"coulm2-text1 flex"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_106C_B',
                        'G_SOUREC',
                        'TICA_106C_J1',
                        '二加热段空烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_106C_B,
                        'TICA_106C_B',
                        'G_SOUREC',
                        'TICA_106C_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_106C_B)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_106D_B',
                        'G_SOUREC',
                        'TICA_106D_J1',
                        '二加热段煤烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_106D_B,
                        'TICA_106D_B',
                        'G_SOUREC',
                        'TICA_106D_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_106D_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'EJRHXT_B',
                      'G_SOUREC',
                      'EJRHXT_J1',
                      '二加热换向时间'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.G_SOUREC.EJRHXT_B,
                      'EJRHXT_B',
                      'G_SOUREC',
                      'EJRHXT_J1'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.EJRHXT_B)+"S ")])]),_c('div',{staticClass:"right-list"},[_c('img',{staticClass:"jrlimg3",attrs:{"src":require("../../assets/images/jrlimg3.png"),"alt":""}}),_c('img',{staticClass:"jrlimg4",attrs:{"src":require("../../assets/images/jrlimg4.png"),"alt":""}}),_c('div',{staticClass:"coulm2-text1 flex",staticStyle:{"padding-left":"0.7vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_104A_B',
                        'G_SOUREC',
                        'TICA_104A_J1',
                        '三加热段空烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_104A_B,
                        'TICA_104A_B',
                        'G_SOUREC',
                        'TICA_104A_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_104A_B)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_104B_B',
                        'G_SOUREC',
                        'TICA_104B_J1',
                        '三加热段煤烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_104B_B,
                        'TICA_104B_B',
                        'G_SOUREC',
                        'TICA_104B_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_104B_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"coulm2-text2-row1"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_103A_B',
                          'G_SOUREC',
                          'TICA_103A_J1',
                          '三加热段左侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.TICA_103A_B,
                          'TICA_103A_B',
                          'G_SOUREC',
                          'TICA_103A_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_103A_B)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_103B_B',
                          'G_SOUREC',
                          'TICA_103B_J1',
                          '三加热段右侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.TICA_103B_B,
                          'TICA_103B_B',
                          'G_SOUREC',
                          'TICA_103B_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_103B_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2-row2",on:{"click":function($event){return _vm.toCompon(9, 3)}}},[_c('div',[_vm._v("三加热段")])]),_c('div',{staticClass:"coulm2-text2-row3"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_TEMP.T21SP,
                          '三加热段设定值',
                          'T21SP',
                          'G_TEMP'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T21SP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T21SPSJ',
                          'G_TEMP',
                          'T21SPSJ_J1',
                          '实际温度控制点'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T21SPSJ,
                          'T21SPSJ',
                          'G_TEMP',
                          'T21SPSJ_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T21SPSJ)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T21PV',
                          'G_TEMP',
                          'T21PV_J1',
                          '三加热段温度均值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_TEMP.T21PV,
                          'T21PV',
                          'G_TEMP',
                          'T21PV_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T21PV)+"℃ ")])]),_c('div',{staticClass:"coulm2-line1"}),_c('div',{staticClass:"coulm2-line2"})]),_c('div',{staticClass:"coulm2-text1 flex",staticStyle:{"padding-left":"0.5vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_104C_B',
                        'G_SOUREC',
                        'TICA_104C_J1',
                        '三加热段空烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_104C_B,
                        'TICA_104C_B',
                        'G_SOUREC',
                        'TICA_104C_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_104C_B)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_104D_B',
                        'G_SOUREC',
                        'TICA_104D_J1',
                        '三加热段煤烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_104D_B,
                        'TICA_104D_B',
                        'G_SOUREC',
                        'TICA_104D_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_104D_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SJRHXT_B',
                      'G_SOUREC',
                      'SJRHXT_J1',
                      '三加热换向时间'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.G_SOUREC.SJRHXT_B,
                      'SJRHXT_B',
                      'G_SOUREC',
                      'SJRHXT_J1'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.SJRHXT_B)+"S ")])]),_c('div',{staticClass:"right-list"},[_c('img',{staticClass:"jrlimg3",attrs:{"src":require("../../assets/images/jrlimg3.png"),"alt":""}}),_c('img',{staticClass:"jrlimg4",attrs:{"src":require("../../assets/images/jrlimg4.png"),"alt":""}}),_c('div',{staticClass:"coulm2-text1 flex",staticStyle:{"padding-left":"0.7vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_102A_B',
                        'G_SOUREC',
                        'TICA_102A_J1',
                        '均热段空烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_102A_B,
                        'TICA_102A_B',
                        'G_SOUREC',
                        'TICA_102A_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_102A_B)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_102B_B',
                        'G_SOUREC',
                        'TICA_102B_J1',
                        '均热段煤烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_102B_B,
                        'TICA_102B_B',
                        'G_SOUREC',
                        'TICA_102B_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_102B_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2 flex align-cen"},[_c('div',{staticClass:"coulm2-text2-row1",staticStyle:{"padding":"1vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_101A_B',
                          'G_SOUREC',
                          'TICA_101A_J1',
                          '均热段左侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.TICA_101A_B,
                          'TICA_101A_B',
                          'G_SOUREC',
                          'TICA_101A_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_101A_B)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_101B_B',
                          'G_SOUREC',
                          'TICA_101B_J1',
                          '均热段右侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.TICA_101B_B,
                          'TICA_101B_B',
                          'G_SOUREC',
                          'TICA_101B_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_101B_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2-row2",staticStyle:{"padding-left":"1vw","padding-right":"0"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(9, 4)}}},[_vm._v("均热段")])]),_c('div',{staticClass:"coulm2-text2-row3 flex",staticStyle:{"position":"absolute","font-size":"0.8vw","right":"-0.7vw","top":"4vh"}},[_c('div',{staticClass:"flex-row",staticStyle:{"padding-right":"2vw"}},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                            _vm.infoList.G_TEMP.T11SP,
                            '均热段设定值',
                            'T11SP',
                            'G_TEMP'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T11SP)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'T11SPSJ',
                            'G_TEMP',
                            'T11SPSJ_J1',
                            '实际温度控制点'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_TEMP.T11SPSJ,
                            'T11SPSJ',
                            'G_TEMP',
                            'T11SPSJ_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T11SPSJ)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'T11PV',
                            'G_TEMP',
                            'T11PV_J1',
                            '均热段上部温度均值'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_TEMP.T11PV,
                            'T11PV',
                            'G_TEMP',
                            'T11PV_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T11PV)+"℃ ")])]),_c('div',{staticClass:"flex-row"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                            _vm.infoList.G_PFJ.FYSP,
                            '负压控制点',
                            'FYSP',
                            'G_PFJ'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.FYSP)+"Pa ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'PV',
                            'G_MCSXK__p__XK13',
                            'XK13_PV_J1',
                            '炉膛压力控制XK'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_MCSXK__p__XK13.PV,
                            'PV',
                            'G_MCSXK__p__XK13',
                            'XK13_PV_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK13.PV)+"Pa ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'PICA_101_B',
                            'G_SOUREC',
                            'PICA_101_J1',
                            '炉膛压力'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.PICA_101_B,
                            'PICA_101_B',
                            'G_SOUREC',
                            'PICA_101_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.PICA_101_B)+"℃ ")])])])]),_c('div',{staticClass:"coulm2-text1 flex",staticStyle:{"padding-left":"0.7vw"}},[_c('div',{staticStyle:{"padding":"0 0.5vw"},on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_102C_B',
                        'G_SOUREC',
                        'TICA_102C_J1',
                        '均热段空烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_102C_B,
                        'TICA_102C_B',
                        'G_SOUREC',
                        'TICA_102C_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_102C_B)+"℃ ")]),_c('div',{staticClass:"right-auto",staticStyle:{"padding":"0 0.5vw"},on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_102D_B',
                        'G_SOUREC',
                        'TICA_102D_J1',
                        '均热段煤烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.G_SOUREC.TICA_102D_B,
                        'TICA_102D_B',
                        'G_SOUREC',
                        'TICA_102D_J1'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.TICA_102D_B)+"℃ ")])]),_c('div',{staticClass:"coulm2-text3",staticStyle:{"left":"7.5vw"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JRDHXT_B',
                      'G_SOUREC',
                      'JRDHXT_J1',
                      '均热段换向时间'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.G_SOUREC.JRDHXT_B,
                      'JRDHXT_B',
                      'G_SOUREC',
                      'JRDHXT_J1'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.JRDHXT_B)+"S ")]),_c('div',{staticClass:"coulm2-line1"}),_c('div',{staticClass:"coulm2-line2"})])])])]),_c('div',{staticClass:"el-container-box-cen-coulm3"},[_c('div',{staticClass:"coulm3-box flex align-cen"},[_c('div',{staticClass:"coulm3-box-left"},[_c('div',{staticClass:"left-text",staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_PFJ.PKQ_SP1,
                    '空气总管压力设定值',
                    'PKQ_SP1',
                    'G_PFJ'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.PKQ_SP1)+" KPa ")]),_c('div',{staticClass:"left-text",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PIA_101_B',
                    'G_SOUREC',
                    'PIA_101_J1',
                    '均热段空气压力'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.G_SOUREC.PIA_101_B,
                    'PIA_101_B',
                    'G_SOUREC',
                    'PIA_101_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.PIA_101_B)+" KPa ")]),_c('div',{staticClass:"left1 flex align-cen"},[_vm._m(1),_c('img',{staticClass:"left1-jflimg1",attrs:{"src":require("../../assets/images/rts_jrlimg1.png"),"alt":""}}),_c('div',{staticClass:"line1"}),_c('div',{staticClass:"left-btn"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                      background:
                        _vm.infoList.G_MCSOTHER__p__QK6 &&
                        !_vm.infoList.G_MCSOTHER__p__QK6.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK6
                        ? _vm.toDetail(
                            2,
                            'QK6',
                            'G_MCSOTHER__p__QK6',
                            '',
                            '鼓风机空气阀门前馈'
                          )
                        : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                      background:
                        _vm.infoList.G_MCSXK__p__XK23 &&
                        _vm.infoList.G_MCSXK__p__XK23.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK23
                        ? _vm.toDetail(
                            1,
                            'XK23',
                            'G_MCSXK__p__XK23',
                            '',
                            '空气总管压力XK'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                      background:
                        _vm.infoList.G_MCSMAN__p__MAN16 &&
                        _vm.infoList.G_MCSMAN__p__MAN16.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN16
                        ? _vm.toCompon(
                            0,
                            'AV',
                            'G_MCSMAN__p__MAN16',
                            'MAN16_AV_J1',
                            '均热鼓风机手操器MAN16'
                          )
                        : ''}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"left-text left-text1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'KF_116_B',
                    'G_SOUREC',
                    'KF_116_J1',
                    '鼓风机(均热段)反馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.G_SOUREC.KF_116_B,
                    'KF_116_B',
                    'G_SOUREC',
                    'KF_116_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_116_B)+"% ")]),_c('div',{staticClass:"left1 left2 flex align-cen"},[_vm._m(2),_c('img',{staticClass:"left1-jflimg1",attrs:{"src":require("../../assets/images/rts_jrlimg1.png"),"alt":""}}),_c('div',{staticClass:"line1"}),_c('div',{staticClass:"left-btn"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                      background:
                        _vm.infoList.G_MCSXK__p__XK24 &&
                        _vm.infoList.G_MCSXK__p__XK24.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK24
                        ? _vm.toDetail(
                            1,
                            'XK24',
                            'G_MCSXK__p__XK24',
                            '',
                            '空气总管压力XK'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                      background:
                        _vm.infoList.G_MCSMAN__p__MAN17 &&
                        _vm.infoList.G_MCSMAN__p__MAN17.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN17
                        ? _vm.toCompon(
                            0,
                            'AV',
                            'G_MCSMAN__p__MAN17',
                            'MAN17_AV_J1',
                            '三热段鼓风机手操器MAN17'
                          )
                        : ''}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"left-text left-text1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'KF_117_B',
                    'G_SOUREC',
                    'KF_117_J1',
                    '鼓风机(三加热段)反馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.G_SOUREC.KF_117_B,
                    'KF_117_B',
                    'G_SOUREC',
                    'KF_117_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_117_B)+"% ")])]),_vm._m(3),_c('div',{staticClass:"coulm3-list"},[_c('div',{staticClass:"coulm3-list-top flex"},[_c('div',{staticClass:"list-o"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_120_B',
                          'G_SOUREC',
                          'KF_120_J1',
                          '一热段空气阀反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.KF_120_B,
                          'KF_120_B',
                          'G_SOUREC',
                          'KF_120_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_120_B)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'FICA_107_B',
                          'G_SOUREC',
                          'FICA_107_J1',
                          '一加热段空气流量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.FICA_107_B,
                          'FICA_107_B',
                          'G_SOUREC',
                          'FICA_107_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_107_B)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                          background:
                            _vm.infoList.G_MCSXK__p__XK22 &&
                            _vm.infoList.G_MCSXK__p__XK22.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK22
                            ? _vm.toDetail(
                                1,
                                'XK22',
                                'G_MCSXK__p__XK22',
                                '',
                                '一加热空气流量XK'
                              )
                            : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                          background:
                            _vm.infoList.G_MCSMAN__p__MAN20 &&
                            _vm.infoList.G_MCSMAN__p__MAN20.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN20
                            ? _vm.toCompon(
                                0,
                                'AV',
                                'G_MCSMAN__p__MAN20',
                                'MAN20_AV_J1',
                                '一热段空气阀门手操器MAN20'
                              )
                            : ''}}},[_vm._v(" A ")])])])]),_c('div',{staticClass:"list flex"},[_vm._m(4),_c('div',{staticClass:"list-row1 list-row2"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_109_B',
                              'G_SOUREC',
                              'KF_109_J1',
                              '二热段空气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_109_B,
                              'KF_109_B',
                              'G_SOUREC',
                              'KF_109_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_109_B)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'FICA_105_B',
                              'G_SOUREC',
                              'FICA_105_J1',
                              '二加热段空气流量'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.FICA_105_B,
                              'FICA_105_B',
                              'G_SOUREC',
                              'FICA_105_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_105_B)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                              background:
                                _vm.infoList.G_MCSXK__p__XK20 &&
                                _vm.infoList.G_MCSXK__p__XK20.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK20
                                ? _vm.toDetail(
                                    1,
                                    'XK20',
                                    'G_MCSXK__p__XK20',
                                    '',
                                    '二加热空气流量XK'
                                  )
                                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                              background:
                                _vm.infoList.G_MCSMAN__p__MAN9 &&
                                _vm.infoList.G_MCSMAN__p__MAN9.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN9
                                ? _vm.toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN9',
                                    'MAN9_AV_J1',
                                    '二热段空气阀门手操器MAN9'
                                  )
                                : ''}}},[_vm._v(" A ")])])])])]),_c('div',{staticClass:"list-row1 list-row2 list-row3"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_105_B',
                              'G_SOUREC',
                              'KF_105_J1',
                              '三热段空气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_105_B,
                              'KF_105_B',
                              'G_SOUREC',
                              'KF_105_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_105_B)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'FICA_103_B',
                              'G_SOUREC',
                              'FICA_103_J1',
                              '三加热段空气流量'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.FICA_103_B,
                              'FICA_103_B',
                              'G_SOUREC',
                              'FICA_103_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_103_B)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                              background:
                                _vm.infoList.G_MCSXK__p__XK18 &&
                                _vm.infoList.G_MCSXK__p__XK18.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK18
                                ? _vm.toDetail(
                                    1,
                                    'XK18',
                                    'G_MCSXK__p__XK18',
                                    '',
                                    '三加热空气流量XK'
                                  )
                                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                              background:
                                _vm.infoList.G_MCSMAN__p__MAN5 &&
                                _vm.infoList.G_MCSMAN__p__MAN5.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN5
                                ? _vm.toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN5',
                                    'MAN5_AV_J1',
                                    '三热段空气阀门手操器MAN5'
                                  )
                                : ''}}},[_vm._v(" A ")])])])]),_c('img',{staticClass:"list-row2-jflimg3",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o2"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_101_B',
                              'G_SOUREC',
                              'KF_101_J1',
                              '均热段空气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.KF_101_B,
                              'KF_101_B',
                              'G_SOUREC',
                              'KF_101_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_101_B)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'FICA_101_B',
                              'G_SOUREC',
                              'FICA_101_J1',
                              '均热段空气流量'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.G_SOUREC.FICA_101_B,
                              'FICA_101_B',
                              'G_SOUREC',
                              'FICA_101_J1'
                            )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_101_B)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                              background:
                                _vm.infoList.G_MCSXK__p__XK16 &&
                                _vm.infoList.G_MCSXK__p__XK16.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK16
                                ? _vm.toDetail(
                                    1,
                                    'XK16',
                                    'G_MCSXK__p__XK16',
                                    '',
                                    '均热空气流量XK'
                                  )
                                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                              background:
                                _vm.infoList.G_MCSMAN__p__MAN1 &&
                                _vm.infoList.G_MCSMAN__p__MAN1.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN1
                                ? _vm.toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN1',
                                    'MAN1_AV_J1',
                                    '均热段空气阀门手操器MAN1'
                                  )
                                : ''}}},[_vm._v(" A ")])])])])])])]),_c('div',{staticClass:"coulm3-list-bot flex"},[_c('div',{staticClass:"coulm3-list-line"}),_c('div',{staticClass:"coulm3-list-bot-list flex"},[_c('div',{staticClass:"bot-text"},[_c('div',{staticClass:"kq",on:{"click":function($event){return _vm.toCompon(7)}}},[_vm._v("空气总管")]),_c('div',{staticClass:"mq",on:{"click":function($event){return _vm.toCompon(8)}}},[_vm._v("煤气总管")])]),_c('div',{staticClass:"maright ma-auto"},[_c('img',{staticClass:"ma-auto-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"mapos",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_124_B',
                          'G_SOUREC',
                          'KF_124_J1',
                          '煤气总管压力调节阀反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.KF_124_B,
                          'KF_124_B',
                          'G_SOUREC',
                          'KF_124_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_124_B)+"% ")]),_c('div',{staticClass:"mapos1",staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.G_PFJ.PMQ_SP,
                          '修改',
                          'PMQ_SP',
                          'G_PFJ'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.PMQ_SP)+"Kpa ")]),_c('div',{staticClass:"mapos2",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'PIA_105_B',
                          'G_SOUREC',
                          'PIA_105_J1',
                          '煤气总管压力'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.G_SOUREC.PIA_105_B,
                          'PIA_105_B',
                          'G_SOUREC',
                          'PIA_105_J1'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.PIA_105_B)+"Kpa ")]),_c('div',{staticClass:"ma-btn flex justify-content-cen"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                          background:
                            _vm.infoList.G_MCSOTHER__p__QK7 &&
                            !_vm.infoList.G_MCSOTHER__p__QK7.TS
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK7
                            ? _vm.toDetail(
                                2,
                                'QK7',
                                'G_MCSOTHER__p__QK7',
                                '',
                                '煤气总管煤气阀门前馈'
                              )
                            : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                          background:
                            _vm.infoList.G_MCSXK__p__XK14 &&
                            _vm.infoList.G_MCSXK__p__XK14.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK14
                            ? _vm.toDetail(
                                1,
                                'XK14',
                                'G_MCSXK__p__XK14',
                                '',
                                '煤气总管压力控制XK'
                              )
                            : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                          background:
                            _vm.infoList.G_MCSMAN__p__MAN24 &&
                            _vm.infoList.G_MCSMAN__p__MAN24.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN24
                            ? _vm.toCompon(
                                0,
                                'AV',
                                'G_MCSMAN__p__MAN24',
                                'MAN24_AV_J1',
                                '煤气总管压力手操器MAN24'
                              )
                            : ''}}},[_vm._v(" A ")])])]),_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_121_B',
                            'G_SOUREC',
                            'KF_121_J1',
                            '一热段煤气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.KF_121_B,
                            'KF_121_B',
                            'G_SOUREC',
                            'KF_121_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_121_B)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'FICA_108_B',
                            'G_SOUREC',
                            'FICA_108_J1',
                            '一加热段煤气流量'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.FICA_108_B,
                            'FICA_108_B',
                            'G_SOUREC',
                            'FICA_108_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_108_B)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK17 &&
                              !_vm.infoList.G_MCSOTHER__p__QK17.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK17
                              ? _vm.toDetail(
                                  2,
                                  'QK17',
                                  'G_MCSOTHER__p__QK17',
                                  '',
                                  '一加热入炉钢温前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK10 &&
                              _vm.infoList.G_MCSXK__p__XK10.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK10
                              ? _vm.toDetail(
                                  1,
                                  'XK10',
                                  'G_MCSXK__p__XK10',
                                  '',
                                  '一加热段炉膛温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK21 &&
                              _vm.infoList.G_MCSXK__p__XK21.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK21
                              ? _vm.toDetail(
                                  1,
                                  'XK21',
                                  'G_MCSXK__p__XK21',
                                  '',
                                  '一加热煤气流量XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN21 &&
                              _vm.infoList.G_MCSMAN__p__MAN21.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN21
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN21',
                                  'MAN21_AV_J1',
                                  '一加热段煤气阀门手操器MAN21'
                                )
                              : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK21 &&
                              !_vm.infoList.G_MCSOTHER__p__QK21.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK21
                              ? _vm.toDetail(
                                  2,
                                  'QK21',
                                  'G_MCSOTHER__p__QK21',
                                  '',
                                  '一加热段煤气压力前馈'
                                )
                              : ''}}},[_vm._v(" K ")])])])])]),_c('div',{staticClass:"coulm3-list-bot-list coulm3-list-bot-list1 flex"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_110_B',
                            'G_SOUREC',
                            'KF_110_J1',
                            '二热段煤气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.KF_110_B,
                            'KF_110_B',
                            'G_SOUREC',
                            'KF_110_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_110_B)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'FICA_106_B',
                            'G_SOUREC',
                            'FICA_106_J1',
                            '二加热段煤气流量'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.FICA_106_B,
                            'FICA_106_B',
                            'G_SOUREC',
                            'FICA_106_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_106_B)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK18 &&
                              !_vm.infoList.G_MCSOTHER__p__QK18.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK18
                              ? _vm.toDetail(
                                  2,
                                  'QK18',
                                  'G_MCSOTHER__p__QK18',
                                  '',
                                  '二加热入炉钢温前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK7 &&
                              _vm.infoList.G_MCSXK__p__XK7.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK7
                              ? _vm.toDetail(
                                  1,
                                  'XK7',
                                  'G_MCSXK__p__XK7',
                                  '',
                                  '二加热段炉膛温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK19 &&
                              _vm.infoList.G_MCSXK__p__XK19.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK19
                              ? _vm.toDetail(
                                  1,
                                  'XK19',
                                  'G_MCSXK__p__XK19',
                                  '',
                                  '二加热煤气流量XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN10 &&
                              _vm.infoList.G_MCSMAN__p__MAN10.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN10
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN10',
                                  'MAN10_AV_J1',
                                  '二加热段煤气阀门手操器MAN10'
                                )
                              : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK22 &&
                              !_vm.infoList.G_MCSOTHER__p__QK22.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK22
                              ? _vm.toDetail(
                                  2,
                                  'QK22',
                                  'G_MCSOTHER__p__QK22',
                                  '',
                                  '二加热段煤气压力前馈'
                                )
                              : ''}}},[_vm._v(" K ")])])])])]),_c('div',{staticClass:"coulm3-list-bot-list coulm3-list-bot-list1 flex"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_106_B',
                            'G_SOUREC',
                            'KF_106_J1',
                            '三热段煤气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.KF_106_B,
                            'KF_106_B',
                            'G_SOUREC',
                            'KF_106_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_106_B)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'FICA_104_B',
                            'G_SOUREC',
                            'FICA_104_J1',
                            '三加热段煤气流量'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.FICA_104_B,
                            'FICA_104_B',
                            'G_SOUREC',
                            'FICA_104_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_104_B)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK19 &&
                              !_vm.infoList.G_MCSOTHER__p__QK19.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK19
                              ? _vm.toDetail(
                                  2,
                                  'QK19',
                                  'G_MCSOTHER__p__QK19',
                                  '',
                                  '三加热入炉钢温前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK4 &&
                              _vm.infoList.G_MCSXK__p__XK4.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK4
                              ? _vm.toDetail(
                                  1,
                                  'XK4',
                                  'G_MCSXK__p__XK4',
                                  '',
                                  '三加热段炉膛温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK17 &&
                              _vm.infoList.G_MCSXK__p__XK17.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK17
                              ? _vm.toDetail(
                                  1,
                                  'XK17',
                                  'G_MCSXK__p__XK17',
                                  '',
                                  '三加热煤气流量XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN6 &&
                              _vm.infoList.G_MCSMAN__p__MAN6.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN6
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN6',
                                  'MAN6_AV_J1',
                                  '三加热段煤气阀门手操器MAN6'
                                )
                              : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK23 &&
                              !_vm.infoList.G_MCSOTHER__p__QK23.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK23
                              ? _vm.toDetail(
                                  2,
                                  'QK23',
                                  'G_MCSOTHER__p__QK23',
                                  '',
                                  '三加热段煤气压力前馈'
                                )
                              : ''}}},[_vm._v(" K ")])])])])]),_c('div',{staticClass:"coulm3-list-bot-list coulm3-list-bot-list1 flex"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_102_B',
                            'G_SOUREC',
                            'KF_102_J1',
                            '均热段煤气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.KF_102_B,
                            'KF_102_B',
                            'G_SOUREC',
                            'KF_102_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.KF_102_B)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'FICA_102_B',
                            'G_SOUREC',
                            'FICA_102_J1',
                            '均热段煤气流量'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.G_SOUREC.FICA_102_B,
                            'FICA_102_B',
                            'G_SOUREC',
                            'FICA_102_J1'
                          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_SOUREC.FICA_102_B)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK20 &&
                              !_vm.infoList.G_MCSOTHER__p__QK20.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK20
                              ? _vm.toDetail(
                                  2,
                                  'QK20',
                                  'G_MCSOTHER__p__QK20',
                                  '',
                                  '均热入炉钢温前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK1 &&
                              _vm.infoList.G_MCSXK__p__XK1.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK1
                              ? _vm.toDetail(
                                  1,
                                  'XK1',
                                  'G_MCSXK__p__XK1',
                                  '',
                                  '均热段炉膛温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSXK__p__XK15 &&
                              _vm.infoList.G_MCSXK__p__XK15.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK15
                              ? _vm.toDetail(
                                  1,
                                  'XK15',
                                  'G_MCSXK__p__XK15',
                                  '',
                                  '均热煤气流量XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSMAN__p__MAN2 &&
                              _vm.infoList.G_MCSMAN__p__MAN2.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN2
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN2',
                                  'MAN2_AV_J1',
                                  '均热段煤气阀门手操器MAN2'
                                )
                              : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.G_MCSOTHER__p__QK24 &&
                              !_vm.infoList.G_MCSOTHER__p__QK24.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK24
                              ? _vm.toDetail(
                                  2,
                                  'QK24',
                                  'G_MCSOTHER__p__QK24',
                                  '',
                                  '均热段煤气压力前馈'
                                )
                              : ''}}},[_vm._v(" K ")])])])])])]),_c('div',{staticClass:"coulm3-line"})])])])])])]),(_vm.alllModel)?_c('JrallModel',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam01)?_c('JRLParam01',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam02)?_c('JRLParam02',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam03)?_c('JRLParam03',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam04)?_c('JRLParam04',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam05)?_c('JRLParam05',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 4 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('JRLcysz',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JrparmsGroup)?_c('JrparmsGroup',{attrs:{"infoList":_vm.infoList,"datatype":_vm.historyname,"canshu1":_vm.canshu1},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Jrwtdata)?_c('Jrwtdata',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fam1"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line"},[_c('img',{staticClass:"line-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line"},[_c('img',{staticClass:"line-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line-box"},[_c('div',{staticClass:"line1"}),_c('div',{staticClass:"line1 line2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-row1"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}})])}]

export { render, staticRenderFns }