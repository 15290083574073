<template>
  <div class="Jrserve drag" v-draw>
    <div class="evaluate-header">
        加热炉_客服系统
      <div class="evaluate-header-icon" @click="closeCompon">
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="Jrconsttit">
       和隆优化客户服务系统
    </div>
    <div class="servebox">
      <div class="servebox-top">
        <div class="servebox-top-tit">和隆信息</div>
        <div class="servebox-top-data flex align-cen">
          <div class="servebox-top-data-left">
            <div class="left-tit">项目进度信息</div>
            <div>项目签订时间： <span>2021/6/21</span>  </div>
            <div>项目进厂时间： <span>2021/7/21</span>  </div>
            <div>项目签收时间： <span>2021/8/21</span>  </div>
          </div>
          <div class="servebox-top-data-right">
            <div class="btn">一键协助</div>
             <div>客户服务热线： <span>400-8811-519</span>  </div>
            <div>项目签收时间： <span>某某  18001170000</span>  </div>
          </div>
        </div>
      </div>
       <div class="servebox-bot servebox-top">
        <div class="servebox-top-tit">项目相关负责人信息</div>
        <div class="servebox-top-data flex align-cen" style="border:none">
          <div class="servebox-top-data-left">
            <div class="left-tit">加热炉信息</div>
            <div class="flex">
              <div class="text1">设备制造厂商：</div>
              <div class="text2">上海嘉德</div>
            </div>
             <div class="flex">
              <div class="text1">设备结构： </div>
              <div class="text2">三个加热段+均热段</div>
            </div>
             <div class="flex">
              <div class="text1">出钢方式： </div>
              <div class="text2">步进式/推钢式</div>
            </div>
            <div class="flex">
              <div class="text1">换热方式： </div>
              <div class="text2">双蓄热线/常规换热器</div>
            </div>
            <div class="flex">
              <div class="text1">设计加热能力： </div>
              <div class="text2">年120万吨</div>
            </div>
            <div class="flex">
              <div class="text1">使用燃料种类： </div>
              <div class="text2">高炉煤气+转炉煤气</div>
            </div>
             <div class="flex">
              <div class="text1">DCS系统信息： </div>
              <div class="text2">西门子400+WINCC</div>
            </div>
             <div class="flex">
              <div class="text1">换向时间： </div>
              <div class="text2">55s</div>
            </div>
             <div class="flex">
              <div class="text1">排烟方式： </div>
              <div class="text2">两个强排+自然排</div>
            </div>
          </div>
          <div class="servebox-top-data-right">
                        <div class="left-tit">项目相关负责人信息</div>
            <div class="flex align-cen">
              <div class="text1">项目总负责人：</div>
              <div class="text2">张三，18001179000；总公司环保处处长负责项目协调和关键时间节点把控；</div>
            </div>
             <div class="flex align-cen">
              <div class="text1">工艺总负责人：</div>
              <div class="text2">李四，18001179000；炼铁厂电仪科科长负责项目工艺流程和调试把控；</div>
            </div>
            <div class="flex align-cen">
              <div class="text1">自动化负责人：</div>
              <div class="text2">王五，18001179000；炼铁厂自动化处长负责项目DCS程序修改，通讯对接等；</div>
            </div>
            <div>1#控制室电话：010-82926605</div>
            <div class="phone">
              <div class="flex">
                <div class="p-l1 flex">
                  <div class="text1">大班长：</div>
              <div class="text2">18001179000</div></div>
                <div class="p-l1 flex">
                  <div class="text1">乙班：</div>
              <div class="text2">18001179000</div></div>
              </div>
              <div class="flex">
                <div class="p-l1 flex">
                  <div class="text1"> 甲班：</div>
              <div class="text2">18001179000</div></div>
                <div class="p-l1 flex">
                  <div class="text1">丙班：</div>
              <div class="text2">18001179000</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
      <Historical v-if="isHshow" @sendStatus = 'isClose1' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
  </div>
</template>

<script>
import inputVal from "@/components/inputVal.vue"   //输入框组件
import Historical from "@/components/Historical.vue"  //历史趋势
export default {
       name: "Jrserve",
        components: {inputVal,Historical},
    props:{
        infoList:{
            type:Object,
            default: {} // 默认值
        }
    },

    data: () => {
		return {
       historyname:'',
      node:'',
      Lkname:'',
      isIndex:'',
        isComShow:false,
      isHshow:false,
      isClose:false,
		}
        
	},
      watch: {
    infoList: {
      handler (n, o) {
       this.infoList = n
      },
      deep: true // 深度监听父组件传过来对象变化
    },
   },
       methods:{
          isClose1(){
          this.isHshow = false
        },
                 toCompon(key,name,name2,name3){
      this.isIndex = key
      this.historyname = name
      this.node = name2
      this.Lkname = name3
      switch(key){
        case 0:
          return this.isMshow = true
        case 1 :
          return  this.isFshow = true
        case 2:
          return  this.isHshow = true
      }
    },
        //    打开输入框组件。
        toIpt(data,name,historyname,node){
            this.$refs.inputVal.open(data,name,historyname,node)
        },
        closeCompon(){
           this.$emit('sendStatus', 'Jrserve',false)
        },
    } 
}
</script>
<style lang="scss" scoped>
$base-rebrder-color:rgba(0,22,36,.2);
 .Jrserve{
     width: 694px;
     height: 584px;
     background: #DFEDF5;
     font-family: PingFang-SC-Regular;
     cursor:move;
     border-radius: 5px;
      .evaluate-header{
         height: 50px;
         background: #5DCEF3;
         line-height: 50px;
         text-align: center;
         color: #041A28;
         font-size: 16px;
         position: relative;
         border-radius: 5px 5px 0 0;
         .evaluate-header-icon{
         width: 32px;
         height: 32px;
         position: absolute;
         right: 10px;
         top: 8px;
         border-radius: 32px;
          border: 1px solid #041A28;
      color: #041A28;
         line-height: 32px;
        cursor: pointer;
            font-size: 24px
     }
     }
     .Jrconsttit{
         height: 50px;
         width: 100%;
         margin: 0 auto;
         color: #0a4c62;
         letter-spacing: 6px;
         font-family: 'PingFang-SC-Regular';
         font-size: 28px;
         text-align: center;
         position: relative;
         margin-top: 20px;
         .tit-left{
             font-size: 16px;
             position: absolute;
             left: 0;
             bottom:2px;
             letter-spacing:0px;
             letter-spacing: 0;
         }
         .tit-btn{
             position: absolute;
             right: 0;
             bottom:2px;
            .btn1{
             width: 100px;
            height: 28px;
            background-color: #61b9d6;
            color: #fff;
            font-size: 16px;
             letter-spacing: 0;
         }
         .btn2,.btn3{
             width: 100px;
            height: 28px;
            background-color: #abd5e3;
            color: #0a4c62;
            font-size: 16px;
             letter-spacing: 0;
             margin-left: 5px;
         }
         }
     }
    .servebox{
      width: 662px;
	height: 443px;
  margin: 0 auto;
  border: 1px solid $base-rebrder-color;
  .servebox-top-tit{
    color: #0a4c62;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    border-bottom: 1px solid $base-rebrder-color;
  }
  .servebox-top-data {
     border-bottom: 1px solid $base-rebrder-color;
  .servebox-top-data-left{
    text-align: center;
    border-right: 1px solid $base-rebrder-color;
    box-sizing: border-box;
    width: 50%;
    padding: 10px 0;
    .left-tit{
      color: #0a4c62;
      font-size: 16px;
    }
    div{
      color: #1f7794;
      font-size: 14px;
      line-height: 23px;
      span{
        color: #1662ec;
        padding-left: 30px;
      }
    }
  }
      .flex{
      align-items: center;
      padding: 0 45px;
      .text1{
        width: 100px;
        text-align: right;
      }
      .text2{
        color: #1662ec;
        
      }
    }
  .servebox-top-data-right{
    text-align: center;
    width: 50%;
      .left-tit{
      color: #0a4c62;
      font-size: 16px;
    }
    .flex{
      padding: 0 5px !important;
      align-items: start;
      .p-l1{
        width: 50%;
        margin-left: -20px;
      }
      .text1{
        width: 88px;
         font-size: 12px;
      }
      .text2{
        width: calc(100% - 88px);
        font-size: 12px;
      }
    }
     div{
      color: #1f7794;
      font-size: 14px;
      line-height: 23px;
      span{
        color: #1662ec;
        padding-left: 30px;
        
      }
    }
  }
  }
    }
 }
</style>