<template>
	<div class="jrl05 drag" id="jrl05" v-draw>
		<!-- 标题部分 -->
		<div>
			<div class="title-hang" fl>{{ projectData.project }}_煤气总管压力模型</div>
			<div class="param11-header-icon" fr @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="titlelable">炉膛压力协调控制模型</div>
		<div class="main-top">
			<div class="row1">
				<div class="fl">引风能力上限炉压</div>
				<div class="fl wdjg colorl1" @click="
                  toIpt(
                    infoList.G_PFJ.MENLUFW1,
                    '引风能力上限炉压',
                     'MENLUFW1',
                    'G_PFJ',
                   
                  )
                ">
					{{ infoList.G_PFJ.MENLUFW1 }}
				</div>
			</div>
			<div class="row2">
				<div class="row-col fl">
					<div class="fl">A03-PV</div>
					<div class="fl wdjg colorl" @click="toCompon(2, 'PV', 'G_MCSOTHER__p__RSF1', 'RSF1_PV_J1','空烟煤烟协调软伺服')"
						@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF1.PV,'PV', 'G_MCSOTHER__p__RSF1', 'RSF1_PV_J1')">
						{{infoList.G_MCSOTHER__p__RSF1.PV}}</div>
				</div>
				<div class="row-col fl row-col-jg">
					<div class="fl">A03-AV</div>
					<div class="fl wdjg color1" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF1', 'RSF1_AV_J1','空烟煤烟协调软伺服')"
						@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF1.AV,'AV', 'G_MCSOTHER__p__RSF1', 'RSF1_AV_J1')">
						{{infoList.G_MCSOTHER__p__RSF1.AV}}</div>
				</div>
			</div>
			<div class="row4 flex">
				<div class="bottonx fl" :style="{
            background: !infoList.G_MCSOTHER__p__RSF1.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.G_MCSOTHER__p__RSF1
              ? toDetail(
                  3,
                  'RSF1',
                  'G_MCSOTHER__p__RSF1',
                  '',
                  '空烟煤烟协调软伺服'
                )
              : ''
          ">
					R
				</div>
				<!-- <div class="row4-div">
      投用
    </div> -->
				<div class="row-col fl row4-jg">
					<div class="fl">A0-KYFPZ</div>
					<div class="fl row4-col1-jg5 colorl sz" @click="toCompon(2, 'KYFPZ1', 'G_PFJ', 'KYFPZ_J1','空烟引风机阀位偏置')"
						@dblclick="Cclick(infoList.G_PFJ.KYFPZ1,'KYFPZ1', 'G_PFJ', 'KYFPZ_J1')">
						{{infoList.G_PFJ.KYFPZ1}}</div>
				</div>
			</div>
			<div>
				<div class="row3 fl">
					<div class="row4-div" :style="{
              background:
                infoList.G_MCSOTHER &&
                !infoList.G_MCSOTHER.RSF1SEL               
                  ?  'red':'#2AFC30'
                 
            }" @click="
                  toIpt(
                    infoList.G_MCSOTHER.RSF1SEL,
                    '状态',
                     'RSF1SEL',
                    'G_MCSOTHER',
                   
                  )
                ">{{infoList.G_MCSOTHER.RSF1SEL?'投用':'切除'}}</div>
					<div class="row3-col1 fl">
						<div>
							<div class="fl">
								<div>
									<div class="fl row3-col1-wid">A01-SP</div>
									<div class="fl row3-col1-wid colorl1" @click="
                  toIpt(
                    infoList.G_PFJ.FYSP,
                    'A01-SP',                 
                    'FYSP',
                     'G_PFJ',
                   
                  )
                ">
										{{ infoList.G_PFJ.FYSP }}
									</div>
								</div>
								<div>
									<div class="fl row3-col1-wid">A01-PV</div>
									<div class="fl row3-col1-wid sz"
										@click="toCompon(2, 'PV', 'G_MCSXK__p__XK13', 'XK13_PV_J1','炉膛压力控制XK')"
										@dblclick="Cclick(infoList.G_MCSXK__p__XK13.PV,'PV', 'G_MCSXK__p__XK13', 'XK13_PV_J1')">
										{{infoList.G_MCSXK__p__XK13.PV}}</div>
								</div>
								<div class="row-col fl">
									<div class="fl">FYTC</div>
									<div class="fl wdjg colorl1" @click="
                  toIpt(
                    infoList.G_PFJ.FYTC,
                    'FYTC',
                     'FYTC',
                    'G_PFJ',
                   
                  )
                ">
										{{ infoList.G_PFJ.FYTC }}
									</div>
								</div>
							</div>
							<div class="fl">
								<div>
									<div class="bottonx fl row4-col1-jg2" :style="{
                  background:
                    infoList.G_MCSXK__p__XK13 && !infoList.G_MCSXK__p__XK13.XK13
                      ? '#2AFC30'
                      : 'red',
                }" @click="
                  infoList.G_MCSXK__p__XK13
                    ? toDetail(1, 'XK13', 'G_MCSXK__p__XK13', '', '炉膛压力控制XK')
                    : ''
                ">
										X
									</div>
								</div>
								<div class="row4-col1-jg11 row-col1 ">
									<div class="fl  ">A01-AV</div>
									<div class="fl row4-col1-jg4 "
										@click="toCompon(2, 'AV', 'G_MCSXK__p__XK13', 'XK13_AV_J1','炉膛压力控制XK')"
										@dblclick="Cclick(infoList.G_MCSXK__p__XK13.AV,'AV', 'G_MCSXK__p__XK13', 'XK13_AV_J1')">
										{{infoList.G_MCSXK__p__XK13.AV}}</div>
								</div>
							</div>
						</div>
						<div class="row4-col1-jg3">
							<div>
								<div class="fl row3-col1-wid1">A04-KQSUM</div>
								<div class="fl">
									<div class="bottonx  mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK1 &&
                !infoList.G_MCSOTHER__p__QK1.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK1 ? toDetail(2,'QK1','G_MCSOTHER__p__QK1','','空气阀门前馈' ): ''">K
									</div>
								</div>
							</div>
							<div>
								<div class="fl row3-col1-wid1">A04-MQSUM</div>
								<div class="fl">
									<div class="bottonx  mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK3 &&
                !infoList.G_MCSOTHER__p__QK3.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK3 ? toDetail(2,'QK3','G_MCSOTHER__p__QK3','','煤气阀门前馈' ): ''">K
									</div>
								</div>
							</div>
						</div>
						<div class="tablex">
							<div class="fl">
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-SP1</div>
									<div class="tablex-row1 fl colorl1" @click="
                  toIpt(
                    infoList.G_TEMP.JRDMPTSP,
                    'A02-SP1',
                     'JRDMPTSP',
                    'G_TEMP',
                   
                  )
                ">
										{{ infoList.G_TEMP.JRDMPTSP }}
									</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-SP2</div>
									<div class="tablex-row1 fl colorl1" @click="
                  toIpt(
                    infoList.G_TEMP.SJRMPTSP,
                    'A02-SP2',
                     'SJRMPTSP',
                    'G_TEMP',
                   
                  )
                ">
										{{ infoList.G_TEMP.SJRMPTSP }}
									</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-SP3</div>
									<div class="tablex-row1 fl colorl1" @click="
                  toIpt(
                    infoList.G_TEMP.EJRMPTSP,
                    'A02-SP3',
                     'EJRMPTSP',
                    'G_TEMP',
                   
                  )
                ">
										{{ infoList.G_TEMP.EJRMPTSP }}
									</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-SP4</div>
									<div class="tablex-row1 fl colorl1" @click="
                  toIpt(
                    infoList.G_TEMP.YJRMPTSP,
                    'A02-SP4',
                     'YJRMPTSP',
                    'G_TEMP',
                   
                  )
                ">
										{{ infoList.G_TEMP.YJRMPTSP }}
									</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-SP5</div>
									<div class="tablex-row1 fl colorl1" @click="
                  toIpt(
                    infoList.G_TEMP.JRDKPTSP,
                    'A02-SP5',
                     'JRDKPTSP',
                    'G_TEMP',
                   
                  )
                ">
										{{ infoList.G_TEMP.JRDKPTSP }}
									</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-SP6</div>
									<div class="tablex-row1 fl colorl1" @click="
                  toIpt(
                    infoList.G_TEMP.SJRKPTSP,
                    'A02-SP6',
                     'SJRKPTSP',
                    'G_TEMP',
                   
                  )
                ">
										{{ infoList.G_TEMP.SJRKPTSP }}
									</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-SP7</div>
									<div class="tablex-row1 fl colorl1" @click="
                  toIpt(
                    infoList.G_TEMP.EJRKPTSP,
                    'A02-SP7',
                     'EJRKPTSP',
                    'G_TEMP',
                   
                  )
                ">
										{{ infoList.G_TEMP.EJRKPTSP }}
									</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-SP8</div>
									<div class="tablex-row1 fl colorl1" @click="
                  toIpt(
                    infoList.G_TEMP.YJRKPTSP,
                    'A02-SP8',
                     'YJRKPTSP',
                    'G_TEMP',
                   
                  )
                ">
										{{ infoList.G_TEMP.YJRKPTSP }}
									</div>
								</div>

							</div>
							<div class="fl">
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-PV1</div>
									<div class="tablex-row1 fl colorl"
										@click="toCompon(2, 'T12PV2', 'G_TEMP', 'T12PV2_J1','均热段煤烟温度处理值')"
										@dblclick="Cclick(infoList.G_TEMP.T12PV2,'T12PV2', 'G_TEMP', 'T12PV2_J1')">
										{{infoList.G_TEMP.T12PV2}}</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-PV2</div>
									<div class="tablex-row1 fl " @click="toCompon(2, 'T22PV2', 'G_TEMP', 'T22PV2_J1','三加热段煤烟温度处理值')"
										@dblclick="Cclick(infoList.G_TEMP.T22PV2,'T22PV2', 'G_TEMP', 'T22PV2_J1')">
										{{infoList.G_TEMP.T22PV2}}</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-PV3</div>
									<div class="tablex-row1 fl colorl"
										@click="toCompon(2, 'T32PV2', 'G_TEMP', 'T32PV2_J1','二加热段煤烟温度处理值')"
										@dblclick="Cclick(infoList.G_TEMP.T32PV2,'T32PV2', 'G_TEMP', 'T32PV2_J1')">
										{{infoList.G_TEMP.T32PV2}}</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-PV4</div>
									<div class="tablex-row1 fl colorl"
										@click="toCompon(2, 'T42PV2', 'G_TEMP', 'T42PV2_J1')"
										@dblclick="Cclick(infoList.G_TEMP.T42PV2,'T42PV2', 'G_TEMP', 'T42PV2_J1')">
										{{infoList.G_TEMP.T42PV2}}</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-PV5</div>
									<div class="tablex-row1 fl colorl"
										@click="toCompon(2, 'T12PV1', 'G_TEMP', 'T12PV1_J1','均热段空烟温度处理值')"
										@dblclick="Cclick(infoList.G_TEMP.T12PV1,'T12PV1', 'G_TEMP', 'T12PV1_J1')">
										{{infoList.G_TEMP.T12PV1}}</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-PV6</div>
									<div class="tablex-row1 fl colorl"
										@click="toCompon(2, 'T22PV1', 'G_TEMP', 'T22PV1_J1','三加热段空烟温度处理值')"
										@dblclick="Cclick(infoList.G_TEMP.T22PV1,'T22PV1', 'G_TEMP', 'T22PV1_J1')">
										{{infoList.G_TEMP.T22PV1}}</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-PV7</div>
									<div class="tablex-row1 fl colorl"
										@click="toCompon(2, 'T32PV1', 'G_TEMP', 'T32PV1_J1','二加热段空烟温度处理值')"
										@dblclick="Cclick(infoList.G_TEMP.T32PV1,'T32PV1', 'G_TEMP', 'T32PV1_J1')">
										{{infoList.G_TEMP.T32PV1}}</div>
								</div>
								<div class="tablex-col1">
									<div class="tablex-row1 fl">A02-PV8</div>
									<div class="tablex-row1 fl colorl"
										@click="toCompon(2, 'T42PV1', 'G_TEMP', 'T42PV1_J1','一加热空烟温度处理值')"
										@dblclick="Cclick(infoList.G_TEMP.T42PV1,'T42PV1', 'G_TEMP', 'T42PV1_J1')">
										{{infoList.G_TEMP.T42PV1}}</div>
								</div>

							</div>
						</div>
					</div>
					<div class="fl ">
						<div class="row-colx col-jg">
							<div class="fl">A0-MYFPZ</div>
							<div class="fl wdjg colorl" @click="toCompon(2, 'MYFPZ1', 'G_PFJ', 'MYFPZ_J1','煤烟引风机阀位偏置')"
								@dblclick="Cclick(infoList.G_PFJ.MYFPZ1,'MYFPZ1', 'G_PFJ', 'MYFPZ_J1')">
								{{infoList.G_PFJ.MYFPZ1}}</div>
						</div>
						<div class="row-col col-jg1">
							<div class="fl">A02-K</div>
							<div class="fl wdjg colorl1" @click="
                  toIpt(
                    infoList.G_PFJ.YZXTK,
                    'A02-K',
                     'YZXTK',
                    'G_PFJ',
                   
                  )
                ">
								{{ infoList.G_PFJ.YZXTK }}
							</div>
						</div>
						<div class="row-colx col-jg2">
							<div class="fl">A0-YZFPZ</div>
							<div class="fl wdjg colorl" @click="toCompon(2, 'YZFPZ', 'G_PFJ', 'YZFPZ_J1','烟闸阀位偏置')"
								@dblclick="Cclick(infoList.G_PFJ.YZFPZ,'YZFPZ', 'G_PFJ', 'YZFPZ_J1')">
								{{infoList.G_PFJ.YZFPZ}}</div>
						</div>
						<div class="bottonx col-jg3" :style="{
            background: !infoList.G_MCSOTHER__p__RSF4.TS ? '#2AFC30' : 'red',
          }" @click="
            infoList.G_MCSOTHER__p__RSF4
              ? toDetail(
                  3,
                  'RSF4',
                  'G_MCSOTHER__p__RSF4',
                  '',
                  '烟闸强排风机协调软伺服'
                )
              : ''
          ">
							R
						</div>
						<div class="row-colx11 ">
							<div class="fl">A02-SP2</div>
							<div class="fl  row4-col1-jg5 colorl sz"
								@click="toCompon(2, 'SP', 'G_MCSOTHER__p__RSF4', 'RSF4_SP_J1','烟闸强排风机协调软伺服')"
								@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF4.SP,'SP', 'G_MCSOTHER__p__RSF4', 'RSF4_SP_J1')">
								{{infoList.G_MCSOTHER__p__RSF4.SP}}</div>
						</div>
						<div class="row-colx11 topx">
							<div class="fl">A02-PV2</div>
							<div class="fl  row4-col1-jg5 sz"
								@click="toCompon(2, 'PV', 'G_MCSOTHER__p__RSF4', 'RSF4_PV_J1','烟闸强排风机协调软伺服')"
								@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF4.PV,'PV', 'G_MCSOTHER__p__RSF4', 'RSF4_PV_J1')">
								{{infoList.G_MCSOTHER__p__RSF4.PV}}</div>
						</div>
						<div class="row4-div hx" :style="{
              background:
                infoList.G_MCSOTHER &&
                !infoList.G_MCSOTHER.RSF4SEL               
                  ?  'red':'#2AFC30'
                 
            }" @click="
                  toIpt(
                    infoList.G_MCSOTHER.RSF4SEL,
                    '状态',
                     'RSF4SEL',
                    'G_MCSOTHER',
                   
                  )
                ">{{infoList.G_MCSOTHER.RSF4SEL?'投用':'切除'}}</div>

					</div>

				</div>
				<div class="row5 fl">
					<div class="row-col-jgx">
						<div class="fl wdht">空烟手操器</div>
						<div class="fl  colorl sz" @click="toCompon(2, 'AV', 'G_MCSMAN__p__MAN13', 'MAN13_AV_J1','空烟气总管阀BCS手操器')"
							@dblclick="Cclick(infoList.G_MCSMAN__p__MAN13.AV,'AV', 'G_MCSMAN__p__MAN13', 'MAN13_AV_J1')">
							{{infoList.G_MCSMAN__p__MAN13.AV}}</div>
					</div>
					<div class="bottonx ajg" :style="{
              background:
                infoList.G_MCSMAN__p__MAN13 &&
                infoList.G_MCSMAN__p__MAN13.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN13
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN13',
                    'MAN13_RM_J1',
                    '加热炉_MAN'
                  )
                : ''
            ">A</div>

					<div class="bottonx ajg" style="margin-top:4vh" :style="{
              background:
                infoList.G_MCSMAN__p__MAN14 &&
                infoList.G_MCSMAN__p__MAN14.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN14
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN14',
                    'MAN14_RM_J1',
                    '加热炉_MAN'
                  )
                : ''
            ">A</div>
					<div class="row-col-jgx">
						<div class="fl wdht">煤烟手操器</div>
						<div class="fl  colorl sz" @click="toCompon(2, 'AV', 'G_MCSMAN__p__MAN14', 'MAN14_AV_J1','煤烟气总管阀BCS手操器')"
							@dblclick="Cclick(infoList.G_MCSMAN__p__MAN14.AV,'AV', 'G_MCSMAN__p__MAN14', 'MAN14_AV_J1')">
							{{infoList.G_MCSMAN__p__MAN14.AV}}</div>
					</div>

					<div class="bottonx ajg1" :style="{
              background:
                infoList.G_MCSMAN__p__MAN15 &&
                infoList.G_MCSMAN__p__MAN15.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN15
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN15',
                    'MAN15_RM_J1',
                    '加热炉_MAN'
                  )
                : ''
            ">A</div>

					<div class="row-col-jgx" style="margin-top:1vh">
						<div class="fl wdht">烟闸手操器</div>
						<div class="fl  colorl sz" @click="toCompon(2, 'AV', 'G_MCSMAN__p__MAN15', 'MAN15_AV_J1','烟闸阀门手操器')"
							@dblclick="Cclick(infoList.G_MCSMAN__p__MAN15.AV,'AV', 'G_MCSMAN__p__MAN15', 'MAN15_AV_J1')">
							{{infoList.G_MCSMAN__p__MAN15.AV}}</div>
					</div>
				</div>
			</div>

		</div>

		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isClose" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList"></Manual>

		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:infoList="infoList"></Historical>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "JRL_mqzg",
		components: {
			Manual,
			Historical,
			inputVal,
		},
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		data: () => {
			return {
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				isMshow: false,
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
				spotArr: [],
				authInfo: [],
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
		},
		computed: {},
		mounted() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) :
				[];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'jrkfirstcontol'
							break
						case 3:
							pathname = 'jrRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			closeCompon() {
				this.$emit("sendStatus", "JRLParam05", false);
			},
			isClose() {
				this.isMshow = false;
			},
			isClose1() {
				this.isHshow = false;
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (
								(this.isHshow = true),
								(this.chName = name4)
							);
					}
				}, 300);
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.jrl05 {
		width: 55vw;
		height: 78vh;
		//  background: #e9f7ff;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		//  cursor:move;
		border-radius: 5px;

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.main-top {
			margin-left: 2vw;
			font-size: 1.8vh;
			color: #8aeaff;
			font-size: 2vh;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.row1 {
			width: 50vw;
			height: 2vh;
			// border:1px solid saddlebrown;
			margin-top: 1vh;
			line-height: 2vh;
		}

		.row2 {
			width: 40vw;
			height: 3vh;
			// border:1px solid saddlebrown;
			margin-top: 1vh;
			line-height: 3vh;
			margin-left: 6vw;
		}

		.row-col {
			width: 10vw;
			height: 3vh;
			line-height: 3vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 1px #236f8d;
		}

		.row-colx1 {
			width: 10vw;
			height: 3vh;
			line-height: 3vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 1px #236f8d;
			margin-left: -1vw;
			;
		}

		.row-colx11 {
			width: 9vw;
			height: 3vh;
			line-height: 3vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 1px #236f8d;
			margin-left: 0vw;
			;
			margin-top: 0.5vh;
			margin-left: -1vw;
		}

		.row-colx {
			width: 12vw;
			height: 3vh;
			line-height: 3vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 1px #236f8d;
		}

		.row-col1 {
			width: 8vw;
			height: 3vh;
			line-height: 3vh;
			background-color: #001b2860;
			border-radius: 0vh;
			border: solid 1px #236f8d;
		}

		.wdjg {
			margin-left: 2vw;
			cursor: pointer;
		}

		.row-col-jg {
			margin-left: 2vw;
		}

		.row-col-jgx {
			margin-left: 5vw;
		}

		.ajg {
			margin-top: 3.2vh;
			margin-left: 8vw;
		}

		.ajg1 {
			margin-top: 9vh;
			margin-left: 8vw;
		}

		.wdht {
			width: 6vw;
		}

		.colorl1 {
			color: #00ffb4;
			cursor: pointer;
		}

		.title-hang {
			height: 5vh;
			width: 53vw;
			font-family: MicrosoftYaHei;
			font-size: 1.8vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			padding-top: 0.5vh;
			// margin-top:vh;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.lable1 {
			font-family: PingFang-SC-Regular;
			font-size: 2.5vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-left: 2.5vw;
			margin-top: 1vh;
		}

		.titlelable {
			width: 53vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0.3vw;
			color: #d5fffe;
			text-align: center;
			margin-top: 4vh;
		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 50vw;
		}

		.button-top {
			width: 5vw;
			height: 3vh;
			background-color: #008db1;
			text-align: center;
			line-height: 3vh;
			margin-right: 3vw;
		}

		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 2vh;
			// margin-right: 0.1vw;
			color: #fff;
			cursor: pointer;
		}

		.mar-jg3 {
			margin-left: 16vw;
			margin-top: 4vh;
		}

		.row3 {
			margin-top: -2vh;
			width: 45vw;
			height: 46vh;
			background-image: url("~@/assets/images/jrl-cs.png");
			background-size: 100% 100%;
			margin-left: 0.5vw;
			padding-top: 1vh;
			position: relative;
			z-index: -1;
			// border: #001b2860 solid 1px;
		}

		.row4 {
			width: 30vw;
			height: 3vh;
			margin-top: 2vh;
			line-height: 3vh;
			margin-left: 16vw;
			z-index: 1;
		}

		.row4-jg {
			margin-left: 13vw;
		}

		.row4-div {
			width: 4vw;
			height: 2.5vh;
			background-color: #04ff57;
			border-radius: 0vw;
			line-height: 2.5vh;
			text-align: center;
			color: #fff;
			margin-left: 18vw;
			font-size: 2vh;
			cursor: pointer;
			// border:1px red solid
		}

		.row3-col1-wid {
			width: 4vw;
			font-size: 2vh;
		}

		.row3-col1-widx {
			width: 6vw;
			font-size: 2vh;
		}

		.row3-col1-wid2 {
			width: 4vw;
			font-size: 2vh;
		}

		.row3-col1-wid3 {
			width: 5vw;
			font-size: 2vh;
		}

		.row3-col1-wid1 {
			width: 8vw;
			font-size: 2vh;
		}

		.jg111 {
			margin-top: -1vh;
			// margin-left:1vw;
		}

		.jg222 {
			margin-top: 2vh;
			margin-left: 1vw;
		}

		.jgg {
			margin-left: 5.2vw;
			margin-top: -0.5vh;
		}

		.jgg1 {
			margin-left: 5.2vw;
			margin-top: 8.5vh;
		}

		.row5 {
			width: 15vw;
			margin-left: -8vw;
			height: 45vh;
			// border:1px solid saddlebrown
		}


		.row3-col1 {
			margin-left: 1vw;
			margin-top: 1.5vh;
		}

		.row4-col1-jg11 {
			margin-top: 3.5vh;
			// margin-left:1vw;
		}

		.row4-col1-jg {
			margin-top: 3.5vh;
		}

		.row4-col1-jg2 {
			margin-left: 3vw;
		}

		.row4-col1-jg3 {
			margin-left: 6vw;
			margin-top: 13vh
		}

		.row4-col1-jg4 {
			margin-left: 1vw;
			cursor: pointer;
		}

		.row4-col1-jg5 {
			margin-left: 1vw;
		}

		.sz {
			cursor: pointer;
		}

		.tablex {
			width: 20vw;
			height: 24vh;
			background-color: #001b2860;
			border: solid 1px #236f8d;
			margin-top: 25vh;
			margin-left: -3vw;
			font-size: 2vh;
		}

		.tablex-row1 {
			width: 5vw;
			height: 3vh;
			line-height: 3vh;
			text-align: center;
			cursor: pointer;
		}

		.col-jg {
			margin-top: 14vh;
			margin-left: 7vw;
		}

		.col-jg1 {
			margin-top: 5vh;
			margin-left: 9vw;
		}

		.col-jg2 {
			margin-top: 0.5vh;
			margin-left: 1vw;
		}

		.col-jg3 {
			margin-top: 3vh;
			margin-left: 8vw;
		}

		.topx {
			margin-top: 1.5vh;
		}

		.hx {
			margin-left: 10vw;
			margin-top: -4vw;
		}
	}
</style>
