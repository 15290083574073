<template>
	<div class="jrl_kqzg drag" v-draw id="Jrlkq">
		<div class="title-hang" fl>
			{{ projectData.project }}_参数02
		</div>
		<div class="param11-header-icon" fr @click="closeCompon">
			<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
		</div>
		<div class="param02-tab">
			<div class="param11-tab-tit">空气总管压力控制参数</div>
			<div class="paeam02-list1 flex">
				<div class="paeam02-list1-left">
					<div class="left-box1 flex align-cen">
						<div>A01-TSP</div>
						<div class="ma-auto" style="color: #00ffb4;"
							@click="toIpt(infoList.G_PFJ.PKQ_SP1, '空气总管压力设定值', 'PKQ_SP1', 'G_PFJ')">
							{{infoList.G_PFJ.PKQ_SP1}}
						</div>
					</div>
					<div class="left-box1 left-box2 flex align-cen">
						<div>A01-TPV</div>
						<div class="ma-auto" @click="toCompon(2, 'PIA_101_B', 'G_SOUREC', 'PIA_101_J1','均热段空气压力')"
							@dblclick="Cclick(infoList.G_SOUREC.PIA_101_B,'PIA_101_B', 'G_SOUREC', 'PIA_101_J1')">
							{{infoList.G_SOUREC.PIA_101_B}}
						</div>
					</div>
					<div class="left-box1 flex align-cen">
						<div>PKQTC1</div>
						<div class="ma-auto" style="color: #00ffb4;"
							@click="toIpt(infoList.G_PFJ.PKQTC1, '空气总管压力1滤波系数', 'PKQTC1', 'G_PFJ')">
							{{infoList.G_PFJ.PKQTC1}}
						</div>
					</div>
				</div>
				<div class="paeam02-list1-right">
					<!-- <div class="line"></div> -->
					<div class="right-cen">
						<div class="left-box1 flex align-cen">
							<div>A02-SUMPV</div>
							<div class="ma-auto"
								@click="toCompon(2, 'PV', 'G_MCSOTHER__p__QK6', 'QK6_PV_J1','鼓风机空气阀门前馈')"
								@dblclick="Cclick(infoList.G_MCSOTHER__p__QK6.PV,'PV', 'G_MCSOTHER__p__QK6', 'QK6_PV_J1')">
								{{infoList.G_MCSOTHER__p__QK6.PV}}
							</div>
						</div>
						<!-- <div class="line2"></div> -->
						<div class="child-dataK child-databtn " :style="{
                                background:
                                infoList.G_MCSOTHER__p__QK6 &&
                                !infoList.G_MCSOTHER__p__QK6.TS
                                ? '#2AFC30'
                                : 'red',
                                }"
							@click="infoList.G_MCSOTHER__p__QK6 ? toDetail(2,'QK6','G_MCSOTHER__p__QK6','','鼓风机空气阀门前馈' ): ''">
							K</div>
						<div class="child-dataX child-databtn" :style="{
                                background:
                                    infoList.G_MCSXK__p__XK23 &&
                                    infoList.G_MCSXK__p__XK23.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                                }"
							@click="infoList.G_MCSXK__p__XK23 ? toDetail(1,'XK23','G_MCSXK__p__XK23','','空气总管压力XK' ): ''">
							X</div>
						<div class="child-dataA child-databtn"
							:style="{ background: infoList.G_MCSMAN__p__MAN16 && infoList.G_MCSMAN__p__MAN16.RM == 1 ? '#2AFC30' : 'red',}"
							@click="infoList.G_MCSMAN__p__MAN16 ? toCompon(0,'AV','G_MCSMAN__p__MAN16','MAN16_AV_J1','均热段鼓风机手操器MAN16') : ''">
							A</div>
					</div>

				</div>
			</div>
			<div class="paeam02-list1 flex paeam02-list2">
				<div class="paeam02-list1-left">
					<div class="left-box1 flex align-cen">
						<div>A01-TSP</div>
						<div class="ma-auto" style="color: #00ffb4;"
							@click="toIpt(infoList.G_PFJ.PKQ_SP2, '修改', 'PKQ_SP2', 'G_PFJ')">
							{{infoList.G_PFJ.PKQ_SP2}}
						</div>
					</div>
					<div class="left-box1 left-box2 flex align-cen">
						<div>A01-TPV</div>
						<div class="ma-auto" @click="toCompon(2, 'PIA_102_B', 'G_SOUREC', 'PIA_102_J1','三加热段压力')"
							@dblclick="Cclick(infoList.G_SOUREC.PIA_102_B,'PIA_102_B', 'G_SOUREC', 'PIA_102_J1')">
							{{infoList.G_SOUREC.PIA_102_B}}
						</div>
					</div>
					<div class="left-box1 flex align-cen">
						<div>PKQTC2</div>
						<div class="ma-auto" style="color: #00ffb4;"
							@click="toIpt(infoList.G_PFJ.PKQTC2, '空气总管压力2滤波系数', 'PKQTC2', 'G_PFJ')">
							{{infoList.G_PFJ.PKQTC2}}
						</div>
					</div>
				</div>
				<div class="paeam02-list1-right">
					<!-- <div class="line"></div> -->
					<div class="right-cen">
						<div class="left-box1 flex align-cen">
							<div>A02-SUMPV</div>
							<div class="ma-auto"
								@click="toCompon(2, 'PV', 'G_MCSOTHER__p__QK6', 'QK6_PV_J1','鼓风机空气阀门前馈')"
								@dblclick="Cclick(infoList.G_MCSOTHER__p__QK6.PV,'PV', 'G_MCSOTHER__p__QK6', 'QK6_PV_J1')">
								{{infoList.G_MCSOTHER__p__QK6.PV}}
							</div>
						</div>
						<!-- <div class="line2"></div> -->
						<div class="child-dataK child-databtn " :style="{
                                background:
                                infoList.G_MCSOTHER__p__QK6 &&
                                !infoList.G_MCSOTHER__p__QK6.TS
                                ? '#2AFC30'
                                : 'red',
                                }"
							@click="infoList.G_MCSOTHER__p__QK6 ? toDetail(2,'QK6','G_MCSOTHER__p__QK6','','鼓风机空气阀门前馈' ): ''">
							K</div>
						<div class="child-dataX child-databtn" :style="{
                                background:
                                    infoList.G_MCSXK__p__XK24 &&
                                    infoList.G_MCSXK__p__XK24.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                                }"
							@click="infoList.G_MCSXK__p__XK24 ? toDetail(1,'XK24','G_MCSXK__p__XK24','','空气总管压力XK' ): ''">
							X</div>
						<div class="child-dataA child-databtn"
							:style="{ background: infoList.G_MCSMAN__p__MAN17 && infoList.G_MCSMAN__p__MAN17.RM == 1 ? '#2AFC30' : 'red',}"
							@click="infoList.G_MCSMAN__p__MAN17 ? toCompon(0,'AV','G_MCSMAN__p__MAN17','MAN17_AV_J1','三加热段鼓风机手操器MAN17') : ''">
							A</div>
					</div>

				</div>
			</div>
			<div class="paeam02-list1 flex paeam02-list2">
				<div class="paeam02-list1-left">
					<div class="left-box1 flex align-cen">
						<div>A01-TSP</div>
						<div class="ma-auto" style="color: #00ffb4;"
							@click="toIpt(infoList.G_PFJ.PKQ_SP3, '修改', 'PKQ_SP3', 'G_PFJ')">
							{{infoList.G_PFJ.PKQ_SP3}}
						</div>
					</div>
					<div class="left-box1 left-box2 flex align-cen">
						<div>A01-TPV</div>
						<div class="ma-auto" @click="toCompon(2, 'PIA_103_B', 'G_SOUREC', 'PIA_103_J1','二加热段空气压力')"
							@dblclick="Cclick(infoList.G_SOUREC.PIA_103_B,'PIA_103_B', 'G_SOUREC', 'PIA_103_J1')">
							{{infoList.G_SOUREC.PIA_103_B}}
						</div>
					</div>
					<div class="left-box1 flex align-cen">
						<div>PKQTC3</div>
						<div class="ma-auto" style="color: #00ffb4;"
							@click="toIpt(infoList.G_PFJ.PKQTC3, '空气总管压力3滤波系数', 'PKQTC3', 'G_PFJ')">
							{{infoList.G_PFJ.PKQTC3}}
						</div>
					</div>
				</div>
				<div class="paeam02-list1-right">
					<!-- <div class="line"></div> -->
					<div class="right-cen">
						<div class="left-box1 flex align-cen">
							<div>A02-SUMPV</div>
							<div class="ma-auto"
								@click="toCompon(2, 'PV', 'G_MCSOTHER__p__QK6', 'QK6_PV_J1','鼓风机空气阀门前馈')"
								@dblclick="Cclick(infoList.G_MCSOTHER__p__QK6.PV,'PV', 'G_MCSOTHER__p__QK6', 'QK6_PV_J1')">
								{{infoList.G_MCSOTHER__p__QK6.PV}}
							</div>
						</div>
						<!-- <div class="line2"></div> -->
						<div class="child-dataK child-databtn " :style="{
                                background:
                                infoList.G_MCSOTHER__p__QK6 &&
                                !infoList.G_MCSOTHER__p__QK6.TS
                                ? '#2AFC30'
                                : 'red',
                                }"
							@click="infoList.G_MCSOTHER__p__QK6 ? toDetail(2,'QK6','G_MCSOTHER__p__QK6','','鼓风机空气阀门前馈' ): ''">
							K</div>
						<div class="child-dataX child-databtn" :style="{
                                background:
                                    infoList.G_MCSXK__p__XK25 &&
                                    infoList.G_MCSXK__p__XK25.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                                }"
							@click="infoList.G_MCSXK__p__XK25 ? toDetail(1,'XK25','G_MCSXK__p__XK25','','空气总管压力XK' ): ''">
							X</div>
						<div class="child-dataA child-databtn"
							:style="{ background: infoList.G_MCSMAN__p__MAN18 && infoList.G_MCSMAN__p__MAN18.RM == 1 ? '#2AFC30' : 'red',}"
							@click="infoList.G_MCSMAN__p__MAN18 ? toCompon(0,'AV','G_MCSMAN__p__MAN18','MAN18_AV_J1','二热段鼓风机手操器MAN18') : ''">
							A</div>
					</div>

				</div>
			</div>
			<div class="paeam02-list1 flex paeam02-list2">
				<div class="paeam02-list1-left">
					<div class="left-box1 flex align-cen">
						<div>A01-TSP</div>
						<div class="ma-auto" style="color: #00ffb4;"
							@click="toIpt(infoList.G_PFJ.PKQ_SP4, '修改', 'PKQ_SP4', 'G_PFJ')">
							{{infoList.G_PFJ.PKQ_SP4}}
						</div>
					</div>
					<div class="left-box1 left-box2 flex align-cen">
						<div>A01-TPV</div>
						<div class="ma-auto" @click="toCompon(2, 'PIA_104_B', 'G_SOUREC', 'PIA_104_B_J1')"
							@dblclick="Cclick(infoList.G_SOUREC.PIA_104_B,'PIA_104_B', 'G_SOUREC', 'PIA_104_J1')">
							{{infoList.G_SOUREC.PIA_104_B}}
						</div>
					</div>
					<div class="left-box1 flex align-cen">
						<div>PKQTC4</div>
						<div class="ma-auto" style="color: #00ffb4;"
							@click="toIpt(infoList.G_PFJ.PKQTC4, '空气总管压力4滤波系数', 'PKQTC4', 'G_PFJ')">
							{{infoList.G_PFJ.PKQTC4}}
						</div>
					</div>
				</div>
				<div class="paeam02-list1-right">
					<!-- <div class="line"></div> -->
					<div class="right-cen">
						<div class="left-box1 flex align-cen">
							<div>A02-SUMPV</div>
							<div class="ma-auto"
								@click="toCompon(2, 'PV', 'G_MCSOTHER__p__QK6', 'QK6_PV_J1','鼓风机空气阀门前馈')"
								@dblclick="Cclick(infoList.G_MCSOTHER__p__QK6.PV,'PV', 'G_MCSOTHER__p__QK6', 'QK6_PV_J1')">
								{{infoList.G_MCSOTHER__p__QK6.PV}}
							</div>
						</div>
						<!-- <div class="line2"></div> -->
						<div class="child-dataK child-databtn " :style="{
                                background:
                                infoList.G_MCSOTHER__p__QK6 &&
                                !infoList.G_MCSOTHER__p__QK6.TS
                                ? '#2AFC30'
                                : 'red',
                                }"
							@click="infoList.G_MCSOTHER__p__QK6 ? toDetail(2,'QK6','G_MCSOTHER__p__QK6','','鼓风机空气阀门前馈' ): ''">
							K</div>
						<div class="child-dataX child-databtn" :style="{
                                background:
                                    infoList.G_MCSXK__p__XK26 &&
                                    infoList.G_MCSXK__p__XK26.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                                }"
							@click="infoList.G_MCSXK__p__XK26 ? toDetail(1,'XK26','G_MCSXK__p__XK26','','空气总管压力XK' ): ''">
							X</div>
						<div class="child-dataA child-databtn"
							:style="{ background: infoList.G_MCSMAN__p__MAN19 && infoList.G_MCSMAN__p__MAN19.RM == 1 ? '#2AFC30' : 'red',}"
							@click="infoList.G_MCSMAN__p__MAN19 ? toCompon(0,'AV','G_MCSMAN__p__MAN19','MAN19_AV_J1','一热段鼓风机手操器MAN19') : ''">
							A</div>
					</div>

				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList"></Manual>
	</div>
</template>
<script>
	import inputVal from "./inputVal" //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'jrkfirstcontol'
							break
						case 3:
							pathname = 'jrRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'JRLParam02', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.jrl_kqzg {
		width: 53vw;
		height: 76vh;
		background: url("~@/assets/images/jrl-param.png") no-repeat 0 0;
		background-size: 100% 100%;
		cursor: move;
		border-radius: 5px;

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 50vw;
		}

		.param11-header {
			height: 50px;
			//  background: #5DCEF3;
			// line-height: 50px;
			text-align: center;
			color: #041a28;
			font-size: 1.5vw;
			position: relative;
			border-radius: 5px 5px 0 0;

			.param11-header-title {
				width: 179px;
				height: 28px;
				font-family: MicrosoftYaHei;
				font-size: 24px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #0ef7ff;
			}

			.param11-header-icon {
				width: 2vw;
				height: 2vh;
				position: absolute;
				right: 1vw;
				margin-top: -5vh;
				cursor: pointer;
			}
		}

		.param02-tab {

			.param11-tab-tit {
				margin-top: 5vh;
				width: 100%;
				height: 26px;
				font-family: PingFang-SC-Regular;
				line-height: 20px;
				font-weight: normal;
				font-stretch: normal;
				font-size: 1.2vw;
				text-align: center;
				letter-spacing: 6px;
				color: #d5fffe;
			}

			.paeam02-list1 {
				width: 39vw;
				height: 13vh;
				margin: 0 auto;
				margin-top: 2vh;

				.paeam02-list1-left {
					width: 10vw;

					.left-box1 {
						width: calc(100% - 1vw);
						height: 3vh;
						border: solid 1px #184054;
						background-color: #184054;
						border-radius: 2px;
						color: #8aeaff;
						font-size: 1vw;
						padding: 0 .5vw;

						.ma-auto {
							color: #8aeaff;
							font-size: 1vw;
							cursor: pointer;
						}
					}

					.left-box2 {
						border: none;
						margin-top: .5vh;
						margin-bottom: 0.5vh;
					}
				}

				.paeam02-list1-right {
					width: 28.9vw;
					margin-left: auto;
					margin-left: .1vw;
					margin-top: calc(1.5vh - 1.5px);
					position: relative;
					//  .line{
					//    width: 6vw;
					//    height: 1.5px;
					//   background: rgba(199,221,233,1);
					//  }

					.right-cen {
						width: 100%;
						height: 13vh;
						background: url("~@/assets/images/jrl2.png") no-repeat 0 0;
						background-size: 30vw 10vh;
						position: relative;

						.left-box1 {
							width: 10vw;
							height: 3vh;
							border: solid 1px #184054;
							background-color: #184054;
							border-radius: 2px;
							color: #8aeaff;
							font-size: 1vw;
							padding: 0 .5vw;
							position: absolute;
							bottom: 1vh;
							left: 0.5vw;

							.ma-auto {
								color: #8aeaff;
								font-size: 1vw;
								cursor: pointer;
							}
						}

						.line2 {
							width: 8.6vw;
							height: 1.5px;
							background: rgba(199, 221, 233, 1);
							position: absolute;
							bottom: -0.1vh;
							left: 12.2vw;
						}

						.child-databtn {

							color: #1EB0F5;
							width: 1vw;
							height: 2vh;
							line-height: 2vh;
							text-align: center;
							box-sizing: border-box;
							border: 1px solid #FDF061;
							font-size: 1vw;
							cursor: pointer;
							color: #fff;

						}

						.child-dataK {
							position: absolute;
							bottom: 2vh;
							left: 15.3vw;
						}

						.child-dataX {
							position: absolute;
							top: 3vh;
							left: 15.3vw;
						}

						.child-dataA {
							position: absolute;
							right: -10px;
							top: 3vh;
						}
					}
				}
			}



		}
	}
</style>
