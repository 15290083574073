<template>
	<div class="JrAssessment drag" v-draw id="JrAssessment">
		<div class="evaluate-header">
			加热炉_运行考核
			<div class="evaluate-header-icon" @click="closeCompon">
				<span class="el-icon-close"></span>
			</div>
		</div>
		<div class="Jrconsttit">运行考核</div>
		<div class="security-tab">
			<div class="security-tab-top">
				<div class="tab-top-row flex">
					<div>序号</div>
					<div>过钢累计(根)</div>
					<div>钢重累计(T)</div>
					<div>煤气累计</div>
					<div>炉膛均压</div>
					<div>均热均温</div>
					<div>出钢均温</div>
					<div>入钢均温</div>
					<div>热装率%</div>
					<div>单耗(m³/t)</div>
					<div>自控累计%</div>
					<div>稳定性%</div>
				</div>
				<div class="tab-top-row flex">
					<div>班运行</div>
					<div class="divfri1" @click="toCompon(2,'KHGGLJB','G_KHTJ','KHGGLJB_J1','过钢量班累积')">
						{{infoList.G_KHTJ.KHGGLJB}}</div>
					<div class="divfri1">0.0</div>
					<div class="divfri1">0.00</div>
					<div class="divfri1">0.00</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0.0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
				</div>
				<div class="tab-top-row flex">
					<div>天运行</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0.0</div>
					<div class="divfri1">0.00</div>
					<div class="divfri1">0.00</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0.0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
				</div>
				<div class="tab-top-row flex">
					<div>月运行</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0.0</div>
					<div class="divfri1">0.00</div>
					<div class="divfri1">0.00</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0.0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
				</div>
				<div class="tab-top-row flex">
					<div>自定义运行</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0.0</div>
					<div class="divfri1">0.00</div>
					<div class="divfri1">0.00</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0.0</div>
					<div class="divfri1">0</div>
					<div class="divfri1">0</div>
				</div>
			</div>
			<div class="security-tab-bot ">
				<div class="tab-top-row flex">
					<div class="divfri">等待考核</div>
					<div>指标名称</div>
					<div>指标精度</div>
					<div></div>
					<div></div>
					<div>回路名称</div>
					<div>统计使能</div>
					<div>自控率</div>
					<div>回路名称</div>
					<div>统计使能</div>
					<div>自控率</div>
				</div>
				<div class="tab-top-row flex">
					<div class="divfri1">自定义考核</div>
					<div>煤气压力</div>
					<div class="divfri1" @click="toIpt(infoList.G_KHTJ.KH_MQPSP,'煤气压力', 'KH_MQPSP', 'G_KHTJ')">
						{{infoList.G_KHTJ.KH_MQPSP}}</div>
					<div>热装温度</div>
					<div class="divfri1">0.00</div>
					<div>煤气压力</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div>二加空气</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div>结束考核</div>
					<div>空气压力</div>
					<div class="divfri1">0.00</div>
					<div></div>
					<div></div>
					<div>空气压力</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div>二加煤气</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div>考核设定</div>
					<div>炉膛压力</div>
					<div class="divfri1">0.00</div>
					<div></div>
					<div></div>
					<div>炉膛压力</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div>二加空烟</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div>0.00</div>
					<div>均热温度</div>
					<div class="divfri1">0.00</div>
					<div></div>
					<div></div>
					<div>均热空气</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div>二加煤气</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div>考核进行时间</div>
					<div>均热烟温</div>
					<div class="divfri1">0</div>
					<div></div>
					<div></div>
					<div>均热煤气</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div>一加空气</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div>0.00</div>
					<div>三加温度</div>
					<div class="divfri1">0</div>
					<div></div>
					<div></div>
					<div>均热空烟</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div>一加煤气</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div>考核开始时间</div>
					<div>三加烟温</div>
					<div class="divfri1">0</div>
					<div></div>
					<div></div>
					<div>均热煤烟</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div>一加空烟</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div></div>
					<div>二加温度</div>
					<div class="divfri1">0</div>
					<div></div>
					<div></div>
					<div>三加空气</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div>一加煤烟</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div>考核结束时间</div>
					<div>二加烟温</div>
					<div class="divfri1">0</div>
					<div></div>
					<div></div>
					<div>三加煤气</div>
					<div>统计切除</div>
					<div></div>
					<div></div>
					<div>统计切除</div>
					<div></div>
				</div>
				<div class="tab-top-row flex">
					<div></div>
					<div>一加温度</div>
					<div class="divfri1">0</div>
					<div></div>
					<div></div>
					<div>三加空烟</div>
					<div>统计切除</div>
					<div class="divfri1">0.0</div>
					<div></div>
					<div>总自控率</div>
					<div class="divfri1">0.0</div>
				</div>
				<div class="tab-top-row flex">
					<div></div>
					<div>一加烟温</div>
					<div class="divfri1">0</div>
					<div></div>
					<div></div>
					<div>三加煤烟</div>
					<div>统计切除</div>
					<div></div>
					<div></div>
					<div>综合性指标</div>
					<div class="divfri1">0.0</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isClose1' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
	</div>
</template>
<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue"; //历史趋势
	export default {
		name: "JrAssessment",
		components: {
			inputVal,
			Historical
		},
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},

		data: () => {
			return {
				chName: '',
				historyname: "",
				node: "",
				Lkname: "",
				isIndex: "",
				isComShow: false,
				isHshow: false,
				isClose: false,
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		methods: {
			isClose1() {
				this.isHshow = false;
			},
			toCompon(key, name, name2, name3, name4) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				switch (key) {
					case 0:
						return (this.isMshow = true);
					case 1:
						return (this.isFshow = true);
					case 2:
						return (this.isHshow = true, this.chName = name4);
				}
			},
			//    打开输入框组件。
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			closeCompon() {
				this.$emit("sendStatus", "JrAssessment", false);
			},
		},
	};
</script>
<style lang="scss" scoped>
	$base-rebrder-color: rgba(0, 22, 36, 0.2);

	.JrAssessment {
		width: 100vw;
		height: calc(100vh - 14vh);
		background: #dfedf5;
		font-family: "PingFang-SC-Regular";
		cursor: move;
		border-radius: 5px;

		.evaluate-header {
			height: 50px;
			background: #5dcef3;
			line-height: 50px;
			text-align: center;
			color: #041a28;
			font-size: 16px;
			position: relative;
			border-radius: 5px 5px 0 0;

			.evaluate-header-icon {
				width: 32px;
				height: 32px;
				position: absolute;
				right: 10px;
				top: 8px;
				border-radius: 32px;
				border: 1px solid #041a28;
				color: #041a28;
				line-height: 32px;
				cursor: pointer;
				font-size: 24px;
			}
		}

		.Jrconsttit {
			height: 50px;
			width: 967px;
			margin: 0 auto;
			color: #0a4c62;
			letter-spacing: 6px;
			font-family: "PingFang-SC-Regular";
			font-size: 28px;
			text-align: center;
			position: relative;
			margin-top: 20px;
		}

		.security-tab-top {
			width: 1050px;
			height: 193px;
			margin: 0 auto;
			border-top: 1px solid $base-rebrder-color;
			border-left: 1px solid $base-rebrder-color;

			.tab-top-row {
				width: 100%;
				height: 38.6px;
				line-height: 38.6px;

				div {
					width: 87.5px;
					text-align: center;
					font-size: 15px;
					color: #0a4c62;
					box-sizing: border-box;
					border-right: 1px solid $base-rebrder-color;
					border-bottom: 1px solid $base-rebrder-color;
				}

				.divfri1 {
					color: #1082a8;
				}
			}
		}

		.security-tab-bot {
			width: 1050px;
			height: 397px;
			margin: 0 auto;
			margin-top: 10px;
			border-top: 1px solid $base-rebrder-color;
			border-left: 1px solid $base-rebrder-color;

			.tab-top-row {
				width: 100%;
				height: 33.0833333px;
				line-height: 33.0833333px;

				div {
					width: 96px;
					text-align: center;
					font-size: 15px;
					color: #0a4c62;
					box-sizing: border-box;
					border-right: 1px solid $base-rebrder-color;
					border-bottom: 1px solid $base-rebrder-color;
				}

				.divfri {
					color: #ec163e;
					box-shadow: 0px 1px 0px 0px #ffffff;
				}

				.divfri1 {
					color: #1082a8;
				}

				div:last-child {
					width: 90px;
				}
			}
		}
	}
</style>
