<template>
  <div class="Jrswitchpage drag" v-draw>
    <div class="evaluate-header">
        加热炉_切换画面
      <div class="evaluate-header-icon" @click="closeCompon">
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="Jrconsttit">
        BCS/DCS切换画面
    </div>
    <div class="security-tab">
      <div class="security-tab-btn">一键全切</div>
      <div class="security-tab-row">
        <div class="tab-row-flex flex">
          <div class="tab-row-flex1 ">
           <div class="list-row flex">
              <div>段号</div>
            <div>控制点</div>
            <div>空气阀门</div>
            <div>煤气阀门</div>
            <div>空烟阀门</div>
            <div>煤烟阀门</div>
            <div>空燃比</div>
            <div>上限减煤气</div>
            <div>煤气量上限</div>
           </div>
           <div class="list-row flex">
            <div>均热段</div>
            <div class="defultcolor">0</div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
           <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>0.0</div>
             <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>0</div>
          </div>
          <div class="list-row flex">
            <div>三加热段</div>
            <div class="defultcolor">0</div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
           <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>0.0</div>
             <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>0</div>
          </div>
          <div class="list-row flex">
            <div>二加热段</div>
            <div class="defultcolor">0</div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
           <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>0.0</div>
             <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>0</div>
          </div>
            <div class="list-row flex">
            <div>一加热段</div>
            <div class="defultcolor">0</div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
           <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>0.0</div>
             <div>
              <span class="flexbtn" :style= "{background:(!infoList ?'red':'#29DFAE')}"></span>
            </div>
            <div>0</div>
          </div>
           <div class="list-row flex">
            <div>炉膛压力</div>
            <div class="defultcolor">0.0</div>
            <div></div>
            <div></div>
           <div class="defultcolor1">
              空烟总阀
            </div>
             <div class="defultcolor1">
              煤烟总阀
            </div>
            <div></div>
            <div></div>
            <div></div>
          </div>
            <div class="list-row flex">
            <div>鼓风压力</div>
            <div class="defultcolor">0.0</div>
            <div class="defultcolor1">鼓风机1</div>
            <div class="defultcolor2">鼓风机1</div>
            <div></div>
             <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
           <div class="list-row flex">
            <div>煤气压力</div>
            <div class="defultcolor">0.0</div>
            <div class="defultcolor1">煤气总管</div>
            <div class="defultcolor2"></div>
            <div></div>
             <div></div>
            <div></div>
            <div></div>
            <div class="defultcolor">0</div>
          </div>
            <div class="list-row flex">
            <div>均热空烟</div>
            <div class="defultcolor">0.0</div>
            <div >三加空烟</div>
            <div class="defultcolor">0</div>
            <div>二加空烟</div>
             <div  class="defultcolor">0</div>
            <div>一加空烟</div>
            <div  class="defultcolor">0</div>
            <div></div>
          </div>
           <div class="list-row flex">
            <div>均热煤烟</div>
            <div class="defultcolor">0.0</div>
            <div >三加煤烟</div>
            <div class="defultcolor">0</div>
            <div>二加煤烟</div>
             <div  class="defultcolor">0</div>
            <div>一加煤烟</div>
            <div  class="defultcolor">0</div>
            <div></div>
          </div>
          </div>
          <div class="tab-row-flex2">
            <div>系统监控</div>
            <div class="otherdiv">
              <div style="border:0">
                DCS  OPC <span>0</span>
              </div>
              <div style="border:0">BCS  OPC <span>0</span></div>
            </div>
             <div>重要参数</div>
              <div class="defultcolor">本班吨钢耗气 <span>0</span></div>
                         <div>通讯报警</div>
            <div class="otherdiv1">
              <div style="border:0" class="flex align-cen justify-content-cen">
                <p>通讯异常</p>
                 <span class="flexbtn" :style= "{background:(infoList ?'#ec163e':'#29DFAE')}"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-row-flex-bot flex">
          <div>鼓风机上限</div>
          <div class="defultcolor">0</div>
           <div>鼓风机下限</div>
           <div class="defultcolor">0</div>
           <div>空烟风机上限</div>
           <div class="defultcolor">0</div>
           <div>空烟风机下限</div>
           <div class="defultcolor">0</div>
           <div>煤烟风机上限</div>
            <div class="defultcolor">0</div>
             <div>煤烟风机下限</div>
              <div class="defultcolor">0</div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
      <Historical v-if="isHshow" @sendStatus = 'isClose1' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
  </div>
</template>

<script>
import inputVal from "@/components/inputVal.vue"   //输入框组件
import Historical from "@/components/Historical.vue"  //历史趋势
export default {
       name: "Jrswitchpage",
        components: {inputVal,Historical},
    props:{
        infoList:{
            type:Object,
            default: {} // 默认值
        }
    },

    data: () => {
		return {
       historyname:'',
      node:'',
      Lkname:'',
      isIndex:'',
        isComShow:false,
      isHshow:false,
      isClose:false,
		}
        
	},
      watch: {
    infoList: {
      handler (n, o) {
       this.infoList = n
      },
      deep: true // 深度监听父组件传过来对象变化
    },
   },
       methods:{
          isClose1(){
          this.isHshow = false
        },
                 toCompon(key,name,name2,name3){
      this.isIndex = key
      this.historyname = name
      this.node = name2
      this.Lkname = name3
      switch(key){
        case 0:
          return this.isMshow = true
        case 1 :
          return  this.isFshow = true
        case 2:
          return  this.isHshow = true
      }
    },
        //    打开输入框组件。
        toIpt(data,name,historyname,node){
            this.$refs.inputVal.open(data,name,historyname,node)
        },
        closeCompon(){
           this.$emit('sendStatus', 'Jrswitchpage',false)
        },
    } 
}
</script>
<style lang="scss" scoped>
$base-rebrder-color:rgba(0,22,36,.2);
 .Jrswitchpage{
     width: 1024px;
     height: 725px;
     background: #DFEDF5;
     font-family: PingFang-SC-Regular;
     cursor:move;
     border-radius: 5px;
      .evaluate-header{
         height: 50px;
         background: #5DCEF3;
         line-height: 50px;
         text-align: center;
         color: #041A28;
         font-size: 16px;
         position: relative;
         border-radius: 5px 5px 0 0;
         .evaluate-header-icon{
         width: 32px;
         height: 32px;
         position: absolute;
         right: 10px;
         top: 8px;
         border-radius: 32px;
          border: 1px solid #041A28;
      color: #041A28;
         line-height: 32px;
        cursor: pointer;
            font-size: 24px
     }
     }
     .Jrconsttit{
         height: 50px;
         width: 967px;
         margin: 0 auto;
         color: #0a4c62;
         letter-spacing: 6px;
         font-family: 'PingFang-SC-Regular';
         font-size: 28px;
         text-align: center;
         position: relative;
         margin-top: 20px;
         .tit-left{
             font-size: 16px;
             position: absolute;
             left: 0;
             bottom:2px;
             letter-spacing:0px;
             letter-spacing: 0;
         }
         .tit-btn{
             position: absolute;
             right: 0;
             bottom:2px;
            .btn1{
             width: 100px;
            height: 28px;
            background-color: #61b9d6;
            color: #fff;
            font-size: 16px;
             letter-spacing: 0;
         }
         .btn2,.btn3{
             width: 100px;
            height: 28px;
            background-color: #abd5e3;
            color: #0a4c62;
            font-size: 16px;
             letter-spacing: 0;
             margin-left: 5px;
         }
         }
     }
     .security-tab{
         width: 992px;
         margin: 0 auto;
         margin-top: 3px;
         .security-tab-btn{
           width: 100px;
          height: 30px;
          background-color: #0de0ac;
          border-radius: 2px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 20px;
         }
         .tab-row-flex{
           width: 100%;
	         height: 376px;
           margin-top: 10px;
           .tab-row-flex1{
             width: 820px;
             height: inherit;
             border-top: 1px solid $base-rebrder-color;
            border-left: 1px solid $base-rebrder-color;
            .list-row{
              width: inherit;
              height: 37.6px;
                 div{
               height: 37.6px;
               width: 90px;
               line-height: 37.6px;
               color: #0a4c62;
               font-size: 15px;
               text-align: center;
               box-sizing: border-box;
        border-right: 1px solid $base-rebrder-color;
        border-bottom: 1px solid $base-rebrder-color;
        .flexbtn{
          display: block;
          width: 60px;
          height: 28.5px;
          background-color: #0de0ac;
          margin: 0 auto;
          margin-top: 4.5px;
        }
             }
             .defultcolor{
               color: #1082a8;
             }
             .defultcolor1{
               color: #0de0ac;
             }
             .defultcolor2{color: #ec163e;}
             div:first-child{
               width: 100px;
             }
            }
           }
           .tab-row-flex2{
             margin-left: auto;
             height: inherit;
              width: 163px;
               border-top: 1px solid $base-rebrder-color;
            border-left: 1px solid $base-rebrder-color;
              div{
                width: inherit;
                height: 37.6px;
                 line-height: 37.6px;
                text-align: center;
                color: #0a4c62;
                 border-right: 1px solid $base-rebrder-color;
                 border-bottom: 1px solid $base-rebrder-color;
                 font-size: 15px;
                  box-sizing: border-box;
              }
              .defultcolor{
                color: #1662ec;
                span{
                  display: inline-block;
                    padding-left: 10px;
                }
              }
              .otherdiv{
                height: 112.8px;
                div{
                  line-height: 72.6px;
                  span{
                    display: inline-block;
                    padding-left: 10px;
                    color: #1662ec;
                  }
                }
              }
              .otherdiv1{
                 height: 112.8px;
               
                 div{
                   line-height: 112.8px;
                    height: 112.8px;
                      color: #ec163e;
                   span{
                     display: inline-block;
                    width: 60px;
                    height: 20px;
                    background-color: #ec163e;
                    border-radius: 2px;
                    margin-left: 10px;
                   }
                 }
              }
           }
         }
         .tab-row-flex-bot {
            width: 100%;
	         height: 44px;
           margin-top: 10px;
           line-height: 44px;
           border: 1px solid $base-rebrder-color;
            box-sizing: border-box;
           div{
             width: 110px;
             text-align: center;
             color:#0a4c62;
             font-size: 15px;
               border-right: 1px solid $base-rebrder-color;
                box-sizing: border-box;
           }
           div:last-child{
              border-right:0;
              width: 57px;
           }
           .defultcolor{
             width: 55px;
             color: #1082a8;
            
           }
         }
     }
 }
</style>