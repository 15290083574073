<template>
  <div
    class="Jrindex"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="系统正在初始化"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.6)"
    style="height: 100%"
  >
    <div class="jrindex-conitaner" :style="scaleHeight" ref="bigbox">
      <div class="maskbok" v-if="maskbok"></div>
      <div class="title">{{ projectData.project }}加热炉燃烧优化控制系统</div>
      <div class="Jrindex-tit">
        <div class="Jrindex-tit-top flex">
          <div class="text-row flex">
            <div>煤气总流量</div>
            <div
              class="ma-auto"
              @click="
                toCompon(
                  2,
                  'FICA_117_B',
                  'G_SOUREC',
                  'FICA_117_J1',
                  '煤气总管流量'
                )
              "
              @dblclick="
                Cclick(
                  infoList.G_SOUREC.FICA_117_B,
                  'FICA_117_B',
                  'G_SOUREC',
                  'FICA_117_J1'
                )
              "
            >
              {{ infoList.G_SOUREC.FICA_117_B }} m³/h
            </div>
          </div>
          <div class="text-row flex">
            <div>入炉钢坯数量(PLC)</div>
            <div
              class="ma-auto"
              @click="
                toCompon(2, 'CGNO_B', 'G_SOUREC', 'GPRLNUM_J1', '入炉板坯数量')
              "
              @dblclick="
                Cclick(
                  infoList.G_SOUREC.CGNO_B,
                  'CGNO_B',
                  'G_SOUREC',
                  'GPRLNUM_J1'
                )
              "
            >
              {{ infoList.G_SOUREC.CGNO_B }} 根
            </div>
          </div>
          <div class="text-row flex">
            <div>入炉钢坯数量(BCS)</div>
            <div
              class="ma-auto"
              @click="
                toCompon(2, 'KHGGLJB', 'G_KHTJ', 'KHGGLJB_J1', '过钢量班累积')
              "
              @dblclick="
                Cclick(
                  infoList.G_KHTJ.KHGGLJB,
                  'KHGGLJB',
                  'G_KHTJ',
                  'KHGGLJB_J1'
                )
              "
            >
              {{ infoList.G_KHTJ.KHGGLJB }} 根
            </div>
          </div>
          <div class="text-row flex">
            <div>入炉钢温</div>
            <div
              class="ma-auto"
              @click="toCompon(2, 'RGT_B', 'G_SOUREC', 'RGT_J1', '入钢温度')"
              @dblclick="
                Cclick(infoList.G_SOUREC.RGT_B, 'RGT_B', 'G_SOUREC', 'RGT_J1')
              "
            >
              {{ infoList.G_SOUREC.RGT_B }} ℃
            </div>
          </div>
          <div class="text-row flex">
            <div>开轧温度(PLC)</div>
            <div
              class="ma-auto"
              @click="toCompon(2, 'CGT_B', 'G_SOUREC', 'CGT_J1', '开轧温度')"
              @dblclick="
                Cclick(infoList.G_SOUREC.CGT_B, 'CGT_B', 'G_SOUREC', 'CGT_J1')
              "
            >
              {{ infoList.G_SOUREC.CGT_B }} ℃
            </div>
          </div>
          <div class="text-row flex align-cen">
            <div>开轧温度(BCS)</div>
            <div
              class="ma-auto"
              @click="toCompon(2, 'CGT_B', 'G_SOUREC', 'CGT_J1', '开轧温度')"
              @dblclick="
                Cclick(infoList.G_SOUREC.CGT_B, 'CGT_B', 'G_SOUREC', 'CGT_J1')
              "
            >
              {{ infoList.G_SOUREC.CGT_B }} ℃
            </div>
            <div
              class="child-dataR child-databtn ma-auto"
              :style="{
                background:
                  infoList.G_MCSOTHER__p__RSF2 &&
                  !infoList.G_MCSOTHER__p__RSF2.TS
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.G_MCSOTHER__p__RSF2
                  ? toDetail(
                      3,
                      'RSF2',
                      'G_MCSOTHER__p__RSF2',
                      '',
                      '出钢温度软伺服'
                    )
                  : ''
              "
            >
              R
            </div>
          </div>
        </div>
        <div class="Jrindex-tit-bot">
          <span @click="toCompon(6)">钢损模型</span>
          <span
            @click="
              toCompon(
                2,
                'GSM',
                'G_KHTJ__p__GSMX1',
                'GSMX_GSM_J1',
                '钢损模型氧化烧损量'
              )
            "
            @dblclick="
              Cclick(
                infoList.G_KHTJ__p__GSMX1.GSM,
                'GSM',
                'G_KHTJ__p__GSMX1',
                'GSMX_GSM_J1'
              )
            "
            >{{ infoList.G_KHTJ__p__GSMX1.GSM }}%</span
          >
        </div>
        <!-- <div class="canshu canshu1 fl" @click="qsGroup()">趋势组图</div> -->
        <!-- <div class="canshu canshu2 fl" @click="qsGroup1()">常用设置</div> -->
      </div>
      <div class="el-container-box" :style="conHeight">
        <div class="el-container-box-cen">
          <!-- 第一部分 -->
          <div class="el-container-box-cen-coulm1">
            <div class="coulm1-top flex align-cen">
              <div
                class="child-dataX child-databtn"
                :style="{
                  background:
                    infoList.G_MCSMAN__p__MAN13 &&
                    infoList.G_MCSMAN__p__MAN13.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.G_MCSMAN__p__MAN13
                    ? toCompon(
                        0,
                        'AV',
                        'G_MCSMAN__p__MAN13',
                        'MAN13_AV_J1',
                        '空烟气总管阀BCS手操器MAN13'
                      )
                    : ''
                "
              >
                A
              </div>
              <img
                src="../../assets/images/rts_jrlimg.png"
                alt=""
                class="jrlimg"
                @click="toCompon(3)"
                style="cursor: pointer"
              />
              <div class="line"></div>
              <img
                src="../../assets/images/rts_jrlimg1.png"
                alt=""
                class="jflimg1"
              />
              <div class="jrl-row flex">
                <div class="line1"></div>
                <div class="fam1">
                  <img
                    src="../../assets/images/jflimg2.png"
                    alt=""
                    class="jflimg2"
                  />
                  <p
                    @click="
                      toCompon(
                        2,
                        'KF_113_B',
                        'G_SOUREC',
                        'KF_113_J1',
                        '空烟气总管阀反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.G_SOUREC.KF_113_B,
                        'KF_113_B',
                        'G_SOUREC',
                        'KF_113_J1'
                      )
                    "
                  >
                    {{ infoList.G_SOUREC.KF_113_B }}%
                  </p>
                </div>
                <div class="jrl_auto flex">
                  <div class="list-auto1 flex">
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.YJRKPTSP,
                            '修改',
                            'YJRKPTSP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.YJRKPTSP }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T42PV1',
                            'G_TEMP',
                            'T42PV1_J1',
                            '一加热空烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T42PV1,
                            'T42PV1',
                            'G_TEMP',
                            'T42PV1_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T42PV1 }}℃
                      </div>
                    </div>
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.YJRMPTSP,
                            '修改',
                            'YJRMPTSP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.YJRMPTSP }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T42PV2',
                            'G_TEMP',
                            'T42PV2_J1',
                            '一加热段煤烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T42PV2,
                            'T42PV2',
                            'G_TEMP',
                            'T42PV2_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T42PV2 }}℃
                      </div>
                    </div>
                    <div class="text2 flex align-cen">
                      <div class="text">烟气含氧量</div>
                      <div
                        style="color: rgb(0, 180, 255); padding-left: 2vw"
                        @click="
                          toIpt(infoList.G_PFJ.O2SP, '修改', 'O2SP', 'G_PFJ')
                        "
                      >
                        {{ infoList.G_PFJ.O2SP }}%
                      </div>
                      <div
                        style="color: #d9feff; padding-left: 1vw"
                        @click="
                          toCompon(
                            2,
                            'CO2_B',
                            'G_SOUREC',
                            'CO2_J1',
                            '烟气含氧量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.CO2_B,
                            'CO2_B',
                            'G_SOUREC',
                            'CO2_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.CO2_B }}%
                      </div>
                      <div
                        class="child-dataR child-databtn"
                        :style="{
                          background:
                            infoList.G_MCSOTHER__p__RSF5 &&
                            !infoList.G_MCSOTHER__p__RSF5.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.G_MCSOTHER__p__RSF5
                            ? toDetail(
                                3,
                                'RSF5',
                                'G_MCSOTHER__p__RSF5',
                                '',
                                '备用'
                              )
                            : ''
                        "
                      >
                        R
                      </div>
                    </div>
                  </div>
                  <div class="list-auto1 flex">
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.EJRKPTSP,
                            '修改',
                            'EJRKPTSP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.EJRKPTSP }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T32PV1',
                            'G_TEMP',
                            'T32PV1_J1',
                            '二加热段空烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T32PV1,
                            'T32PV1',
                            'G_TEMP',
                            'T32PV1_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T32PV1 }}℃
                      </div>
                    </div>
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.EJRMPTSP,
                            '修改',
                            'EJRMPTSP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.EJRMPTSP }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T32PV2',
                            'G_TEMP',
                            'T32PV2_J1',
                            '二加热段煤烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T32PV2,
                            'T32PV2',
                            'G_TEMP',
                            'T32PV2_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T32PV2 }}℃
                      </div>
                    </div>
                  </div>
                  <div class="list-auto1 flex">
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.SJRKPTSP,
                            '修改',
                            'SJRKPTSP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.SJRKPTSP }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T22PV1',
                            'G_TEMP',
                            'T22PV1_J1',
                            '三加热段空烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T22PV1,
                            'T22PV1',
                            'G_TEMP',
                            'T22PV1_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T22PV1 }}℃
                      </div>
                    </div>
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.SJRMPTSP,
                            '修改',
                            'SJRMPTSP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.SJRMPTSP }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T22PV2',
                            'G_TEMP',
                            'T22PV2_J1',
                            '三加热段煤烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T22PV2,
                            'T22PV2',
                            'G_TEMP',
                            'T22PV2_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T22PV2 }}℃
                      </div>
                    </div>
                    <div class="text2 th-text2 flex align-cen">
                      <div
                        style="
                          color: rgb(0, 180, 255);
                          position: absolute;
                          left: -6vw;
                        "
                        @click="
                          toIpt(
                            infoList.G_PFJ.FYSP_L,
                            '修改',
                            'FYSP_L',
                            'G_PFJ'
                          )
                        "
                      >
                        {{ infoList.G_PFJ.FYSP_L }}Pa
                      </div>
                      <div
                        class="th-child-dataR child-databtn"
                        :style="{
                          background:
                            infoList.G_MCSOTHER__p__RSF3 &&
                            !infoList.G_MCSOTHER__p__RSF3.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.G_MCSOTHER__p__RSF3
                            ? toDetail(
                                3,
                                'RSF3',
                                'G_MCSOTHER__p__RSF3',
                                '',
                                '负压空废煤废温度补偿'
                              )
                            : ''
                        "
                      >
                        R
                      </div>
                      <div class="th-text" @click="toCompon(10)">空烟管道</div>
                      <div class="my-text th-text" @click="toCompon(10)">
                        煤烟管道
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="thfamright flex">
                <div class="text1">
                  <div
                    style="color: rgb(0, 180, 255)"
                    @click="
                      toIpt(
                        infoList.G_TEMP.JRDKPTSP,
                        '修改',
                        'JRDKPTSP',
                        'G_TEMP'
                      )
                    "
                  >
                    {{ infoList.G_TEMP.JRDKPTSP }}℃
                  </div>
                  <div
                    @click="
                      toCompon(
                        2,
                        'T12PV1',
                        'G_TEMP',
                        'T12PV1_J1',
                        '均热段空烟温度处理值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.G_TEMP.T12PV1,
                        'T12PV1',
                        'G_TEMP',
                        'T12PV1_J1'
                      )
                    "
                  >
                    {{ infoList.G_TEMP.T12PV1 }}℃
                  </div>
                </div>
                <div class="text1">
                  <div
                    style="color: rgb(0, 180, 255)"
                    @click="
                      toIpt(
                        infoList.G_TEMP.JRDMPTSP,
                        '修改',
                        'JRDMPTSP',
                        'G_TEMP'
                      )
                    "
                  >
                    {{ infoList.G_TEMP.JRDMPTSP }}℃
                  </div>
                  <div
                    @click="
                      toCompon(
                        2,
                        'T12PV2',
                        'G_TEMP',
                        'T12PV2_J1',
                        '均热段煤烟温度处理值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.G_TEMP.T12PV2,
                        'T12PV2',
                        'G_TEMP',
                        'T12PV2_J1'
                      )
                    "
                  >
                    {{ infoList.G_TEMP.T12PV2 }}℃
                  </div>
                </div>
              </div>
            </div>
            <div class="coulm1-cen flex align-cen">
              <div
                class="child-dataX child-databtn"
                :style="{
                  background:
                    infoList.G_MCSXK__p__XK13 &&
                    infoList.G_MCSXK__p__XK13.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.G_MCSXK__p__XK13
                    ? toDetail(
                        1,
                        'XK13',
                        'G_MCSXK__p__XK13',
                        '',
                        '炉膛压力控制XK'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="child-dataR child-databtn"
                :style="{
                  background:
                    infoList.G_MCSOTHER__p__RSF1 &&
                    !infoList.G_MCSOTHER__p__RSF1.TS
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.G_MCSOTHER__p__RSF1
                    ? toDetail(
                        3,
                        'RSF1',
                        'G_MCSOTHER__p__RSF1',
                        '',
                        '空烟煤协调软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
              <div
                class="child-dataK child-databtn"
                :style="{
                  background:
                    infoList.G_MCSOTHER__p__QK1 &&
                    !infoList.G_MCSOTHER__p__QK1.TS
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.G_MCSOTHER__p__QK1
                    ? toDetail(
                        2,
                        'QK1',
                        'G_MCSOTHER__p__QK1',
                        '',
                        '空气阀门前馈'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="child-dataK child-databtn"
                :style="{
                  background:
                    infoList.G_MCSOTHER__p__QK3 &&
                    !infoList.G_MCSOTHER__p__QK3.TS
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.G_MCSOTHER__p__QK3
                    ? toDetail(
                        2,
                        'QK3',
                        'G_MCSOTHER__p__QK3',
                        '',
                        '煤气阀门前馈'
                      )
                    : ''
                "
              >
                K
              </div>
            </div>
            <div class="coulm1-bot flex align-cen">
              <div
                class="child-dataX child-databtn"
                :style="{
                  background:
                    infoList.G_MCSMAN__p__MAN14 &&
                    infoList.G_MCSMAN__p__MAN14.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.G_MCSMAN__p__MAN14
                    ? toCompon(
                        0,
                        'AV',
                        'G_MCSMAN__p__MAN14',
                        'MAN14_AV_J1',
                        '煤烟气总管阀BCS手操器MAN14'
                      )
                    : ''
                "
              >
                A
              </div>
              <img
                src="../../assets/images/rts_jrlimg.png"
                alt=""
                class="jrlimg"
                @click="toCompon(3)"
                style="cursor: pointer"
              />
              <div class="bot-wiidth flex">
                <div class="coulm1-bot-line"></div>
                <img
                  src="../../assets/images/rts_jrlimg1.png"
                  alt=""
                  class="coulm1-bot-img1"
                />
                <div class="coulm1-bot-line1"></div>
                <div class="coulm1-bot-line2"></div>
                <div
                  class="pore"
                  @click="
                    toCompon(
                      2,
                      'KF_114_B',
                      'G_SOUREC',
                      'KF_114_J1',
                      '煤烟气总管阀反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.G_SOUREC.KF_114_B,
                      'KF_114_B',
                      'G_SOUREC',
                      'KF_114_J1'
                    )
                  "
                >
                  {{ infoList.G_SOUREC.KF_114_B }}%
                </div>
                <div class="coulm1-bot-right flex">
                  <div class="fam1">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="jflimg2"
                    />
                  </div>
                  <div class="famright flex">
                    <div class="famright-list flex">
                      <div class="famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK15 &&
                                !infoList.G_MCSOTHER__p__QK15.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK15
                                ? toDetail(
                                    2,
                                    'QK15',
                                    'G_MCSOTHER__p__QK15',
                                    '',
                                    '一加热空烟空气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK12 &&
                                infoList.G_MCSXK__p__XK12.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK12
                                ? toDetail(
                                    1,
                                    'XK12',
                                    'G_MCSXK__p__XK12',
                                    '',
                                    '一加热段空烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN22 &&
                                infoList.G_MCSMAN__p__MAN22.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN22
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN22',
                                    'MAN22_AV_J1',
                                    '一加热段空烟气阀门手操器MAN22'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            @click="
                              toCompon(
                                2,
                                'KF_122_B',
                                'G_SOUREC',
                                'KF_122_J1',
                                '一热段空烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_122_B,
                                'KF_122_B',
                                'G_SOUREC',
                                'KF_122_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_122_B }}%
                          </p>
                        </div>
                      </div>
                      <div class="famright-list-right famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK16 &&
                                !infoList.G_MCSOTHER__p__QK16.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK16
                                ? toDetail(
                                    2,
                                    'QK16',
                                    'G_MCSOTHER__p__QK16',
                                    '',
                                    '一加热煤烟煤气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK11 &&
                                infoList.G_MCSXK__p__XK11.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK11
                                ? toDetail(
                                    1,
                                    'XK11',
                                    'G_MCSXK__p__XK11',
                                    '',
                                    '一加热段煤烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN23 &&
                                infoList.G_MCSMAN__p__MAN23.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN23
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN23',
                                    'MAN23_AV_J1',
                                    '一加热段煤烟气阀门手操器MAN23'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            @click="
                              toCompon(
                                2,
                                'KF_123_B',
                                'G_SOUREC',
                                'KF_123_J1',
                                '一热段煤烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_123_B,
                                'KF_123_B',
                                'G_SOUREC',
                                'KF_123_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_123_B }}%
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="famright-list flex">
                      <div class="famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK13 &&
                                !infoList.G_MCSOTHER__p__QK13.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK13
                                ? toDetail(
                                    2,
                                    'QK13',
                                    'G_MCSOTHER__p__QK13',
                                    '',
                                    '二加热空烟空气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK9 &&
                                infoList.G_MCSXK__p__XK9.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK9
                                ? toDetail(
                                    1,
                                    'XK9',
                                    'G_MCSXK__p__XK9',
                                    '',
                                    '二加热段空烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN11 &&
                                infoList.G_MCSMAN__p__MAN11.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN11
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN11',
                                    'MAN11_AV_J1',
                                    '二加热段空烟气阀门手操器MAN11'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            @click="
                              toCompon(
                                2,
                                'KF_111_B',
                                'G_SOUREC',
                                'KF_111_J1',
                                '二热段空烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_111_B,
                                'KF_111_B',
                                'G_SOUREC',
                                'KF_111_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_111_B }}%
                          </p>
                        </div>
                      </div>
                      <div class="famright-list-right famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK14 &&
                                !infoList.G_MCSOTHER__p__QK14.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK14
                                ? toDetail(
                                    2,
                                    'QK14',
                                    'G_MCSOTHER__p__QK14',
                                    '',
                                    '二加热煤烟煤气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK8 &&
                                infoList.G_MCSXK__p__XK8.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK8
                                ? toDetail(
                                    1,
                                    'XK8',
                                    'G_MCSXK__p__XK8',
                                    '',
                                    '二加热段煤烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN12 &&
                                infoList.G_MCSMAN__p__MAN12.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN12
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN12',
                                    'MAN12_AV_J1',
                                    '二加热段煤烟气阀门手操器MAN12'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            @click="
                              toCompon(
                                2,
                                'KF_112_B',
                                'G_SOUREC',
                                'KF_112_J1',
                                '二热段煤烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_112_B,
                                'KF_112_B',
                                'G_SOUREC',
                                'KF_112_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_112_B }}%
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="famright-list flex">
                      <div class="famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK11 &&
                                !infoList.G_MCSOTHER__p__QK11.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK11
                                ? toDetail(
                                    2,
                                    'QK11',
                                    'G_MCSOTHER__p__QK11',
                                    '',
                                    '三加热空烟空气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK6 &&
                                infoList.G_MCSXK__p__XK6.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK6
                                ? toDetail(
                                    1,
                                    'XK6',
                                    'G_MCSXK__p__XK6',
                                    '',
                                    '三加热段空烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN7 &&
                                infoList.G_MCSMAN__p__MAN7.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN7
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN7',
                                    'MAN7_AV_J1',
                                    '三加热段空烟气阀门手操器MAN7'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2 jflimgpo2"
                          />
                          <p
                            class="jflimgpop"
                            @click="
                              toCompon(
                                2,
                                'KF_107_B',
                                'G_SOUREC',
                                'KF_107_J1',
                                '三热段空烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_107_B,
                                'KF_107_B',
                                'G_SOUREC',
                                'KF_107_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_107_B }}%
                          </p>
                        </div>
                      </div>
                      <div class="famright-list-right famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK12 &&
                                !infoList.G_MCSOTHER__p__QK12.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK12
                                ? toDetail(
                                    2,
                                    'QK12',
                                    'G_MCSOTHER__p__QK12',
                                    '',
                                    '三加热煤烟煤气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK5 &&
                                infoList.G_MCSXK__p__XK5.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK5
                                ? toDetail(
                                    1,
                                    'XK5',
                                    'G_MCSXK__p__XK5',
                                    '',
                                    '三加热段煤烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN8 &&
                                infoList.G_MCSMAN__p__MAN8.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN8
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN8',
                                    'MAN8_AV_J1',
                                    '三加热段煤烟气阀门手操器MAN8'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            class=""
                            @click="
                              toCompon(
                                2,
                                'KF_108_B',
                                'G_SOUREC',
                                'KF_108_J1',
                                '三热段煤烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_108_B,
                                'KF_108_B',
                                'G_SOUREC',
                                'KF_108_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_108_B }}%
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="thfamright1">
                      <div class="btnflex flex">
                        <div
                          class="child-dataK child-databtn"
                          :style="{
                            background:
                              infoList.G_MCSOTHER__p__QK9 &&
                              !infoList.G_MCSOTHER__p__QK9.TS
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.G_MCSOTHER__p__QK9
                              ? toDetail(
                                  2,
                                  'QK9',
                                  'G_MCSOTHER__p__QK9',
                                  '',
                                  '均热段空烟空气阀门前馈'
                                )
                              : ''
                          "
                        >
                          K
                        </div>
                        <div
                          class="child-dataX child-databtn"
                          :style="{
                            background:
                              infoList.G_MCSXK__p__XK3 &&
                              infoList.G_MCSXK__p__XK3.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.G_MCSXK__p__XK3
                              ? toDetail(
                                  1,
                                  'XK3',
                                  'G_MCSXK__p__XK3',
                                  '',
                                  '均热段空烟温度控制XK'
                                )
                              : ''
                          "
                        >
                          X
                        </div>
                        <div
                          class="child-dataA child-databtn"
                          :style="{
                            background:
                              infoList.G_MCSMAN__p__MAN3 &&
                              infoList.G_MCSMAN__p__MAN3.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.G_MCSMAN__p__MAN3
                              ? toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN3',
                                  'MAN3_AV_J1',
                                  '均热段空烟气阀门手操器MAN3'
                                )
                              : ''
                          "
                        >
                          A
                        </div>
                      </div>
                      <div class="text3 flex">
                        <img
                          src="../../assets/images/jflimg2.png"
                          alt=""
                          class="jflimg2"
                        />
                        <p
                          class=""
                          @click="
                            toCompon(
                              2,
                              'KF_103_B',
                              'G_SOUREC',
                              'KF_103_J1',
                              '均热段空烟气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.KF_103_B,
                              'KF_103_B',
                              'G_SOUREC',
                              'KF_103_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.KF_103_B }}%
                        </p>
                      </div>
                      <div class="thfamright2">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK10 &&
                                !infoList.G_MCSOTHER__p__QK10.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK10
                                ? toDetail(
                                    2,
                                    'QK10',
                                    'G_MCSOTHER__p__QK10',
                                    '',
                                    '均热段煤烟煤气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK2 &&
                                infoList.G_MCSXK__p__XK2.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK2
                                ? toDetail(
                                    1,
                                    'XK2',
                                    'G_MCSXK__p__XK2',
                                    '',
                                    '均热段煤烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN4 &&
                                infoList.G_MCSMAN__p__MAN4.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN4
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN4',
                                    'MAN4_AV_J1',
                                    '均热段煤烟气阀门手操器MAN4'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            class=""
                            @click="
                              toCompon(
                                2,
                                'KF_104_B',
                                'G_SOUREC',
                                'KF_104_J1',
                                '均热段煤烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_104_B,
                                'KF_104_B',
                                'G_SOUREC',
                                'KF_104_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_104_B }}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 第二部分 -->
          <div class="el-container-box-cen-coulm2">
            <div class="coulm2-box flex align-cen">
              <div class="coulm2-box-left">
                <p>生产模式切换</p>
                <div
                  :class="infoList.G_SOUREC.MENLU == 2 ? 'active' : ''"
                  @click="chaneStu(2, 'MENLU', 'G_SOUREC')"
                >
                  闷炉
                </div>
                <div
                  :class="infoList.G_SOUREC.MENLU == 3 ? 'active' : ''"
                  @click="chaneStu(3, 'MENLU', 'G_SOUREC')"
                >
                  长时间闷炉
                </div>
                <div
                  :class="infoList.G_SOUREC.MENLU == 4 ? 'active' : ''"
                  @click="chaneStu(4, 'MENLU', 'G_SOUREC')"
                >
                  正常烧炉
                </div>
              </div>
              <div class="coulm2-box-right flex">
                <div class="right-list">
                  <img
                    src="../../assets/images/jrlimg3.png"
                    alt=""
                    class="jrlimg3"
                  />
                  <img
                    src="../../assets/images/jrlimg4.png"
                    alt=""
                    class="jrlimg4"
                  />
                  <div class="coulm2-text1 flex">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_108A_B',
                          'G_SOUREC',
                          'TICA_108A_J1',
                          '一加热段空烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_108A_B,
                          'TICA_108A_B',
                          'G_SOUREC',
                          'TICA_108A_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_108A_B }}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_108B_B',
                          'G_SOUREC',
                          'TICA_108B_J1',
                          '一加热段煤烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_108B_B,
                          'TICA_108B_B',
                          'G_SOUREC',
                          'TICA_108B_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_108B_B }}℃
                    </div>
                  </div>
                  <div class="coulm2-text2 flex align-cen justify-content-cen">
                    <div class="coulm2-text2-row1">
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_107A_B',
                            'G_SOUREC',
                            'TICA_107A_J1',
                            '一加热段左侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.TICA_107A_B,
                            'TICA_107A_B',
                            'G_SOUREC',
                            'TICA_107A_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.TICA_107A_B }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_107B_B',
                            'G_SOUREC',
                            'TICA_107B_J1',
                            '一加热段右侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.TICA_107B_B,
                            'TICA_107B_B',
                            'G_SOUREC',
                            'TICA_107B_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.TICA_107B_B }}℃
                      </div>
                    </div>
                    <div class="coulm2-text2-row2" @click="toCompon(9, 1)">
                      <div>一加热段</div>
                    </div>
                    <div class="coulm2-text2-row3">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.T41SP,
                            '一加热段设定值',
                            'T41SP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T41SP }}℃
                      </div>
                      <div
                        @click="toCompon(2, 'T41SPSJ', 'G_TEMP', 'T41SPSJ_J1')"
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T41SPSJ,
                            'T41SPSJ',
                            'G_TEMP',
                            'T41SPSJ_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T41SPSJ }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T41PV',
                            'G_TEMP',
                            'T41PV_J1',
                            '一加热段上部温度均值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T41PV,
                            'T41PV',
                            'G_TEMP',
                            'T41PV_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T41PV }}℃
                      </div>
                    </div>
                  </div>
                  <div class="coulm2-text1 flex">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_108C_B',
                          'G_SOUREC',
                          'TICA_108C_J1',
                          '一加热段空烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_108C_B,
                          'TICA_108C_B',
                          'G_SOUREC',
                          'TICA_108C_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_108C_B }}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_108D_B',
                          'G_SOUREC',
                          'TICA_108D_J1',
                          '一加热段煤烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_108D_B,
                          'TICA_108D_B',
                          'G_SOUREC',
                          'TICA_108D_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_108D_B }}℃
                    </div>
                  </div>
                  <div
                    class="coulm2-text3"
                    @click="
                      toCompon(
                        2,
                        'YJRHXT_B',
                        'G_SOUREC',
                        'YJRHXT_J1',
                        '一加热换向时间'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.G_SOUREC.YJRHXT_B,
                        'YJRHXT_B',
                        'G_SOUREC',
                        'YJRHXT_J1'
                      )
                    "
                  >
                    {{ infoList.G_SOUREC.YJRHXT_B }}S
                  </div>
                  <div class="coulm2-line1"></div>
                  <div class="coulm2-line2"></div>
                </div>
                <div class="right-list">
                  <img
                    src="../../assets/images/jrlimg3.png"
                    alt=""
                    class="jrlimg3"
                  />
                  <img
                    src="../../assets/images/jrlimg4.png"
                    alt=""
                    class="jrlimg4"
                  />
                  <div class="coulm2-text1 flex">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_106A_B',
                          'G_SOUREC',
                          'TICA_106A_J1',
                          '二加热段空烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_106A_B,
                          'TICA_106A_B',
                          'G_SOUREC',
                          'TICA_106A_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_106A_B }}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_106B_B',
                          'G_SOUREC',
                          'TICA_106B_J1',
                          '二加热段煤烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_106B_B,
                          'TICA_106B_B',
                          'G_SOUREC',
                          'TICA_106B_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_106B_B }}℃
                    </div>
                  </div>
                  <div class="coulm2-text2 flex align-cen justify-content-cen">
                    <div class="coulm2-text2-row1">
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_105A_B',
                            'G_SOUREC',
                            'TICA_105A_J1',
                            '二加热段左侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.TICA_105A_B,
                            'TICA_105A_B',
                            'G_SOUREC',
                            'TICA_105A_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.TICA_105A_B }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_105B_B',
                            'G_SOUREC',
                            'TICA_105B_J1',
                            '二加热段右侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.TICA_105B_B,
                            'TICA_105B_B',
                            'G_SOUREC',
                            'TICA_105B_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.TICA_105B_B }}℃
                      </div>
                    </div>
                    <div class="coulm2-text2-row2" @click="toCompon(9, 2)">
                      <div>二加热段</div>
                    </div>
                    <div class="coulm2-text2-row3">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.T31SP,
                            '二加热段设定值',
                            'T31SP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T31SP }}℃
                      </div>
                      <div
                        @click="toCompon(2, 'T31SPSJ', 'G_TEMP', 'T31SPSJ_J1')"
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T31SPSJ,
                            'T31SPSJ',
                            'G_TEMP',
                            'T31SPSJ_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T31SPSJ }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T31PV',
                            'G_TEMP',
                            'T31PV_J1',
                            '二加热段上部温度均值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T31PV,
                            'T31PV',
                            'G_TEMP',
                            'T31PV_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T31PV }}℃
                      </div>
                    </div>

                    <div class="coulm2-line1"></div>
                    <div class="coulm2-line2"></div>
                  </div>
                  <div class="coulm2-text1 flex">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_106C_B',
                          'G_SOUREC',
                          'TICA_106C_J1',
                          '二加热段空烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_106C_B,
                          'TICA_106C_B',
                          'G_SOUREC',
                          'TICA_106C_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_106C_B }}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_106D_B',
                          'G_SOUREC',
                          'TICA_106D_J1',
                          '二加热段煤烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_106D_B,
                          'TICA_106D_B',
                          'G_SOUREC',
                          'TICA_106D_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_106D_B }}℃
                    </div>
                  </div>
                  <div
                    class="coulm2-text3"
                    @click="
                      toCompon(
                        2,
                        'EJRHXT_B',
                        'G_SOUREC',
                        'EJRHXT_J1',
                        '二加热换向时间'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.G_SOUREC.EJRHXT_B,
                        'EJRHXT_B',
                        'G_SOUREC',
                        'EJRHXT_J1'
                      )
                    "
                  >
                    {{ infoList.G_SOUREC.EJRHXT_B }}S
                  </div>
                </div>
                <div class="right-list">
                  <img
                    src="../../assets/images/jrlimg3.png"
                    alt=""
                    class="jrlimg3"
                  />
                  <img
                    src="../../assets/images/jrlimg4.png"
                    alt=""
                    class="jrlimg4"
                  />
                  <div class="coulm2-text1 flex" style="padding-left: 0.7vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_104A_B',
                          'G_SOUREC',
                          'TICA_104A_J1',
                          '三加热段空烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_104A_B,
                          'TICA_104A_B',
                          'G_SOUREC',
                          'TICA_104A_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_104A_B }}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_104B_B',
                          'G_SOUREC',
                          'TICA_104B_J1',
                          '三加热段煤烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_104B_B,
                          'TICA_104B_B',
                          'G_SOUREC',
                          'TICA_104B_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_104B_B }}℃
                    </div>
                  </div>
                  <div class="coulm2-text2 flex align-cen justify-content-cen">
                    <div class="coulm2-text2-row1">
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_103A_B',
                            'G_SOUREC',
                            'TICA_103A_J1',
                            '三加热段左侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.TICA_103A_B,
                            'TICA_103A_B',
                            'G_SOUREC',
                            'TICA_103A_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.TICA_103A_B }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_103B_B',
                            'G_SOUREC',
                            'TICA_103B_J1',
                            '三加热段右侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.TICA_103B_B,
                            'TICA_103B_B',
                            'G_SOUREC',
                            'TICA_103B_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.TICA_103B_B }}℃
                      </div>
                    </div>
                    <div class="coulm2-text2-row2" @click="toCompon(9, 3)">
                      <div>三加热段</div>
                    </div>
                    <div class="coulm2-text2-row3">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_TEMP.T21SP,
                            '三加热段设定值',
                            'T21SP',
                            'G_TEMP'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T21SP }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T21SPSJ',
                            'G_TEMP',
                            'T21SPSJ_J1',
                            '实际温度控制点'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T21SPSJ,
                            'T21SPSJ',
                            'G_TEMP',
                            'T21SPSJ_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T21SPSJ }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T21PV',
                            'G_TEMP',
                            'T21PV_J1',
                            '三加热段温度均值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_TEMP.T21PV,
                            'T21PV',
                            'G_TEMP',
                            'T21PV_J1'
                          )
                        "
                      >
                        {{ infoList.G_TEMP.T21PV }}℃
                      </div>
                    </div>

                    <div class="coulm2-line1"></div>
                    <div class="coulm2-line2"></div>
                  </div>
                  <div class="coulm2-text1 flex" style="padding-left: 0.5vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_104C_B',
                          'G_SOUREC',
                          'TICA_104C_J1',
                          '三加热段空烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_104C_B,
                          'TICA_104C_B',
                          'G_SOUREC',
                          'TICA_104C_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_104C_B }}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_104D_B',
                          'G_SOUREC',
                          'TICA_104D_J1',
                          '三加热段煤烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_104D_B,
                          'TICA_104D_B',
                          'G_SOUREC',
                          'TICA_104D_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_104D_B }}℃
                    </div>
                  </div>
                  <div
                    class="coulm2-text3"
                    @click="
                      toCompon(
                        2,
                        'SJRHXT_B',
                        'G_SOUREC',
                        'SJRHXT_J1',
                        '三加热换向时间'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.G_SOUREC.SJRHXT_B,
                        'SJRHXT_B',
                        'G_SOUREC',
                        'SJRHXT_J1'
                      )
                    "
                  >
                    {{ infoList.G_SOUREC.SJRHXT_B }}S
                  </div>
                </div>
                <div class="right-list">
                  <img
                    src="../../assets/images/jrlimg3.png"
                    alt=""
                    class="jrlimg3"
                  />
                  <img
                    src="../../assets/images/jrlimg4.png"
                    alt=""
                    class="jrlimg4"
                  />
                  <div class="coulm2-text1 flex" style="padding-left: 0.7vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_102A_B',
                          'G_SOUREC',
                          'TICA_102A_J1',
                          '均热段空烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_102A_B,
                          'TICA_102A_B',
                          'G_SOUREC',
                          'TICA_102A_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_102A_B }}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_102B_B',
                          'G_SOUREC',
                          'TICA_102B_J1',
                          '均热段煤烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_102B_B,
                          'TICA_102B_B',
                          'G_SOUREC',
                          'TICA_102B_J1'
                        )
                      "
                    >
                      {{ infoList.G_SOUREC.TICA_102B_B }}℃
                    </div>
                  </div>
                  <div class="coulm2-text2 flex align-cen">
                    <div class="coulm2-text2-row1" style="padding: 1vw">
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_101A_B',
                            'G_SOUREC',
                            'TICA_101A_J1',
                            '均热段左侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.TICA_101A_B,
                            'TICA_101A_B',
                            'G_SOUREC',
                            'TICA_101A_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.TICA_101A_B }}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_101B_B',
                            'G_SOUREC',
                            'TICA_101B_J1',
                            '均热段右侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.TICA_101B_B,
                            'TICA_101B_B',
                            'G_SOUREC',
                            'TICA_101B_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.TICA_101B_B }}℃
                      </div>
                    </div>
                    <div
                      class="coulm2-text2-row2"
                      style="padding-left: 1vw; padding-right: 0"
                    >
                      <div @click="toCompon(9, 4)">均热段</div>
                    </div>
                    <div
                      class="coulm2-text2-row3 flex"
                      style="
                        position: absolute;
                        font-size: 0.8vw;
                        right: -0.7vw;
                        top: 4vh;
                      "
                    >
                      <div class="flex-row" style="padding-right: 2vw">
                        <div
                          style="color: rgb(0, 180, 255)"
                          @click="
                            toIpt(
                              infoList.G_TEMP.T11SP,
                              '均热段设定值',
                              'T11SP',
                              'G_TEMP'
                            )
                          "
                        >
                          {{ infoList.G_TEMP.T11SP }}℃
                        </div>
                        <div
                          @click="
                            toCompon(
                              2,
                              'T11SPSJ',
                              'G_TEMP',
                              'T11SPSJ_J1',
                              '实际温度控制点'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_TEMP.T11SPSJ,
                              'T11SPSJ',
                              'G_TEMP',
                              'T11SPSJ_J1'
                            )
                          "
                        >
                          {{ infoList.G_TEMP.T11SPSJ }}℃
                        </div>
                        <div
                          @click="
                            toCompon(
                              2,
                              'T11PV',
                              'G_TEMP',
                              'T11PV_J1',
                              '均热段上部温度均值'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_TEMP.T11PV,
                              'T11PV',
                              'G_TEMP',
                              'T11PV_J1'
                            )
                          "
                        >
                          {{ infoList.G_TEMP.T11PV }}℃
                        </div>
                      </div>
                      <div class="flex-row">
                        <div
                          style="color: rgb(0, 180, 255)"
                          @click="
                            toIpt(
                              infoList.G_PFJ.FYSP,
                              '负压控制点',
                              'FYSP',
                              'G_PFJ'
                            )
                          "
                        >
                          {{ infoList.G_PFJ.FYSP }}Pa
                        </div>
                        <div
                          @click="
                            toCompon(
                              2,
                              'PV',
                              'G_MCSXK__p__XK13',
                              'XK13_PV_J1',
                              '炉膛压力控制XK'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_MCSXK__p__XK13.PV,
                              'PV',
                              'G_MCSXK__p__XK13',
                              'XK13_PV_J1'
                            )
                          "
                        >
                          {{ infoList.G_MCSXK__p__XK13.PV }}Pa
                        </div>

                        <div
                          @click="
                            toCompon(
                              2,
                              'PICA_101_B',
                              'G_SOUREC',
                              'PICA_101_J1',
                              '炉膛压力'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.PICA_101_B,
                              'PICA_101_B',
                              'G_SOUREC',
                              'PICA_101_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.PICA_101_B }}℃
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coulm2-text1 flex" style="padding-left: 0.7vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_102C_B',
                          'G_SOUREC',
                          'TICA_102C_J1',
                          '均热段空烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_102C_B,
                          'TICA_102C_B',
                          'G_SOUREC',
                          'TICA_102C_J1'
                        )
                      "
                      style="padding: 0 0.5vw"
                    >
                      {{ infoList.G_SOUREC.TICA_102C_B }}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_102D_B',
                          'G_SOUREC',
                          'TICA_102D_J1',
                          '均热段煤烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.G_SOUREC.TICA_102D_B,
                          'TICA_102D_B',
                          'G_SOUREC',
                          'TICA_102D_J1'
                        )
                      "
                      style="padding: 0 0.5vw"
                    >
                      {{ infoList.G_SOUREC.TICA_102D_B }}℃
                    </div>
                  </div>
                  <div
                    class="coulm2-text3"
                    style="left: 7.5vw"
                    @click="
                      toCompon(
                        2,
                        'JRDHXT_B',
                        'G_SOUREC',
                        'JRDHXT_J1',
                        '均热段换向时间'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.G_SOUREC.JRDHXT_B,
                        'JRDHXT_B',
                        'G_SOUREC',
                        'JRDHXT_J1'
                      )
                    "
                  >
                    {{ infoList.G_SOUREC.JRDHXT_B }}S
                  </div>
                  <div class="coulm2-line1"></div>
                  <div class="coulm2-line2"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 第三部分 -->
          <div class="el-container-box-cen-coulm3">
            <div class="coulm3-box flex align-cen">
              <div class="coulm3-box-left">
                <div
                  class="left-text"
                  style="color: rgb(0, 180, 255)"
                  @click="
                    toIpt(
                      infoList.G_PFJ.PKQ_SP1,
                      '空气总管压力设定值',
                      'PKQ_SP1',
                      'G_PFJ'
                    )
                  "
                >
                  {{ infoList.G_PFJ.PKQ_SP1 }} KPa
                </div>
                <div
                  class="left-text"
                  @click="
                    toCompon(
                      2,
                      'PIA_101_B',
                      'G_SOUREC',
                      'PIA_101_J1',
                      '均热段空气压力'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.G_SOUREC.PIA_101_B,
                      'PIA_101_B',
                      'G_SOUREC',
                      'PIA_101_J1'
                    )
                  "
                >
                  {{ infoList.G_SOUREC.PIA_101_B }} KPa
                </div>
                <div class="left1 flex align-cen">
                  <div class="line">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="line-jflimg2"
                    />
                  </div>
                  <img
                    src="../../assets/images/rts_jrlimg1.png"
                    alt=""
                    class="left1-jflimg1"
                  />
                  <div class="line1"></div>
                  <div class="left-btn">
                    <div
                      class="child-dataK child-databtn"
                      :style="{
                        background:
                          infoList.G_MCSOTHER__p__QK6 &&
                          !infoList.G_MCSOTHER__p__QK6.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.G_MCSOTHER__p__QK6
                          ? toDetail(
                              2,
                              'QK6',
                              'G_MCSOTHER__p__QK6',
                              '',
                              '鼓风机空气阀门前馈'
                            )
                          : ''
                      "
                    >
                      K
                    </div>
                    <div
                      class="child-dataX child-databtn"
                      :style="{
                        background:
                          infoList.G_MCSXK__p__XK23 &&
                          infoList.G_MCSXK__p__XK23.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.G_MCSXK__p__XK23
                          ? toDetail(
                              1,
                              'XK23',
                              'G_MCSXK__p__XK23',
                              '',
                              '空气总管压力XK'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="child-dataA child-databtn"
                      :style="{
                        background:
                          infoList.G_MCSMAN__p__MAN16 &&
                          infoList.G_MCSMAN__p__MAN16.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.G_MCSMAN__p__MAN16
                          ? toCompon(
                              0,
                              'AV',
                              'G_MCSMAN__p__MAN16',
                              'MAN16_AV_J1',
                              '均热鼓风机手操器MAN16'
                            )
                          : ''
                      "
                    >
                      A
                    </div>
                  </div>
                </div>
                <div
                  class="left-text left-text1"
                  @click="
                    toCompon(
                      2,
                      'KF_116_B',
                      'G_SOUREC',
                      'KF_116_J1',
                      '鼓风机(均热段)反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.G_SOUREC.KF_116_B,
                      'KF_116_B',
                      'G_SOUREC',
                      'KF_116_J1'
                    )
                  "
                >
                  {{ infoList.G_SOUREC.KF_116_B }}%
                </div>
                <div class="left1 left2 flex align-cen">
                  <div class="line">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="line-jflimg2"
                    />
                  </div>
                  <img
                    src="../../assets/images/rts_jrlimg1.png"
                    alt=""
                    class="left1-jflimg1"
                  />
                  <div class="line1"></div>
                  <div class="left-btn">
                    <div
                      class="child-dataX child-databtn"
                      :style="{
                        background:
                          infoList.G_MCSXK__p__XK24 &&
                          infoList.G_MCSXK__p__XK24.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.G_MCSXK__p__XK24
                          ? toDetail(
                              1,
                              'XK24',
                              'G_MCSXK__p__XK24',
                              '',
                              '空气总管压力XK'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="child-dataA child-databtn"
                      :style="{
                        background:
                          infoList.G_MCSMAN__p__MAN17 &&
                          infoList.G_MCSMAN__p__MAN17.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.G_MCSMAN__p__MAN17
                          ? toCompon(
                              0,
                              'AV',
                              'G_MCSMAN__p__MAN17',
                              'MAN17_AV_J1',
                              '三热段鼓风机手操器MAN17'
                            )
                          : ''
                      "
                    >
                      A
                    </div>
                  </div>
                </div>
                <div
                  class="left-text left-text1"
                  @click="
                    toCompon(
                      2,
                      'KF_117_B',
                      'G_SOUREC',
                      'KF_117_J1',
                      '鼓风机(三加热段)反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.G_SOUREC.KF_117_B,
                      'KF_117_B',
                      'G_SOUREC',
                      'KF_117_J1'
                    )
                  "
                >
                  {{ infoList.G_SOUREC.KF_117_B }}%
                </div>
              </div>
              <div class="line-box">
                <div class="line1"></div>
                <div class="line1 line2"></div>
              </div>
              <div class="coulm3-list">
                <div class="coulm3-list-top flex">
                  <div class="list-o">
                    <div class="list-text">
                      <div
                        class="text"
                        @click="
                          toCompon(
                            2,
                            'KF_120_B',
                            'G_SOUREC',
                            'KF_120_J1',
                            '一热段空气阀反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.KF_120_B,
                            'KF_120_B',
                            'G_SOUREC',
                            'KF_120_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.KF_120_B }}%
                      </div>
                      <div
                        class="text"
                        @click="
                          toCompon(
                            2,
                            'FICA_107_B',
                            'G_SOUREC',
                            'FICA_107_J1',
                            '一加热段空气流量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.FICA_107_B,
                            'FICA_107_B',
                            'G_SOUREC',
                            'FICA_107_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.FICA_107_B }}m³/h
                      </div>
                      <div class="btn flex">
                        <div
                          class="child-dataX child-databtn"
                          :style="{
                            background:
                              infoList.G_MCSXK__p__XK22 &&
                              infoList.G_MCSXK__p__XK22.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.G_MCSXK__p__XK22
                              ? toDetail(
                                  1,
                                  'XK22',
                                  'G_MCSXK__p__XK22',
                                  '',
                                  '一加热空气流量XK'
                                )
                              : ''
                          "
                        >
                          X
                        </div>
                        <div
                          class="child-dataA child-databtn"
                          :style="{
                            background:
                              infoList.G_MCSMAN__p__MAN20 &&
                              infoList.G_MCSMAN__p__MAN20.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.G_MCSMAN__p__MAN20
                              ? toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN20',
                                  'MAN20_AV_J1',
                                  '一热段空气阀门手操器MAN20'
                                )
                              : ''
                          "
                        >
                          A
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list flex">
                    <div class="list-row1">
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="list-row1-jflimg2"
                      />
                    </div>
                    <div class="list-row1 list-row2">
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="list-row1-jflimg2"
                      />
                      <div class="list-o1">
                        <div class="list-text">
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'KF_109_B',
                                'G_SOUREC',
                                'KF_109_J1',
                                '二热段空气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_109_B,
                                'KF_109_B',
                                'G_SOUREC',
                                'KF_109_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_109_B }}%
                          </div>
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'FICA_105_B',
                                'G_SOUREC',
                                'FICA_105_J1',
                                '二加热段空气流量'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.FICA_105_B,
                                'FICA_105_B',
                                'G_SOUREC',
                                'FICA_105_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.FICA_105_B }}m³/h
                          </div>
                          <div class="btn flex">
                            <div
                              class="child-dataX child-databtn"
                              :style="{
                                background:
                                  infoList.G_MCSXK__p__XK20 &&
                                  infoList.G_MCSXK__p__XK20.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.G_MCSXK__p__XK20
                                  ? toDetail(
                                      1,
                                      'XK20',
                                      'G_MCSXK__p__XK20',
                                      '',
                                      '二加热空气流量XK'
                                    )
                                  : ''
                              "
                            >
                              X
                            </div>
                            <div
                              class="child-dataA child-databtn"
                              :style="{
                                background:
                                  infoList.G_MCSMAN__p__MAN9 &&
                                  infoList.G_MCSMAN__p__MAN9.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.G_MCSMAN__p__MAN9
                                  ? toCompon(
                                      0,
                                      'AV',
                                      'G_MCSMAN__p__MAN9',
                                      'MAN9_AV_J1',
                                      '二热段空气阀门手操器MAN9'
                                    )
                                  : ''
                              "
                            >
                              A
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-row1 list-row2 list-row3">
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="list-row1-jflimg2"
                      />
                      <div class="list-o1">
                        <div class="list-text">
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'KF_105_B',
                                'G_SOUREC',
                                'KF_105_J1',
                                '三热段空气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_105_B,
                                'KF_105_B',
                                'G_SOUREC',
                                'KF_105_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_105_B }}%
                          </div>
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'FICA_103_B',
                                'G_SOUREC',
                                'FICA_103_J1',
                                '三加热段空气流量'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.FICA_103_B,
                                'FICA_103_B',
                                'G_SOUREC',
                                'FICA_103_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.FICA_103_B }}m³/h
                          </div>
                          <div class="btn flex">
                            <div
                              class="child-dataX child-databtn"
                              :style="{
                                background:
                                  infoList.G_MCSXK__p__XK18 &&
                                  infoList.G_MCSXK__p__XK18.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.G_MCSXK__p__XK18
                                  ? toDetail(
                                      1,
                                      'XK18',
                                      'G_MCSXK__p__XK18',
                                      '',
                                      '三加热空气流量XK'
                                    )
                                  : ''
                              "
                            >
                              X
                            </div>
                            <div
                              class="child-dataA child-databtn"
                              :style="{
                                background:
                                  infoList.G_MCSMAN__p__MAN5 &&
                                  infoList.G_MCSMAN__p__MAN5.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.G_MCSMAN__p__MAN5
                                  ? toCompon(
                                      0,
                                      'AV',
                                      'G_MCSMAN__p__MAN5',
                                      'MAN5_AV_J1',
                                      '三热段空气阀门手操器MAN5'
                                    )
                                  : ''
                              "
                            >
                              A
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="list-row2-jflimg3"
                      />
                      <div class="list-o2">
                        <div class="list-text">
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'KF_101_B',
                                'G_SOUREC',
                                'KF_101_J1',
                                '均热段空气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.KF_101_B,
                                'KF_101_B',
                                'G_SOUREC',
                                'KF_101_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.KF_101_B }}%
                          </div>
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'FICA_101_B',
                                'G_SOUREC',
                                'FICA_101_J1',
                                '均热段空气流量'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.G_SOUREC.FICA_101_B,
                                'FICA_101_B',
                                'G_SOUREC',
                                'FICA_101_J1'
                              )
                            "
                          >
                            {{ infoList.G_SOUREC.FICA_101_B }}m³/h
                          </div>
                          <div class="btn flex">
                            <div
                              class="child-dataX child-databtn"
                              :style="{
                                background:
                                  infoList.G_MCSXK__p__XK16 &&
                                  infoList.G_MCSXK__p__XK16.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.G_MCSXK__p__XK16
                                  ? toDetail(
                                      1,
                                      'XK16',
                                      'G_MCSXK__p__XK16',
                                      '',
                                      '均热空气流量XK'
                                    )
                                  : ''
                              "
                            >
                              X
                            </div>
                            <div
                              class="child-dataA child-databtn"
                              :style="{
                                background:
                                  infoList.G_MCSMAN__p__MAN1 &&
                                  infoList.G_MCSMAN__p__MAN1.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.G_MCSMAN__p__MAN1
                                  ? toCompon(
                                      0,
                                      'AV',
                                      'G_MCSMAN__p__MAN1',
                                      'MAN1_AV_J1',
                                      '均热段空气阀门手操器MAN1'
                                    )
                                  : ''
                              "
                            >
                              A
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="coulm3-list-bot flex">
                  <div class="coulm3-list-line"></div>
                  <div class="coulm3-list-bot-list flex">
                    <div class="bot-text">
                      <div class="kq" @click="toCompon(7)">空气总管</div>
                      <div class="mq" @click="toCompon(8)">煤气总管</div>
                    </div>
                    <div class="maright ma-auto">
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="ma-auto-jflimg2"
                      />
                      <div
                        class="mapos"
                        @click="
                          toCompon(
                            2,
                            'KF_124_B',
                            'G_SOUREC',
                            'KF_124_J1',
                            '煤气总管压力调节阀反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.KF_124_B,
                            'KF_124_B',
                            'G_SOUREC',
                            'KF_124_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.KF_124_B }}%
                      </div>
                      <div
                        class="mapos1"
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.G_PFJ.PMQ_SP,
                            '修改',
                            'PMQ_SP',
                            'G_PFJ'
                          )
                        "
                      >
                        {{ infoList.G_PFJ.PMQ_SP }}Kpa
                      </div>
                      <div
                        class="mapos2"
                        @click="
                          toCompon(
                            2,
                            'PIA_105_B',
                            'G_SOUREC',
                            'PIA_105_J1',
                            '煤气总管压力'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.G_SOUREC.PIA_105_B,
                            'PIA_105_B',
                            'G_SOUREC',
                            'PIA_105_J1'
                          )
                        "
                      >
                        {{ infoList.G_SOUREC.PIA_105_B }}Kpa
                      </div>
                      <div class="ma-btn flex justify-content-cen">
                        <div
                          class="child-dataK child-databtn"
                          :style="{
                            background:
                              infoList.G_MCSOTHER__p__QK7 &&
                              !infoList.G_MCSOTHER__p__QK7.TS
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.G_MCSOTHER__p__QK7
                              ? toDetail(
                                  2,
                                  'QK7',
                                  'G_MCSOTHER__p__QK7',
                                  '',
                                  '煤气总管煤气阀门前馈'
                                )
                              : ''
                          "
                        >
                          K
                        </div>
                        <div
                          class="child-dataX child-databtn"
                          :style="{
                            background:
                              infoList.G_MCSXK__p__XK14 &&
                              infoList.G_MCSXK__p__XK14.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.G_MCSXK__p__XK14
                              ? toDetail(
                                  1,
                                  'XK14',
                                  'G_MCSXK__p__XK14',
                                  '',
                                  '煤气总管压力控制XK'
                                )
                              : ''
                          "
                        >
                          X
                        </div>
                        <div
                          class="child-dataA child-databtn"
                          :style="{
                            background:
                              infoList.G_MCSMAN__p__MAN24 &&
                              infoList.G_MCSMAN__p__MAN24.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.G_MCSMAN__p__MAN24
                              ? toCompon(
                                  0,
                                  'AV',
                                  'G_MCSMAN__p__MAN24',
                                  'MAN24_AV_J1',
                                  '煤气总管压力手操器MAN24'
                                )
                              : ''
                          "
                        >
                          A
                        </div>
                      </div>
                    </div>
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="list-row1-jflimg2"
                    />
                    <div class="list-o1">
                      <div class="list-text">
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'KF_121_B',
                              'G_SOUREC',
                              'KF_121_J1',
                              '一热段煤气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.KF_121_B,
                              'KF_121_B',
                              'G_SOUREC',
                              'KF_121_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.KF_121_B }}%
                        </div>
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'FICA_108_B',
                              'G_SOUREC',
                              'FICA_108_J1',
                              '一加热段煤气流量'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.FICA_108_B,
                              'FICA_108_B',
                              'G_SOUREC',
                              'FICA_108_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.FICA_108_B }}m³/h
                        </div>
                        <div class="btn flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK17 &&
                                !infoList.G_MCSOTHER__p__QK17.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK17
                                ? toDetail(
                                    2,
                                    'QK17',
                                    'G_MCSOTHER__p__QK17',
                                    '',
                                    '一加热入炉钢温前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK10 &&
                                infoList.G_MCSXK__p__XK10.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK10
                                ? toDetail(
                                    1,
                                    'XK10',
                                    'G_MCSXK__p__XK10',
                                    '',
                                    '一加热段炉膛温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK21 &&
                                infoList.G_MCSXK__p__XK21.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK21
                                ? toDetail(
                                    1,
                                    'XK21',
                                    'G_MCSXK__p__XK21',
                                    '',
                                    '一加热煤气流量XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN21 &&
                                infoList.G_MCSMAN__p__MAN21.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN21
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN21',
                                    'MAN21_AV_J1',
                                    '一加热段煤气阀门手操器MAN21'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK21 &&
                                !infoList.G_MCSOTHER__p__QK21.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK21
                                ? toDetail(
                                    2,
                                    'QK21',
                                    'G_MCSOTHER__p__QK21',
                                    '',
                                    '一加热段煤气压力前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coulm3-list-bot-list coulm3-list-bot-list1 flex">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="list-row1-jflimg2"
                    />
                    <div class="list-o1">
                      <div class="list-text">
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'KF_110_B',
                              'G_SOUREC',
                              'KF_110_J1',
                              '二热段煤气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.KF_110_B,
                              'KF_110_B',
                              'G_SOUREC',
                              'KF_110_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.KF_110_B }}%
                        </div>
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'FICA_106_B',
                              'G_SOUREC',
                              'FICA_106_J1',
                              '二加热段煤气流量'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.FICA_106_B,
                              'FICA_106_B',
                              'G_SOUREC',
                              'FICA_106_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.FICA_106_B }}m³/h
                        </div>
                        <div class="btn flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK18 &&
                                !infoList.G_MCSOTHER__p__QK18.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK18
                                ? toDetail(
                                    2,
                                    'QK18',
                                    'G_MCSOTHER__p__QK18',
                                    '',
                                    '二加热入炉钢温前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK7 &&
                                infoList.G_MCSXK__p__XK7.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK7
                                ? toDetail(
                                    1,
                                    'XK7',
                                    'G_MCSXK__p__XK7',
                                    '',
                                    '二加热段炉膛温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK19 &&
                                infoList.G_MCSXK__p__XK19.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK19
                                ? toDetail(
                                    1,
                                    'XK19',
                                    'G_MCSXK__p__XK19',
                                    '',
                                    '二加热煤气流量XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN10 &&
                                infoList.G_MCSMAN__p__MAN10.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN10
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN10',
                                    'MAN10_AV_J1',
                                    '二加热段煤气阀门手操器MAN10'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK22 &&
                                !infoList.G_MCSOTHER__p__QK22.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK22
                                ? toDetail(
                                    2,
                                    'QK22',
                                    'G_MCSOTHER__p__QK22',
                                    '',
                                    '二加热段煤气压力前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coulm3-list-bot-list coulm3-list-bot-list1 flex">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="list-row1-jflimg2"
                    />
                    <div class="list-o1">
                      <div class="list-text">
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'KF_106_B',
                              'G_SOUREC',
                              'KF_106_J1',
                              '三热段煤气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.KF_106_B,
                              'KF_106_B',
                              'G_SOUREC',
                              'KF_106_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.KF_106_B }}%
                        </div>
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'FICA_104_B',
                              'G_SOUREC',
                              'FICA_104_J1',
                              '三加热段煤气流量'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.FICA_104_B,
                              'FICA_104_B',
                              'G_SOUREC',
                              'FICA_104_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.FICA_104_B }}m³/h
                        </div>
                        <div class="btn flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK19 &&
                                !infoList.G_MCSOTHER__p__QK19.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK19
                                ? toDetail(
                                    2,
                                    'QK19',
                                    'G_MCSOTHER__p__QK19',
                                    '',
                                    '三加热入炉钢温前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK4 &&
                                infoList.G_MCSXK__p__XK4.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK4
                                ? toDetail(
                                    1,
                                    'XK4',
                                    'G_MCSXK__p__XK4',
                                    '',
                                    '三加热段炉膛温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK17 &&
                                infoList.G_MCSXK__p__XK17.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK17
                                ? toDetail(
                                    1,
                                    'XK17',
                                    'G_MCSXK__p__XK17',
                                    '',
                                    '三加热煤气流量XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN6 &&
                                infoList.G_MCSMAN__p__MAN6.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN6
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN6',
                                    'MAN6_AV_J1',
                                    '三加热段煤气阀门手操器MAN6'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK23 &&
                                !infoList.G_MCSOTHER__p__QK23.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK23
                                ? toDetail(
                                    2,
                                    'QK23',
                                    'G_MCSOTHER__p__QK23',
                                    '',
                                    '三加热段煤气压力前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coulm3-list-bot-list coulm3-list-bot-list1 flex">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="list-row1-jflimg2"
                    />
                    <div class="list-o1">
                      <div class="list-text">
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'KF_102_B',
                              'G_SOUREC',
                              'KF_102_J1',
                              '均热段煤气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.KF_102_B,
                              'KF_102_B',
                              'G_SOUREC',
                              'KF_102_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.KF_102_B }}%
                        </div>
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'FICA_102_B',
                              'G_SOUREC',
                              'FICA_102_J1',
                              '均热段煤气流量'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.G_SOUREC.FICA_102_B,
                              'FICA_102_B',
                              'G_SOUREC',
                              'FICA_102_J1'
                            )
                          "
                        >
                          {{ infoList.G_SOUREC.FICA_102_B }}m³/h
                        </div>
                        <div class="btn flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK20 &&
                                !infoList.G_MCSOTHER__p__QK20.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK20
                                ? toDetail(
                                    2,
                                    'QK20',
                                    'G_MCSOTHER__p__QK20',
                                    '',
                                    '均热入炉钢温前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK1 &&
                                infoList.G_MCSXK__p__XK1.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK1
                                ? toDetail(
                                    1,
                                    'XK1',
                                    'G_MCSXK__p__XK1',
                                    '',
                                    '均热段炉膛温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSXK__p__XK15 &&
                                infoList.G_MCSXK__p__XK15.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSXK__p__XK15
                                ? toDetail(
                                    1,
                                    'XK15',
                                    'G_MCSXK__p__XK15',
                                    '',
                                    '均热煤气流量XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSMAN__p__MAN2 &&
                                infoList.G_MCSMAN__p__MAN2.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSMAN__p__MAN2
                                ? toCompon(
                                    0,
                                    'AV',
                                    'G_MCSMAN__p__MAN2',
                                    'MAN2_AV_J1',
                                    '均热段煤气阀门手操器MAN2'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.G_MCSOTHER__p__QK24 &&
                                !infoList.G_MCSOTHER__p__QK24.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.G_MCSOTHER__p__QK24
                                ? toDetail(
                                    2,
                                    'QK24',
                                    'G_MCSOTHER__p__QK24',
                                    '',
                                    '均热段煤气压力前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="coulm3-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <JrallModel
      v-if="alllModel"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JrallModel>
    <JRLParam01
      v-if="JRLParam01"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam01>
    <JRLParam02
      v-if="JRLParam02"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam02>
    <JRLParam03
      v-if="JRLParam03"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam03>
    <JRLParam04
      v-if="JRLParam04"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam04>
    <JRLParam05
      v-if="JRLParam05"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam05>
    <JRLcysz
      v-if="flag == 4 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLcysz>
    <JrparmsGroup
      v-if="JrparmsGroup"
      @sendStatus="isClose"
      :infoList="infoList"
      :datatype="historyname"
      :canshu1="canshu1"
    ></JrparmsGroup>
    <Jrwtdata
      v-if="Jrwtdata"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Jrwtdata>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
import JrSecurity from "@/components/JrSecurity.vue"; //加热炉安全设置组件
import JrAssessment from "@/components/JrAssessment.vue"; //运行考核组件
import Jrswitchpage from "@/components/Jrswitchpage.vue"; //切换画面组件
import JrallModel from "@/components/JrallModel.vue"; //切换画面组件
import Jrserve from "@/components/Jrserve.vue"; //客服组件
import JRLParam01 from "@/components/JRL_Param01.vue"; //参数01组件
import JRLParam02 from "@/components/JRL_kqzg.vue"; //参数02组件
import JRLParam03 from "@/components/JRL_mqzg.vue"; //参数03组件
import JrparmsGroup from "@/components/JrparmsGroup.vue"; //空气总管压力
import JRLParam04 from "@/components/JrallModel.vue"; //参数04组件
import JRLParam05 from "@/components/JRL_Param05.vue"; //参数05组件
import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
import Jrwtdata from "@/components/Jrwtdata.vue"; //数据采集组件
import Historical from "@/components/Historical.vue"; //历史趋势
import inputVal from "@/components/inputVal.vue"; //输入框组件
import { downAction } from "@/api/index/index";
export default {
  name: "Jrindex",
  components: {
    Manual,
    JrSecurity,
    JrAssessment,
    Jrswitchpage,
    JrallModel,
    Jrserve,
    JRLParam01,
    JRLParam02,
    JRLParam03,
    JRLParam04,
    JrparmsGroup,
    Jrwtdata,
    Historical,
    inputVal,
    JRLcysz,
    JRLParam05,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      JRLParam05: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      conHeight: "",
      scaleHeight: "",
      alllModel: false,
      JRLParam01: false,
      JRLParam02: false,
      JRLParam03: false,
      JRLParam04: false,
      JrparmsGroup: false,
      Jrwtdata: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  computed: {},
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));

    //   打开趋势组图
    // 获取燃烧流程接口信息
    //   this.getInfo()
    this.$bus.$on("sendMsg", (msg, msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
      switch (this.flag) {
        case 0:
          return (this.JRLcysz = true), this.$router.push({ path: "/Jrindex" });
        case 1:
          return this.$router.push({ path: "/Jryxpj" });
        case 2:
          return this.$router.push({ path: "/Jraqsz" });
        case 3:
          return this.$router.push({ path: "/Jrswitch" });
        case 4:
          if (msg1) {
            return (this.JRLcysz = msg1);
          }
      }
    });
  },
  mounted() {},
  methods: {
    //   闷炉按钮切换
    chaneStu(val, mark, node) {
      downAction({
        device: this.projectData.device,
        mark: mark,
        node: node,
        value: val,
        cp_type: this.projectData.type,
      })
        .then((res) => {
          if (res.info.code == 200) {
            this.infoList[node][mark] = res.item.value;
          } else {
          }
        })
        .catch((err) => {});
    },
    //   打开趋势组图
    qsGroup() {
      this.$router.push({ path: "/trendGroup" });
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_index/index.scss";
</style>
