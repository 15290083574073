<template>
  <div class="JrSecurity drag" v-draw>
    <div class="evaluate-header">
        安全设置
      <div class="evaluate-header-icon" @click="closeCompon">
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="security-tab">
      <div class="security-tab-box flex">
        <div class="box-row1 ">
          <div>安全</div>
          <div>生产</div>
           <div>阀位</div>
           <div>设置</div>
        </div>
        <div class="box-row2">
          <div>均热段煤气阀门上限</div>
          <div>均热段空气阀门上限</div>
          <div>均热段煤烟阀门上限</div>
          <div>均热段空烟阀门上限</div>
          <div>二加热段煤气阀门上限</div>
          <div>二加热段空气阀门上限</div>
          <div>二加热段煤烟阀门上限</div>
          <div>二加热段空烟阀门上限</div>
          <div>鼓风机频率上限</div>
          <div>空烟气引风频率上限</div>
          <div>均热段煤气设定上限</div>
        </div>
          <div class="box-row3">
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
        </div>
            <div class="box-row2">
          <div>均热段煤气阀门下限</div>
          <div>均热段空气阀门下限</div>
          <div>均热段煤烟阀门下限</div>
          <div>均热段空烟阀门下限</div>
          <div>二加热段煤气阀门下限</div>
          <div>二加热段空气阀门下限</div>
          <div>二加热段煤烟阀门下限</div>
          <div>二加热段空烟阀门下限</div>
          <div>鼓风机频率下限</div>
          <div>空烟气引风频率下限</div>
          <div>三加热段煤气设定下限</div>
        </div>
           <div class="box-row3">
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
        </div>
                  <div class="box-row2">
          <div>三热段煤气阀门上限</div>
          <div>三热段空气阀门上限</div>
          <div>三热段煤烟阀门上限</div>
          <div>三热段空烟阀门上限</div>
          <div>一加热段煤气阀门上限</div>
          <div>一加热段空气阀门上限</div>
          <div>一加热段煤烟阀门上限</div>
          <div>一加热段空烟阀门上限</div>
          <div>煤气总管阀门上限</div>
          <div>煤烟气引风频率上限</div>
          <div>二加热段煤气设定上限</div>
        </div>
           <div class="box-row3">
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
        </div>
                <div class="box-row2">
          <div>三热段煤气阀门下限</div>
          <div>三热段空气阀门下限</div>
          <div>三热段煤烟阀门下限</div>
          <div>三热段空烟阀门下限</div>
          <div>一加热段煤气阀门下限</div>
          <div>一加热段空气阀门下限</div>
          <div>一加热段煤烟阀门下限</div>
          <div>一加热段空烟阀门下限</div>
          <div>煤气总管阀门下限</div>
          <div>煤烟气引风频率下限</div>
          <div>二加热段煤气设定下限</div>
        </div>
           <div class="box-row3 box-row4">
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
        </div>
      </div>
      <div class="security-tab-box flex security-tab-box1">
               <div class="box-row1 ">
          <div>安全</div>
          <div>生产</div>
           <div>报警</div>
           <div>参数</div>
           <div>设置</div>
        </div>
           <div class="box-row2 box-row22">
             <div class="flex align-cen ">
               <span></span> 
               <p> 均热段温度上限</p>
             </div>
           <div class="flex align-cen "><span></span>  
           <p>三加热段温度上限</p>
           </div>
           <div class="flex align-cen "><span></span>  <p>二加热段温度上限</p></div>
          <div class="flex align-cen "><span></span>  <p>二加热段温度上限</p></div>
          <div class="flex align-cen "><span></span>  <p>二加热段温度上限</p></div>
          <div class="flex align-cen "><span></span>  <p>二加热段温度上限</p></div>
          <div class="flex align-cen "><span></span>  <p>二加热段温度上限</p></div>
          <div class="flex align-cen "><span></span>  <p>二加热段温度上限</p></div>
        </div>
                <div class="box-row3">
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
        </div>
                <div class="box-row2">
          <div>均热段温度下限</div>
          <div>三加热段温度下限</div>
          <div>二加热段温度下限</div>
          <div>一加热段温度下限</div>
          <div>均热空烟温度下限</div>
          <div>三加空烟温度下限</div>
          <div>二加空烟温度下限</div>
          <div>一加空烟温度下限</div>
        </div>
             <div class="box-row3">
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
        </div>
            <div class="box-row2 box-row22">
             <div class="flex align-cen ">
               <span></span> 
               <p> 煤气总管压力上限</p>
             </div>
           <div class="flex align-cen "><span></span>  
           <p>空气总管压力上限</p>
           </div>
           <div class="flex align-cen " > <p style="visibility: hidden">占位</p> </div>
          <div class="flex align-cen "><span></span>  <p>优化站异常报警</p></div>
          <div class="flex align-cen "><span></span>  <p>均热煤烟温度上限</p></div>
          <div class="flex align-cen "><span></span>  <p>三加煤烟温度上限</p></div>
          <div class="flex align-cen "><span></span>  <p>二加煤烟温度上限</p></div>
          <div class="flex align-cen "><span></span>  <p>一加煤烟温度上限</p></div>
        </div>
          <div class="box-row3">
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
        </div>
                 <div class="box-row2">
          <div>煤气总管压力下限</div>
          <div>空气总管压力下限</div>
          <div> <p style="visibility: hidden">占位</p></div>
          <div> <p style="visibility: hidden">占位</p></div>
          <div>均热煤烟温度下限</div>
          <div>三加煤烟温度下限</div>
          <div>二加煤烟温度下限</div>
          <div>一加煤烟温度下限</div>
        </div>
          <div class="box-row3 box-row4">
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
      <Historical v-if="isHshow" @sendStatus = 'isClose1' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
  </div>
</template>

<script>
import inputVal from "@/components/inputVal.vue"   //输入框组件
import Historical from "@/components/Historical.vue"  //历史趋势
export default {
       name: "JrSecurity",
        components: {inputVal,Historical},
    props:{
        infoList:{
            type:Object,
            default: {} // 默认值
        }
    },

    data: () => {
		return {
       historyname:'',
      node:'',
      Lkname:'',
      isIndex:'',
        isComShow:false,
      isHshow:false,
      isClose:false,
		}
        
	},
      watch: {
    infoList: {
      handler (n, o) {
       this.infoList = n
      },
      deep: true // 深度监听父组件传过来对象变化
    },
   },
       methods:{
          isClose1(){
          this.isHshow = false
        },
                 toCompon(key,name,name2,name3){
      this.isIndex = key
      this.historyname = name
      this.node = name2
      this.Lkname = name3
      switch(key){
        case 0:
          return this.isMshow = true
        case 1 :
          return  this.isFshow = true
        case 2:
          return  this.isHshow = true
      }
    },
        //    打开输入框组件。
        toIpt(data,name,historyname,node){
            this.$refs.inputVal.open(data,name,historyname,node)
        },
        closeCompon(){
           this.$emit('sendStatus', 'JrSecurity',false)
        },
    } 
}
</script>
<style lang="scss" scoped>
$base-rebrder-color:rgba(0,22,36,.2);
 .JrSecurity{
     width: 1145px;
     height: 725px;
     background: #DFEDF5;
     font-family: PingFang-SC-Regular;
     cursor:move;
     border-radius: 5px;
      .evaluate-header{
         height: 50px;
         background: #5DCEF3;
         line-height: 50px;
         text-align: center;
         color: #041A28;
         font-size: 16px;
         position: relative;
         border-radius: 5px 5px 0 0;
         .evaluate-header-icon{
         width: 32px;
         height: 32px;
         position: absolute;
         right: 10px;
         top: 8px;
         border-radius: 32px;
          border: 1px solid #041A28;
      color: #041A28;
         line-height: 32px;
        cursor: pointer;
            font-size: 24px
     }
     }
     .security-tab-box{
       width: 1112px;
	      height: 354px;
        margin: 0 auto;
        margin-top: 15px;
         border: 1px solid $base-rebrder-color;
         .box-row1 {
           width: 8%;
           text-align: center;
           color: #0a4c62;
           font-size: 21px;
           line-height: 60px;
           padding-top: 55px;
            border-right: 1px solid $base-rebrder-color;
          
         }
          .box-row3 {
           width: 8%;
           text-align: center;
           color: #0a4c62;
           font-size: 16px;
           line-height: 31.5px;
            border-right: 1px solid $base-rebrder-color;
           div{
               box-sizing: border-box;
                border-bottom: 1px solid $base-rebrder-color;
            }
            div:last-child{
               border-bottom: none;
            }
         }
         .box-row4{
           border-right:none !important
         }
              .box-row2{
         width: 15%;
           text-align: center;
           color: #0a4c62;
           font-size: 16px;
           line-height: 31.5px;
            border-right: 1px solid $base-rebrder-color;
            box-sizing: border-box;
            span{
              width: 20px;
              height: 20px;
              background-color: #badaf0;
              border: solid 2px #ffff00;
              display: block;
              margin-left: 5px;
            }
            div{
             
               box-sizing: border-box;
                border-bottom: 1px solid $base-rebrder-color;
                p{
                  margin-left: auto;
                  padding-right: 5px;
                }
            }
            div:last-child{
               border-bottom: none;
            }
     }
     .box-row22{
       width: 15%;
       text-align: right;
        div{
           width: 100%;
        }
     }
     }
     .security-tab-box1{
       height: 260px;
       .box-row1 {
         line-height: 36px;
         padding-top: 15px;
       }
     }
    //  .security-tab-box{
    //      margin: 10px;
    //       border-bottom: 1px solid $base-border-color;
    //       margin-bottom: 0;
    //       margin-top: 5px;
    //         .row-coulme{
    //           border: 1px solid $base-border-color;
    //           box-sizing: border-box;
    //           height: 40px;
    //           line-height: 40px;
    //           text-align: center;
    //           border-right: none;
    //           border-bottom: none;
    //         }
    //      .widthMax{
    //          width: 17.5%;
    //          border-right: 1px solid $base-border-color;
    //           box-sizing: border-box;
    //           font-size: 16px;
    //           color: #0a4c62;
    //           letter-spacing: -2px;
    //           line-height: 40px;
    //      }
    //      .widthmin{
    //          width: 5%;
    //           border-right: 1px solid $base-border-color;
    //           box-sizing: border-box;
    //           cursor: pointer;
    //           color: #0a4c62;
    //      }
    //      .green{
    //          background: #00ffff;
    //          width: 20px;
    //          height: 20px;
    //          display: block;
    //          margin: 0 auto;
    //          margin-top:2px;
    //          border: 2px solid #ffff00;
    //      }
    //       .red{
    //          background: red;
    //          width: 20px;
    //          height: 20px;
    //          display: block;
    //          margin: 0 auto;
    //          margin-top:2px;
    //          border: 2px solid #ffff00;
    //      }
    //  }
 }
</style>